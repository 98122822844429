import { Button } from '../../atoms';
import { useTranslation } from 'next-i18next';
import React from 'react';

type CancelParticipationProps = {
  onClose: () => void;
  handleCancelParticipation?: () => void;
  cancelLoading: boolean;
};
export const CancelParticipationModal: React.FC<CancelParticipationProps> = ({
  onClose,
  handleCancelParticipation,
  cancelLoading,
}) => {
  const { t } = useTranslation('common');
  return (
    <div>
      <p className="mb-4 regular16-24 text-gray-8">
        {t("Are you sure you want to cancel your participation?")}
      </p>
      <Button
        name={t('Confirm')}
        marginBottom={'mb-2'}
        buttonType={'error'}
        loading={cancelLoading}
        onClick={handleCancelParticipation}
      ></Button>
      <Button
        name={t('Cancel')}
        onClick={onClose}
        marginBottom={'mb-3'}
        buttonType={'tertiary'}
      />
    </div>
  );
};
