import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { TitleWithIcon, Button, PageLoader } from 'ui';
import Link from 'next/link';
import { backArrowIcon } from '../../../assets';
import { toastError, toastSuccess } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import React from 'react';
import { auth } from 'config';

interface ChangePasswordProps {
  changePassword: () => void;
}

export const ChangePassword = ({ changePassword }: ChangePasswordProps) => {
  const router = useRouter();
  const { t, i18n } = useTranslation('common');
  const toast = useToast();
  const [passwordDone, setPasswordDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const onClickBackArrowIcon = () => {
    router.back();
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      auth.languageCode = i18n?.language.startsWith('en') ? 'en' : 'ja';
      await changePassword();
      toastSuccess({
        toastId: 'change-password',
        toast,
        description: t('password reset email has been sent.'),
      });
      setPasswordDone(true);
    } catch (error) {
      toastError({
        toastId: 'reset',
        toast,
        description: t('Failed to send password reset email.'),
      });
    }
    setLoading(false);
  };
  return <>
    <div className="py-3">
      {loading && <PageLoader />}
      <TitleWithIcon
        title={t('Change Password')}
        icon={backArrowIcon}
        onClickIcon={router.back}
      />
      <p className="regular16-24 py-6 text-[#000000]">
        {!passwordDone
          ? t(
            'If you want to change your password, please click on the button below. We will send you instructions on how to reset your password to your registered e-mail address.',
          )
          : t(
            'Instructions for resetting your password have been sent to your registered e-mail address. Please follow the instructions in the email to change your password.',
          )}
      </p>
      {passwordDone ? (
        <Link href={'/'}>
          <Button as="div" name={t('Go to Top Page')} />
        </Link>
      ) : (
        <Button name={t('Send password reset email')} onClick={onSubmit} />
      )}
    </div>
  </>;
};
