import React, { FocusEvent, } from 'react';
import { useTranslation } from 'next-i18next';
import { CKEditor, CKEditorEventHandler } from 'ckeditor4-react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { IEventFormInputs } from 'models';

type EditorProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  value?: string;
  field?: ControllerRenderProps<IEventFormInputs, 'event_details'>;
  marginBottom?: string;
  labelPadding?: string;
  initialData?: string;
  onChange?: CKEditorEventHandler<'change'> &
  ((event: FocusEvent<HTMLInputElement, Element>) => void);
};
export const HTMLEditorComponent = <T extends FieldValues, K extends Path<T>>({
  label,
  value,
  onChange,
  field,
  marginBottom = 'mb-6',
  labelPadding = 'pb-2',
  initialData,
}: EditorProps<T, K>) => {
  const { t } = useTranslation();
  const { ref: _, ...fieldRest } = (field ?? {})
  return (
    <div className={`${marginBottom}`}>
      <div className={`${labelPadding}`}>
        {label && (
          <label className="text-sm font-normal text-gray-9 text-start">
            {t(label)}
          </label>
        )}
      </div>
      <CKEditor
        {...fieldRest}
        data={value}
        onChange={onChange}
        type="classic"
        initData={initialData}
        className={'text-red-2'}
        config={{
          removePlugins: ['image'],
          removeButtons: ['Styles', 'Blockquote', 'Anchor'],
        }}
      />
    </div>
  );
};
