import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

export interface ModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  bodyComponent: React.ReactNode;
  isCenter?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const ModalDialogBox: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  bodyComponent,
  isCenter = true,
  size = 'md',
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered={isCenter}>
      <ModalOverlay />
      <ModalContent rounded={'lg'} className={'mx-4'} maxW={'340px'}>
        <ModalHeader px={'12px'} pt={'12px'} pb={0}>
          <span className="text-gray-10 !semibold16-24">{title}</span>
        </ModalHeader>
        <ModalCloseButton height={6} width={6} marginTop={2} color="gray.6" />
        <ModalBody px={'12px'} py={0} mt={4}>
          <>{bodyComponent}</>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
