import React, { useCallback, useEffect, useRef } from 'react';
import { Event } from '../../atoms';
import { useInfiniteQuery } from 'react-query';
import { getLiveEvents } from 'services';
import { getOpenAndCloseTime } from 'helpers';

export const LiveEventsPanel: React.FC = () => {
  const observerElem = useRef<HTMLDivElement>(null);

  const { data, hasNextPage, isSuccess, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      'liveEvents',
      ({ pageParam = 1 }) => getLiveEvents(pageParam),
      {
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.pagination.has_next) {
            return lastPage.pagination.page + 1;
          }
        },
      },
    );

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;
      if (target.isIntersecting && hasNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage],
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    if (element) {
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [fetchNextPage, hasNextPage, handleObserver]);

  return (
    <>
      {isSuccess &&
        data.pages.map(page =>
          page.data.map(
            ({
              id,
              date,
              name,
              image,
              venue,
              show_time,
              event_type,
              has_favorite,
              favorite_counts,
              event_participants
            }) => (
              <div className="mb-2">
                <Event
                  key={id}
                  id={id}
                  dateTime={date}
                  title={name}
                  image={image ? image : '/assets/image/default_event.png'}
                  isNight={event_type === 'night'}
                  location={venue[0]}
                  times={getOpenAndCloseTime({ show_time })}
                  isMyFavorite={has_favorite}
                  favorite_counts={favorite_counts}
                  event_participants={event_participants}
                />
              </div>
            ),
          ),
        )}
      <div className="loader" ref={observerElem}>
        {isFetchingNextPage && hasNextPage ? 'Loading...' : ''}
      </div>
    </>
  );
};
