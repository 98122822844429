import * as React from 'react';
import { Logo } from 'ui';

export const LoginSignup = () => {
  return (
    <>
      <div className="ml-auto mr-auto h-screen px-4 pt-12 sm:max-w-sm">
        <div className="mb-[49.6px] flex justify-center">
          <Logo />
        </div>
      </div>
    </>
  );
};
