import React from 'react';

export const RecommendedEvents: React.FC = () => {
  return (
    <div className="flex flex-col mt-4 ">
      <p className='text-black regular16-24'>Recommended</p>
      
    </div>
  );
};
