import { Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import { User } from 'firebase/auth';
import React, { Dispatch, SetStateAction } from 'react';
import { SidebarContent } from '../SidebarContent';

export interface NavBarItems {
  icon: string;
  name: string;
  path: string;
}

interface NavBarProps {
  isOpen: boolean;
  onClose: () => void;
  drawerItems: NavBarItems[];
  variant?: string;
  onLogout: () => void;
  authenticated: boolean;
  user: User | null;
  subscription?: boolean;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  setRedirectedFromNavDrawer: Dispatch<SetStateAction<boolean>>;
}

export const NavigationDrawer: React.FC<NavBarProps> = ({
  isOpen,
  onClose,
  drawerItems,
  onLogout,
  authenticated,
  user,
  subscription,
  setSelectedTabIndex,
  setRedirectedFromNavDrawer,
}) => {
  return (
    <>
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size={'xs'}
      >
        <DrawerOverlay />
        <DrawerContent bgColor={'primary'}>
          <SidebarContent
            setRedirectedFromNavDrawer={setRedirectedFromNavDrawer}
            onClose={onClose}
            drawerItems={drawerItems}
            onLogout={onLogout}
            setSelectedTabIndex={setSelectedTabIndex}
            authenticated={authenticated}
            user={user}
            subscription={subscription}
          />
        </DrawerContent>
      </Drawer>
    </>
  );
};
