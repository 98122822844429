import React, { createContext, useContext, useEffect, useState } from 'react';
import { ChatListProps, IChatDetail } from 'models';
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { firestore } from 'config';
import { getChatDetail, getUserDetails } from 'services';
import { User } from 'firebase/auth';

const ChatContext = createContext<Partial<ChatListProps>>({});

export const useChatList = () => useContext(ChatContext);

type ChatListProviderProps = {
  user?: User | null;
  children?: React.ReactNode;
};

export const ChatListProvider: React.FC<ChatListProviderProps> = ({
  children,
  user,
}) => {
  const [rooms, setRooms] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setLoading] = useState(true);

  const [chats, setChats] = useState<IChatDetail[]>([]);

  useEffect(() => {
    if (user?.uid) {
      const ref = collection(firestore, 'Rooms');
      const queryRef = query(
        ref,
        where('participants', 'array-contains', user.uid),
        orderBy('updatedAt', 'desc'),
        limit(50),
      );

      let unsubscribe = onSnapshot(queryRef, async snapshot => {
        if (snapshot.docChanges().length) {
          const data: any = snapshot.docChanges().map(x => {
            return {
              roomId: x.doc.id,
              ...x.doc.data(),
              type: x.type,
              key: x.doc.id,
            };
          });
          setRooms(
            data.filter(
              (x: any) =>
                x.type == 'added' ||
                (x.type == 'modified' && x.action !== `read-${user.uid}`),
            ),
          );
        }
      });
      return () => {
        unsubscribe && unsubscribe();
      };
    }
    if (!user) {
      setRooms([]);
      setLoading(false);
      setUnreadCount(0);
      setChats([]);
    }
  }, [user]);

  const dataUpdate = async () => {
    //get userData from api
    if (user && userDetails) {
      const prefetchedUsers = [...Object.keys(userDetails), user.uid];
      const usersId = rooms
        .map((x: any) => x.participants)
        .flat(2)
        .filter(x => !prefetchedUsers.includes(x));
      if (usersId.length) {
        const response = await getUserDetails(usersId);
        setUserDetails(m => {
          return { ...m, ...response.data };
        });
      }
    }
  };

  useEffect(() => {
    if (Object.keys(userDetails).length && user && rooms?.length > 0) {
      const chatRooms = rooms.map(doc => {
        return getChatDetail(doc, userDetails, user?.uid!);
      });
      setChats(c => {
        const filteredData = c.filter(
          x => !chatRooms.map(y => y.roomId).includes(x.roomId),
        );
        const rooms = [...chatRooms, ...filteredData];
        return rooms.filter(x => x.message);
      });
    }
  }, [userDetails, user, rooms]);

  useEffect(() => {
    if (rooms?.length) {
      dataUpdate();
      setLoading(false);
    }
  }, [rooms]);

  useEffect(() => {
    if (chats.length) {
      const totalUnread = chats
        .map((x: IChatDetail) => x.unreadMsgCount[x.firebaseId as keyof object])
        .filter(x => x !== 0).length;
      setUnreadCount(totalUnread);
    }
  }, [chats]);

  return (
    <ChatContext.Provider
      value={{ chats, isLoading, unreadCount, userDetails, setUserDetails }}
    >
      {children}
    </ChatContext.Provider>
  );
};
