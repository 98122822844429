// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, User, UserCredential } from 'firebase/auth';
import { IProfileInputs } from 'models';
import { getFirestore } from 'firebase/firestore';
import { Dispatch, SetStateAction } from 'react';
import { getAnalytics } from 'firebase/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
};

// Initialize Firebase
if (!getApps().length) {
  const app = initializeApp(firebaseConfig);
  if (app.name && typeof window !== 'undefined') {
    getAnalytics(app);
  }
} else {
  getApp();
}

export const firestore = getFirestore(getApp());
export const auth = getAuth();

export interface AuthContextProps {
  loading?: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  user?: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  authenticated?: boolean | null;
  setAuthenticated: Dispatch<SetStateAction<boolean>>;
  signUp: (email: string, password: string) => Promise<UserCredential | void>;
  logIn: (email: string, password: string) => Promise<UserCredential | void>;
  logOut: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  changeEmail: (newEmail: string) => Promise<void>;
  changePassword: () => void;
  userProfile?: IProfileInputs;
  selectedTabIndex?: number;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  setUserProfile: Dispatch<SetStateAction<IProfileInputs | undefined>>;
  loginStatus?: boolean;
  logoutStatus?: boolean;
  setLoginStatus: Dispatch<SetStateAction<boolean>>;
  setLogoutStatus: Dispatch<SetStateAction<boolean>>;
  action?: string;
  redirectedFromNavDrawer?: boolean;
  setRedirectedFromNavDrawer: Dispatch<SetStateAction<boolean>>;
  setAction: Dispatch<SetStateAction<string>>;
  isAdmin?: boolean;
}
