import { extendTheme, theme as origTheme } from '@chakra-ui/react';
import tailwindConfig from './tailwind.config';

// @ts-ignore
import resolveConfig from 'tailwindcss/resolveConfig';

const twConfig = resolveConfig(tailwindConfig);

export const theme = extendTheme({
  colors: {
    ...twConfig?.theme?.colors,
  },
  styles: {
    global: () => ({
      body: {
        bg: 'gray.3',
      },
      thead: {
        bgColor: 'gray.1 !important',
        borderColor: 'gray.1 !important',
      },
    }),
  },
  components: {
    Alert: {
      variants: {
        success: () => {
          return {
            container: {
              bg: 'primary',
              color: 'white',
            },
          };
        },
        info: () => {
          return {
            container: {
              bg: 'primary',
              color: 'white',
            },
          };
        },
        error: () => {
          return {
            container: {
              bg: 'red',
              color: 'white',
            },
          };
        },
      },
    },
    Checkbox: {
      baseStyle: {
        icon: {
          color: 'white',
          background: 'primary',
        },
        control: {
          border: '1px',
          borderColor: 'gray.6',
          borderRadius: '4px',
          _disabled: {
            borderColor: 'gray.3',
            bg: 'gray.2',
          },
          _checked: {
            borderColor: 'primary',
            bg: 'primary',
            _hover: {
              borderColor: 'primary',
              bg: 'primary',
            },
          },
          _indeterminate: {
            borderColor: 'primary',
            bg: 'primary',
            _hover: {
              borderColor: 'primary',
              bg: 'primary',
            },
          },
        },
      },
    },
  },
});
