import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
export interface IToast {
  (options?: UseToastOptions): ToastId;
  isActive: (id: ToastId) => boolean;
}
type toastSchemaProps = {
  toastId: string;
  toast: IToast;
  title?: string;
  description?: string;
  status?: 'success' | 'info' | 'warning' | 'error' | 'loading';
  position?: string;
  duration?: number;
};

export const toastSuccess = ({
  toastId,
  toast,
  title,
  description,
}: toastSchemaProps) => {
  if (toast && !toast.isActive!(toastId)) {
    return toast({
      id: toastId,
      title: title,
      description: description,
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      variant: 'success',
    });
  }
};

export const toastError = ({
  toastId,
  toast,
  title,
  description,
}: toastSchemaProps) => {
  if (!toast.isActive!(toastId)) {
    return toast({
      id: toastId,
      title: title,
      description: description,
      status: 'error',
      variant: 'error',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  }
};

export const toastInfo = ({
  toastId,
  toast,
  title,
  description,
}: toastSchemaProps) => {
  if (!toast.isActive(toastId)) {
    return toast({
      id: toastId,
      title: title,
      description: description,
      status: 'info',
      variant: 'info',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
    });
  }
};
