import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import React from 'react';
import { getUserById, handleSnsSignIn, useCreateUser } from 'services';
import { Button, facebookIcon, mailIcon, twitterIcon, PageLoader } from 'ui';
import { toastError } from 'toast-schema';
import { auth } from 'config';
import { IProfileInputs } from 'models';
import { User } from 'firebase/auth';
interface ILogin {
  authenticated: boolean;
  loading: boolean;
  user?: User | null;
  setLoading: (args: boolean) => void;
  setUser: (args: User | null) => void;
  setAuthenticated: (args: boolean) => void;
  setLoginStatus: (args: boolean) => void;
  setAction: (args: string) => void;
  setUserProfile: (args: IProfileInputs) => void;
}

export const Login: React.FC<ILogin> = props => {
  const { t } = useTranslation('common');
  const toast = useToast();

  const { mutateAsync, isError } = useCreateUser(toast, true);

  const handleFailedLogin = () => {
    auth.currentUser
      ?.delete()
      .then()
      .finally(() => {
        props.setLoading(false);
      });
    auth.signOut().then(r => {
      props.setUser(null);
      props.setAuthenticated(false);
      props.setLoading(false);
    });
  };

  if (isError) {
    if (!toast.isActive('mutate-user-create')) {
      toastError({
        toastId: 'mutate-user-create',
        toast,
        description: t('Something went wrong.'),
      });
    }
    handleFailedLogin();
  }

  const snsLogin = async (snsName: string) => {
    const data = await handleSnsSignIn(snsName, toast, t);
    if (data) {
      props.setLoading(true);
      getUserById(data.user.uid)
        .then((res: IProfileInputs) => {
          props.setLoginStatus(true);
          props.setUserProfile(res);
          props.setUser(data.user);
          props.setAuthenticated(true);
          props.setLoading(false);
        })
        .catch(() => {
          props.setAction('sns');
          props.setLoginStatus(false);
          mutateAsync({
            email: data?.user?.email,
            firebase_id: data?.user?.uid,
            username: data?.user?.displayName,
            profile_picture_url: data?.user?.photoURL,
          }).then(value => {
            props.setUserProfile(value.data);
            props.setUser(data.user);
            props.setAuthenticated(true);
            props.setLoading(false);
            props.setAction('sns');
            localStorage.setItem('user_id', value.data.id || '');
            localStorage.setItem(
              'signup_type',
              data?.providerId ? data.providerId : 'sns',
            );
          });
        });
    }
  };

  return <>
    {props.loading && <PageLoader />}
    <h2 className="semibold20-28 !text-[20px] ">
      {t("Let's login to your MELD account")}
    </h2>
    <div className="mt-6">
      <Button
        name={t('Login with Facebook')}
        icon={facebookIcon}
        buttonType="facebook"
        onClick={() => snsLogin('facebook')}
      />
      <Button
        name={t('Login with Twitter')}
        icon={twitterIcon}
        buttonType="twitter"
        onClick={() => snsLogin('twitter')}
      />
      <Link href={'/login/email'}>
        <Button as="div" name={t('Login with email')} icon={mailIcon} />
      </Link>
    </div>
    <p className="mb-12 regular14-22 text-gray-8">
      {t('If you do not have an account, please create a new one.')}
    </p>
    <Link href={'/signup'}>
      <Button as="div" buttonType="secondary" name={t('Create an account')} />
    </Link>
  </>;
};
