import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from 'react-share';
import { Icon } from '@iconify/react';
import Image from "next/image";
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
const ShareDrawer = ({ onClose, isOpen }: Props) => {
  const router = useRouter();
  const { id } = router.query;
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const SHARE_URL = process.env.NEXT_PUBLIC_FRONTEND_URL + router.asPath;

  return (
    <Drawer
      placement={'bottom'}
      onClose={() => {
        onClose();
        setIsCopied(false);
      }}
      isOpen={isOpen}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody className="!p-0">
          <DrawerCloseButton />
          <div className="xs:px-8 mx-auto mt-8 max-w-[600px] px-4 py-4 md:mt-4">
            <div className="flex items-center justify-between gap-2  px-[5px]">
              <p className="overflow-anywhere w-[70%]">{SHARE_URL}</p>
              <CopyToClipboard
                text={SHARE_URL}
                onCopy={() => setIsCopied(true)}
              >
                <span
                  className={`bg-gray-3 flex cursor-pointer  gap-2  rounded-md p-3 text-center  font-semibold`}
                >
                  {isCopied ? (
                    <Icon
                      icon="charm:tick-double"
                      className="text-green-6"
                      width="24"
                      height="24"
                    />
                  ) : (
                    t('Copy')
                  )}
                </span>
              </CopyToClipboard>
            </div>
            <div className="flex gap-6 mt-4">
              <EmailShareButton url={SHARE_URL}>
                <div className="bg-gray-3 max-h-[40px] rounded-md px-2 py-2">
                  <Image
                    src="/assets/svg/gmail.png"
                    height={24}
                    width={24}
                    style={{objectFit: 'contain'}}
                    alt="gmail"
                  />
                </div>
              </EmailShareButton>
              <FacebookShareButton url={SHARE_URL} quote="">
                <div className="bg-gray-3 max-h-[40px] rounded-md px-2 py-2">
                  <Image
                    src="/assets/svg/facebook.png"
                    height={24}
                    width={24}
                    alt="facebook"
                  />
                </div>
              </FacebookShareButton>
              <TwitterShareButton url={SHARE_URL}>
                <div className="bg-gray-3 max-h-[40px] rounded-md px-2 py-2">
                  <Image
                    src="/assets/svg/twitter.png"
                    height={24}
                    width={24}
                    alt="twitter"
                  />
                </div>
              </TwitterShareButton>
              <LineShareButton url={SHARE_URL}>
                <div className="bg-gray-3 max-h-[40px] rounded-md px-2 py-2">
                  <Image
                    src="/assets/svg/line.png"
                    height={24}
                    width={24}
                    alt="share"
                  />
                </div>
              </LineShareButton>
            </div>
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export { ShareDrawer };
