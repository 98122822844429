import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  EventHandler,
  ReactElement,
  useRef,
  useState,
} from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

export type LeftIconProps = {
  marginStart?: string;
  marginTop?: string;
  boxSize?: number;
  color?: string;
};

type InputEventHandler =
  | React.ChangeEvent<HTMLInputElement>
  | React.CompositionEvent<HTMLInputElement>;

interface IPlaceholderProps {
  position?: string;
  top?: string;
}

type FormInputProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  name?: string;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  placeholderColor?: string;
  captionText?: string;
  iconLeft?: ReactElement<FieldValues, string>;
  iconRight?: ReactElement<FieldValues, string>;
  iconLeftStyle?: LeftIconProps;
  error?: string;
  required?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClickRightIcon?: () => void;
  field?: ControllerRenderProps<T, K>;
  marginTop?: string;
  marginBottom?: string;
  className?: string;
  placeholderStyle?: IPlaceholderProps;
  disabled?: boolean;
  labelpadding?: string;
  border?: boolean;
  textTransform?: 'uppercase' | 'inherit';
  subText?: string;
  count?: number;
  maxCount?: number;
  autoComplete?: string;
};

export const InputField = <T extends FieldValues, K extends Path<T>>({
  label,
  name,
  type,
  value,
  placeholder,
  placeholderColor = 'gray.8',
  placeholderStyle,
  iconLeft,
  iconRight,
  iconLeftStyle = {
    boxSize: 6,
    marginStart: '12px',
    marginTop: '12px',
    color: 'gray.9',
  },
  disabled,
  onChange,
  error,
  size = 'lg',
  onClickRightIcon,
  field,
  border = true,
  marginTop,
  marginBottom = 'mb-6',
  className = '',
  labelpadding = 'pb-2',
  textTransform,
  subText,
  count,
  maxCount,
  autoComplete,
}: FormInputProps<T, K>) => {
  const { t } = useTranslation('common');

  const compositing = useRef(false);
  const [inputValue, setInputValue] = useState(value ?? '');
  const _onChange = (event: InputEventHandler) => {
    setInputValue((event?.target as HTMLInputElement).value);
    if (event.type === 'compositionstart') {
      compositing.current = true;
      return;
    }
    if (event.type == 'compositionend') {
      compositing.current = false;
    }
    if (!compositing.current) {
      onChange && onChange(event as ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div className={`${marginBottom} ${className} ${marginTop}`}>
      <div className={`${labelpadding} flex justify-between`}>
        {label && (
          <label className="text-sm font-normal text-gray-9 text-start">
            {t(label)}
          </label>
        )}
        {maxCount &&
          (count! <= maxCount ? (
            <p className="font-normal regular14-22 text-gray-7 text-start">
              {count!}/{maxCount}
            </p>
          ) : (
            <p className="font-normal regular14-22 text-red-5 text-start">
              {count!}/{maxCount}
            </p>
          ))}
      </div>

      <InputGroup>
        {iconLeft && (
          <InputLeftElement
            pointerEvents="none"
            width={12}
            height={12}
            color={iconLeftStyle.color}
            marginStart={iconLeftStyle.marginStart}
            marginTop={iconLeftStyle.marginTop}
            boxSize={iconLeftStyle.boxSize}
          >
            {iconLeft}
          </InputLeftElement>
        )}
        <Input
          bg={border ? 'white' : 'gray.3'}
          variant={border ? 'outline' : 'unstyled'}
          type={type ? type : 'text'}
          placeholder={t(placeholder!)}
          size={size}
          _placeholder={{
            color: placeholderColor,
            ...placeholderStyle,
          }}
          name={name ? name : ''}
          value={inputValue}
          borderColor={error ? 'red.6' : 'gray.5'}
          className="relative regular16-24"
          focusBorderColor="gray.5"
          borderRadius={'8px'}
          paddingLeft={iconLeft ? '38px' : '12px'}
          paddingEnd={'12px'}
          paddingY={'12px'}
          color="gray.8"
          alignItems={'center'}
          disabled={disabled}
          _disabled={{
            color: placeholderColor,
            bg: 'gray.3',
          }}
          textTransform={textTransform}
          _hover={{ borderColor: border ? (error ? 'red.6' : 'gray.5') : '' }}
          _focus={{ borderColor: border ? (error ? 'red.6' : 'gray.5') : '' }}
          onChange={_onChange}
          onCompositionStart={_onChange}
          onCompositionEnd={_onChange}
          autoComplete={autoComplete}
          {...field}
        />
        {iconRight && (
          <InputRightElement
            width={border ? 12 : '40px'}
            height={border ? 12 : '22px'}
            marginEnd={border ? '12px' : ''}
            marginTop={border ? '12px' : '5px'}
            boxSize={border ? 6 : ''}
            cursor="pointer"
            onClick={onClickRightIcon}
            color="gray.7"
          >
            {iconRight}
          </InputRightElement>
        )}
      </InputGroup>
      {subText ? <p className="text-gray-7 regular14-22">{t(subText)}</p> : ''}
      {error == '' ? null : (
        <p className="text-red-6">{t(error || '', { name: t(name!) })}</p>
      )}
    </div>
  );
};
