import { As, Button as ChakraButton, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

interface Props {
  name?: string;
  icon?: React.ReactNode;
  buttonType?:
  | 'primary'
  | 'secondary'
  | 'error'
  | 'tertiary'
  | 'facebook'
  | 'twitter'
  | 'gray';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  marginTop?: string;
  marginBottom?: string;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  specialButton?: boolean;
  cursorClassname?: string;
  as?: As<any>
}

export const Button = ({
  name,
  icon,
  onClick,
  buttonType = 'primary',
  type = 'submit',
  marginTop,
  marginBottom = 'mb-6',
  specialButton = false,
  disabled,
  loading,
  cursorClassname = '',
  as,
}: Props) => {
  const { t } = useTranslation('common');

  const bgColor = {
    primary: {
      backgroundColor: 'primary',
      textColor: 'white',
      borderColor: 'primary',
    },
    secondary: {
      backgroundColor: 'primaryLight',
      textColor: 'primary',
      borderColor: 'primary',
    },
    tertiary: {
      backgroundColor: 'gray.3',
      textColor: 'gray.10',
      borderColor: 'gray.3',
    },
    error: {
      backgroundColor: 'red.5',
      textColor: 'white',
      borderColor: 'red.5',
    },
    gray: {
      backgroundColor: 'gray.6',
      textColor: 'gray.8',
      borderColor: 'gray.6',
    },
    facebook: {
      backgroundColor: 'facebook',
      textColor: 'white',
      borderColor: 'facebook',
    },
    twitter: {
      backgroundColor: 'twitter',
      textColor: 'white',
      borderColor: 'twitter',
    },
  };

  return (
    <ChakraButton
      as={as}
      backgroundColor={bgColor[buttonType].backgroundColor}
      borderColor={bgColor[buttonType].borderColor}
      color={bgColor[buttonType].borderColor}
      border={buttonType == 'secondary' ? '1px' : '0px'}
      _hover={{
        background: bgColor[buttonType].backgroundColor,
        opacity: 0.8,
        _disabled: { backgroundColor: 'gray.6' },
      }}
      size={specialButton ? 'md' : 'lg'}
      type={type}
      className={`w-full [&>svg]:h-full [&>svg]:w-full ${marginBottom} ${cursorClassname} ${marginTop}`}
      borderRadius="8px"
      onClick={onClick}
      disabled={disabled}
      _disabled={{
        backgroundColor: 'gray.6',
        cursor: 'not-allowed',
      }}
      isLoading={loading}
      _loading={{ color: 'white' }}
    >
      {icon && <span className="w-6 h-6">{icon}</span>}

      {name && (
        <Text
          color={bgColor[buttonType].textColor}
          className="semibold16-24"
          paddingLeft={icon ? 2 : 0}
          as="span"
        >
          {t(name)}
        </Text>
      )}
    </ChakraButton>
  );
};
