import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { API, firestore } from 'config';
import axios, { CancelTokenSource } from 'axios';
import { IChatDetail, MessageProps, IUserDetails } from 'models';
import { IToast, toastError } from 'toast-schema';
import { TFunction } from 'next-i18next';
import { QueryKey } from 'react-query';
export const getUserDetails = (firebaseIdList: string | string[]) => {
  return API.get(`/user/less?ids=${firebaseIdList}`);
};
interface IMessageFileUpload {
  attachment: {
    attachment: string;
    id: string;
  };
}
export const getChatDetail = (
  doc: DocumentData,
  userDetails: Record<string, IUserDetails>,
  userFirebaseID?: string,
): IChatDetail => {
  const firebaseID = doc.participants.find((x: string) => x !== userFirebaseID);
  const data = {
    date: isNaN(doc.updatedAt) ? doc.updatedAt : doc.updatedAt?.toDate(),
    message: doc.lastMessage,
    firebaseId: firebaseID,
    name: userDetails[firebaseID]?.nick_name,
    img: userDetails[firebaseID]?.profile_picture,
    roomId: doc.roomId,
    unreadMsgCount: doc.unreadMsgCount,
    is_deleted: userDetails[firebaseID]?.is_deleted,
    direction:
      doc.lastMessageSentBy === userFirebaseID ? 'outgoing' : 'incoming',
  };
  return data;
};

export const getPreviousMessages = async (roomId: string, lastDoc: string) => {
  const ref = collection(firestore, 'Rooms', `${roomId}`, 'messages');
  const docSnap = await getDoc(
    doc(firestore, 'Rooms', `${roomId}`, 'messages', `${lastDoc}`),
  );
  const orderedRef = query(
    ref,
    orderBy('createdAt', 'desc'),
    startAfter(docSnap),
    limit(20),
  );
  const messages: MessageProps[] = await getDocs(orderedRef).then(snapshot => {
    const arrayList: MessageProps[] = [];
    snapshot.docs.forEach(x => {
      if (x.data()) {
        arrayList.push({ id: x.id, ...(x.data() as MessageProps) });
      }
    });
    return arrayList;
  });
  return messages;
};

let CancelToken: CancelTokenSource;
export const searchChatRooms = async ({
  searchQuery,
}: {
  searchQuery: string;
}) => {
  const api = `/chat/search?query=${searchQuery}`;
  if (typeof CancelToken != typeof undefined) {
    CancelToken.cancel('Operation canceled due to new request.');
  }
  CancelToken = axios.CancelToken.source();
  try {
    const response = await API.get(api, {
      cancelToken: CancelToken.token,
    });
    return response.data;
  } catch (error) {}
};

export const sendMessage = async (
  message: string,
  type: string,
  roomId: string,
  file?: string | null,
  docId?: string,
) => {
  await API.post('/chat/send-message', {
    room_id: roomId,
    message,
    file,
    type,
    doc_id: docId,
  });
};

export const setAvailability = (firebaseId: string, status: boolean) => {
  const statusRef = doc(firestore, 'Availability', firebaseId);
  setDoc(statusRef, {
    isOnline: status,
  });
};

export const createOrRetrieveChatRoom = async (
  senderId: string,
  recieverId: string,
) => {
  const roomRef = collection(firestore, 'Rooms');
  const queryRef = query(
    roomRef,
    where('participants', 'array-contains', senderId),
  );
  const result = await getDocs(queryRef);
  const data = result.docs.find(arr =>
    arr
      .data()
      .participants.every((id: string) => [senderId, recieverId].includes(id)),
  );
  if (data?.id) {
    return data.id;
  } else {
    const response = await addDoc(roomRef, {
      participants: [senderId, recieverId],
      lastMessage: '',
      lastMessageSentBy: null,
      unreadMsgCount: {
        [senderId]: 0,
        [recieverId]: 0,
      },
      action: 'room-create',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return response.id;
  }
};

export const messageFileUpload = async (formdata: FormData) => {
  const data: IMessageFileUpload = await API.post('/chat/attachment', formdata);
  return data.attachment;
};

export const getFileOrImage = async ({ queryKey }: { queryKey: QueryKey }) => {
  const type = queryKey[2] ? queryKey[2] : 'original';
  const { data } = await API.get(
    `/chat/attachment/${queryKey[1]}?type=${type}`,
  );
  return data.attachment;
};

export const updateLatestMessageReadStatus = (
  roomId: string,
  firebaseId: string,
) => {
  const roomRef = doc(firestore, 'Rooms', `${roomId}`);
  updateDoc(roomRef, {
    action: `read-${firebaseId}`,
    [`unreadMsgCount.${firebaseId}`]: 0,
  });
};

export const markMessageAsRead = (roomId: string, docId: string) => {
  const messageRef = doc(firestore, 'Rooms', roomId, 'messages', docId);
  updateDoc(messageRef, {
    isRead: true,
  });
};

export const checkDate = (date: string) => {
  const dateProvided = new Date(date).toLocaleDateString();
  const today = new Date().toLocaleDateString();
  const yesterday = new Date(Date.now() - 86400000).toLocaleDateString();
  return dateProvided == today
    ? 'Today'
    : dateProvided == yesterday
    ? 'Yesterday'
    : date;
};

export const groupDataByDate = (data: MessageProps[]) => {
  let object: Record<string, MessageProps[]> = {};
  data.forEach(x => {
    if (x.createdAt) {
      const createdAt = x.createdAt.toDate();
      const date = new Date(createdAt).toLocaleDateString(); //convert date into DD/MM/YYYY format.
      if (!object.hasOwnProperty(date)) {
        object[date] = [x];
      } else {
        object[date] = [...object[date], x];
      }
    }
  });
  return object;
};

export const replaceLinks = (inputText: string) => {
  const urlPattern =
    /\b(?:https?|ftp):\/\/[a-z0-9-+&@#/%?=~_|!:,.;]*[a-z0-9-+&@#/%=~_|]/gim; //http://, https://, ftp://
  const pseudoUrlPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim; // www. sans http:// or https://
  return inputText
    .replace(urlPattern, '<a href="$&" target="_blank" rel="nofollow">$&</a>')
    .replace(
      pseudoUrlPattern,
      '$1<a href="http://$2" target="_blank" rel="nofollow">$2</a>',
    );
};

export const formattedMessage = (message: string) => {
  const data = message.replaceAll(/<\/?[^>]+(>|$)/g, '');
  return data;
};

export const checkFileFormat = (toast: IToast, t: TFunction, type: string) => {
  const supportedTypes = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'image/webp',
    'image/heic',
    'image/heif',
    'image/jpg',
    'video/mp4',
    'video/quicktime',
  ];
  if (supportedTypes.includes(type)) {
    return true;
  } else {
    toastError({
      toastId: 'profile',
      toast,
      description: t('Invalid file type.'),
    });
    return false;
  }
};
