import { Box, Checkbox, Flex, Stack, Text } from '@chakra-ui/react';
import { IOptions } from 'models';
import { useTranslation } from 'next-i18next';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { cheveronDownIcon } from '../../../assets';

interface CheckboxSearchProps {
  placeHolder?: string;
  list: IOptions[];
  setSelectedGenre?: Dispatch<SetStateAction<string[]>>;
  selectedGenre?: string[];
}
export const CheckboxSearch: React.FC<CheckboxSearchProps> = ({
  placeHolder,
  list,
  setSelectedGenre,
  selectedGenre,
}) => {
  const [checkedItems, setCheckedItems] = React.useState<string[]>(
    selectedGenre || [],
  );
  const [allChecked, setAllChecked] = React.useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const { t } = useTranslation();
  const refs = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (refs.current && !refs['current'].contains(event?.target as any)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const itemValue = list.map(item => {
    return item.value;
  });

  const handleSelectAll = () => {
    setAllChecked(!allChecked);
    setCheckedItems([...itemValue]);
    if (allChecked) {
      setCheckedItems([]);
    }
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (checkedItems.includes(e.target.value)) {
      const filteredItems = checkedItems.filter(item => item != e.target.value);
      setCheckedItems(filteredItems);
    } else {
      setCheckedItems([...checkedItems, e.target.value]);
    }
    setAllChecked(checkedItems.length === list.length);
  };

  useEffect(() => {
    setAllChecked(checkedItems.length === list.length);
    setSelectedGenre && setSelectedGenre(checkedItems);
  }, [checkedItems]);

  return (
    <>
      <div ref={refs}>
        <Box
          backgroundColor={'gray.1'}
          border={'1px'}
          borderColor={'gray.5'}
          textAlign={'left'}
          rounded={'md'}
          padding={'11px'}
          onClick={() => setShowOptions(!showOptions)}
        >
          <Flex justifyContent={'space-between'}>
            <Text color={'gray.8'} className={'regular16-24'}>
              {t(placeHolder!) || ''}{' '}
              <span className="text-gray-6">
                {checkedItems.filter(itemValue => !!itemValue).length !== 0 &&
                  `(${checkedItems.filter(itemValue => !!itemValue).length})`}
              </span>
            </Text>
            <Text marginTop={1}>{cheveronDownIcon}</Text>
          </Flex>
        </Box>
        {showOptions && (
          <Box
            rounded={'md'}
            backgroundColor={'gray.1'}
            border={'1px'}
            borderColor={'gray.5'}
            marginTop={1}
            position={'absolute'}
            maxH={'312px'}
            zIndex={50}
            className={'scrollbar my-2'}
          >
            <Checkbox
              paddingX={2}
              marginTop={3}
              isChecked={allChecked}
              onChange={handleSelectAll}
              _checked={{
                color: 'primary',
              }}
              color="gray.7"
            >
              {t('Select All')}
            </Checkbox>
            <hr className="mx-2 my-2 border-gray-5" />
            <Stack spacing={1}>
              {list.map((item: IOptions, index: number) => (
                <Checkbox
                  key={index}
                  value={item.value}
                  paddingX={2}
                  marginTop={'2px'}
                  width={'253px'}
                  isChecked={allChecked || checkedItems.includes(item.value)}
                  onChange={handleClick}
                  _checked={{
                    color: 'primary',
                  }}
                  color="gray.7"
                >
                  {t(item.label)}
                </Checkbox>
              ))}
            </Stack>
          </Box>
        )}
      </div>
    </>
  );
};
