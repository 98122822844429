import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Panel, NoResultsFound, TabMenu } from 'ui';

import { useInfiniteQuery } from 'react-query';
import { toastError } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { getFeaturedEvents } from 'services';

interface FeaturedEventsProps {
  UUID: string;
  titleTextClass?: string;
  isAdmin?: boolean;
}

export const FeaturedEvents: React.FC<FeaturedEventsProps> = ({
  UUID,
  isAdmin,
  titleTextClass,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const userId = UUID;

  const {
    data: previousData,
    hasNextPage: hasNextPageP,
    isLoading: isLoadingP,
    isFetchingNextPage: isFetchingNextPageP,
    fetchNextPage: fetchNextPageP,
  } = useInfiniteQuery(
    ['previous-events', UUID],
    ({ pageParam = 1 }) => getFeaturedEvents(pageParam, userId, 'previous'),
    {
      getNextPageParam: lastPage => {
        if (lastPage?.pagination?.has_next) {
          return lastPage.pagination.page + 1;
        }
      },
      retry: false,
      enabled: !!UUID,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );

  const {
    data: goingData,
    hasNextPage: hasNextPageG,
    isLoading: isLoadingG,
    isFetchingNextPage: isFetchingNextPageG,
    fetchNextPage: fetchNextPageG,
  } = useInfiniteQuery(
    ['going-events', UUID],
    ({ pageParam = 1 }) => getFeaturedEvents(pageParam, userId, 'going'),
    {
      getNextPageParam: lastPage => {
        if (lastPage?.pagination?.has_next) {
          return lastPage.pagination.page + 1;
        }
      },
      retry: false,
      enabled: !!UUID,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );

  const data = [
    {
      label: t('Going'),
      panel: (
        <>
          {goingData?.pages &&
            (goingData?.pages[0]?.data?.length > 0 ? (
              <Panel
                isAdmin={isAdmin}
                data={goingData}
                isFetchingNext={isFetchingNextPageG}
                fetchNext={fetchNextPageG}
                hasNext={hasNextPageG}
                isLoading={isLoadingG}
              />
            ) : (
              <NoResultsFound text="No events available!" />
            ))}
        </>
      ),
    },
    {
      label: t('Previously Attended'),
      panel: (
        <>
          {previousData?.pages &&
            (previousData?.pages[0]?.data?.length > 0 ? (
              <Panel
                isAdmin={isAdmin}
                data={previousData}
                isFetchingNext={isFetchingNextPageP}
                fetchNext={fetchNextPageP}
                hasNext={hasNextPageP}
                isLoading={isLoadingP}
              />
            ) : (
              <NoResultsFound text="No events available!" />
            ))}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="mt-[16px]">
        <h4
          className={`${
            titleTextClass ? titleTextClass : 'text-gray-7 !regular12-20 '
          }`}
        >
          {t('Events')}
        </h4>
        <TabMenu tabList={data} type="sm" isAdmin={isAdmin} />
      </div>
    </>
  );
};
