import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { Button, facebookIcon, mailIcon, twitterIcon, PageLoader } from 'ui';
import { useToast } from '@chakra-ui/react';
import { getUserById, handleSnsSignIn, useCreateUser } from 'services';
import { toastError } from 'toast-schema';
import React from 'react';
import { auth, AuthContextProps } from 'config';
import { IProfileInputs } from 'models';
import { User } from 'firebase/auth';
interface ISignUp {
  authenticated: boolean;
  loading: boolean;
  user?: User | null;
  setLoading: (args: boolean) => void;
  setUser: (args: User | null) => void;
  setAuthenticated: (args: boolean) => void;
  setLoginStatus: (args: boolean) => void;
  setAction: (args: string) => void;
  setUserProfile: (args: IProfileInputs) => void;
}
export const SignUp: React.FC<ISignUp> = props => {
  const { t } = useTranslation('common');
  const toast = useToast();

  const { mutateAsync, isError } = useCreateUser(toast, true);

  const handleFailedLogin = () => {
    auth.currentUser?.delete().then();
    auth.signOut().then(r => {
      props.setUser(null);
      props.setAuthenticated(false);
      props.setLoading(false);
    });
  };

  if (isError) {
    if (!toast.isActive('mutate-user-create')) {
      toastError({
        toastId: 'mutate-user-create',
        toast,
        description: t('Something went wrong.'),
      });
    }
    handleFailedLogin();
  }
  const snsLogin = async (snsName: string) => {
    const data = await handleSnsSignIn(snsName, toast, t);
    if (data) {
      props.setLoading(true);
      getUserById(data.user.uid)
        .then((res: IProfileInputs) => {
          props.setLoginStatus(true);
          props.setUserProfile(res);
          props.setUser(data.user);
          props.setAuthenticated(true);
          props.setLoading(false);
        })
        .catch(() => {
          props.setLoginStatus(false);
          mutateAsync({
            email: data?.user?.email,
            firebase_id: data?.user?.uid,
            username: data?.user?.displayName,
            profile_picture_url: data?.user?.photoURL,
          }).then(value => {
            props.setUserProfile(value.data);
            props.setUser(data.user);
            props.setAuthenticated(true);
            props.setLoading(false);
            localStorage.setItem('user_id', value.data.id || '');
            localStorage.setItem(
              'signup_type',
              data?.providerId ? data.providerId : 'sns',
            );
          });
        });
      props.setAction('sns');
    }
  };

  return <>
    {props.loading && <PageLoader />}
    <h2 className="semibold20-28 !text-[20px] ">{t('Signup')}</h2>
    <div className="mt-6">
      {/* <Button
        name={t('Signup with Facebook')}
        icon={facebookIcon}
        buttonType="facebook"
        onClick={() => snsLogin('facebook')}
      /> */}
      <Button
        name={t('Signup with Twitter')}
        icon={twitterIcon}
        buttonType={'twitter'}
        onClick={() => snsLogin('twitter')}
      />
      <Link href={'/signup/email'}>
        <Button as="div" name={t('Signup with Email')} icon={mailIcon} />
      </Link>
    </div>
    <p className="regular14-22 text-gray-8">
      {t('If you already have an account, please login.')}
    </p>
    <Link href="/login" className="mt-12">
      <Button as="div" buttonType="secondary" name={t('Login')} />
    </Link>
  </>;
};
