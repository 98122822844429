import Image from 'next/image';
import { Icon } from '@iconify/react';
import { I18n, useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';
import { EventParticipant, ScheduleTimes } from 'models';
import { addHours } from 'date-fns';
import { favoriteFillIcon, favoriteOutlineIcon } from '../../../assets';
import { animate } from 'framer-motion';

type EventProps = {
  dateTime: string | number | Date;
  image: string;
  location: string;
  isNight?: boolean;
  title: string; 
  id: string;
  times: ScheduleTimes;
  favorite_counts: number;
  isMyFavorite: boolean;
  isAdmin?: boolean;
  event_participants?: EventParticipant
};

const getMonth = (i18n: I18n, month: number) => {
  const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
  return i18n.language != 'ja'
    ? formatter.format(new Date(2003, month, 12))
    : (month + 1).toString();
};

interface YMDHM {
  year: number;
  month: string;
  day: number;
  hour: number;
  minute: string;
}

const useDate = (timestamp: Date, times: ScheduleTimes): YMDHM | undefined => {
  const { i18n } = useTranslation();
  const [formattedDate, setFormattedDate] = useState<YMDHM>();

  useEffect(() => {
    setFormattedDate({
      year: timestamp.getFullYear(),
      month: getMonth(i18n, timestamp.getMonth()),
      day: timestamp.getDate(),
      hour: times?.OpeningTime?.getHours() ?? 0,
      minute: String(times?.OpeningTime?.getMinutes()).padStart(2, '0'),
    });
  }, [timestamp, times, i18n]);

  return formattedDate;
};


export const Event: React.FC<EventProps> = ({
  dateTime,
  times,
  image,
  location,
  isNight,
  title,
  id,
  isAdmin,
  favorite_counts,
  isMyFavorite,
  event_participants
}) => {
  const { t, i18n } = useTranslation();
  const date = useMemo(() => new Date(dateTime), [dateTime]);

  const isLive =
    new Date() >= new Date(times?.StartTime!) &&
    new Date() <= addHours(new Date(times?.StartTime!), 12);

  const ymd = useDate(date, times);
  
  // animation number of people
  const peopleCountRef = useRef<HTMLSpanElement | null>(null);

  const getCountEventParticipant = (num : number | undefined) => {
    if(!num) return 0
    if(num > 9999) return 9999
    return num
  } 
  const countEventParticipant = getCountEventParticipant(event_participants?.count_event_participant)
  useEffect(() => {
    const node = peopleCountRef.current as HTMLSpanElement;

    const controls = animate(0, countEventParticipant, {
      duration: 1,
      onUpdate(value) {
        if (countEventParticipant === 0) {
          node.textContent = t('参加者がいません')
          return
        }
        node.textContent = value.toFixed(0)
      },
      onComplete() {
        if(countEventParticipant === 0) {
          node.textContent = t('参加者がいません')
          return
        }

        const numOfParticipantFormat = countEventParticipant.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        node.textContent = numOfParticipantFormat + '人が参加'; 
      }
    });

    return () => controls.stop();
  }, []);

  return (
    <div className={'cursor-pointer'}>
      <Link
        prefetch={false}
        href={isAdmin ? `/events/edit/${id}` : `/events/${id}`}
      >
        <span className="flex p-2 transition-all duration-300 rounded-lg bg-gray-2 hover:shadow-sm">
          {image ? (
            <div className="h-[82px] min-w-[80px]">
              <Image
                alt={title}
                height={82}
                width={80}
                priority
                style={{ objectFit: 'cover', objectPosition: 'center' }}
                src={image}
                className={'h-20 rounded-md'}
              />
            </div>
          ) : (
            <div className="bg-gray-3 text-gray-8 flex h-[82px] items-center justify-between text-center text-[8px]">
              <p className="w-[80px] justify-center">
                {t('No Image Available')}
              </p>
            </div>
          )}

          <div className="flex flex-col flex-auto ml-2 justify-items-stretch">
            <div className={'flex flex-auto flex-row flex-wrap justify-end'}>
              {ymd ? (
                <p className="flex-auto text-gray-7 regular12-20">
                  {t('date_format', {
                    year: ymd?.year,
                    month: ymd?.month,
                    day: ymd?.day,
                  })}
                  {'  '}
                  {ymd?.hour}:{ymd?.minute}
                </p>
              ) : (
                <p className="flex-auto invisible text-gray-7 regular12-20">
                  .
                </p>
              )}
              {isLive ? (
                <Image
                  priority
                  src="/assets/svg/connected_tv.svg"
                  alt="live"
                  height={20}
                  width={20}
                />
              ) : isNight ? (
                <Icon
                  icon="clarity:moon-solid"
                  color="#8c8c8c"
                  width="20"
                  height="20"
                />
              ) : null}
            </div>
            <h4 className="xxs:max-w-[200px] xs:max-w-xs text-gray-10 semibold16-24 line-clamp-2 mt-2 flex max-w-[80px] overflow-hidden sm:max-w-[384px]">
              {title}
            </h4>
            <div className={'mt-2 flex justify-between'}>
              <div className="flex flex-row ">
                <div className="absolute h-[22px] w-[22px]">
                  <Image
                    priority
                    src="/assets/svg/pin-icon.svg"
                    alt={location}
                    height={22}
                    width={22}
                  />
                </div>
                <p className="regular14-22 text-gray-10 line-clamp-2 xxs:max-w-[200px] xs:max-w-xs ml-5 flex max-w-[80px] flex-wrap pl-1 sm:max-w-[384px]">
                  {t(location)}
                </p>
              </div>
              <div className="top-0 flex gap-[1px] align-middle">
                <div className="text-[20px]">
                  {isMyFavorite ? favoriteFillIcon : favoriteOutlineIcon}
                </div>
                {favorite_counts > 0 && (
                  <div className="b-2 regular14-22">
                    {favorite_counts <= 999 ? favorite_counts : '999+'}
                  </div>
                )}
              </div>
            </div>

            {/* group user */}
            <div className='mt-2'>
              <div className='flex gap-x-2'>
                <Image
                  src="/assets/svg/group-people.svg"
                  height={22}
                  width={22}
                  alt="group people icon"
                  // className="animate-left-to-current"
                />            
                <span className='text-sm leading-[22px]' ref={peopleCountRef}></span>
              </div>
              {event_participants?.users && event_participants.users.length > 0 && (
                <div className='flex mt-2'>
                  {event_participants.users.map((user,idx) => {
                    return (
                      <div key={user.id} className={`w-8 h-8 aspect-square p-0.5 bg-white rounded-full overflow-hidden ${idx? '-ml-2': ''}`}>
                        <Image
                          src={user.profile_picture || '/assets/svg/person.svg'}
                          className={`object-cover object-center h-full rounded-full ${user.profile_picture ? 'p-0' : 'bg-[#EEE] p-0.5'}`}
                          width={40}
                          height={40}
                          alt="user icon"
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
            {/* end group user */}
          </div>
        </span>
      </Link>
    </div>
  );
};
