import * as React from 'react';
import Image from "next/image";

interface LogoProps {
  type?: 'light' | 'white';
  height?: number;
  width?: number;
}

export const Logo: React.FC<LogoProps> = ({
  type = 'light',
  height = 56,
  width = 120,
}) => {
  return (
    <>
      {type == 'light' ? (
        <Image
          src="/assets/svg/logo-light.svg"
          alt="logo-light"
          height={height}
          width={width}
        />
      ) : (
        <Image
          src="/assets/svg/logo-white.svg"
          height={height}
          priority={true}
          width={width}
          alt="Logo-of-MELD"
        />
      )}
    </>
  );
};
