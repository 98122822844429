import { API } from 'config';
import { removeEmpty } from 'helpers';
import { IPagination } from './pagination';

interface IAutoSearchWord {
  created_at: string;
  id: string;
  search_word: string;
  updated_at: string;
}
interface IAutoSearchWordsResponse {
  data: IAutoSearchWord[];
  pagination: IPagination;
}

export const getAutoSearchWords = async (
  searchQuery: string,
  pageParam: number,
): Promise<IAutoSearchWordsResponse> => {
  return await API.get('/search-word', {
    params: removeEmpty({
      page: pageParam,
      per_page: 10,
      search_word: searchQuery,
    }),
  });
};

export const deleteAutoSearchWord = async (id: string) => {
  return await API.delete(`/search-word/${id}`);
};

export const addAutoSearchWord = async (search_word: string) => {
  return await API.post(`/search-word`, { search_word });
};

export const updateAutoSearchWord = async (id: string, search_word: string) => {
  return await API.put(`/search-word/${id}`, { search_word });
};

export const getAutoSearchWordById = async (id: string) => {
  return await API.get<IAutoSearchWord>(`/search-word/${id}`);
};
