import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Attendee, Button, ModalDialogBox } from '../../atoms';
import { IAttendeeList as SmallList, IParticipantStatus } from 'models';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UserStatusBeforeLoginModal } from '../UserStatusBeforeLogin';

interface IAttendeeProps {
  attendees: SmallList[];
  total_attendees: number;
  authenticated: boolean;
  participant_status: IParticipantStatus;
  userId: string;
  showAttendees?: boolean;
}

export const AttendeeList: React.FC<IAttendeeProps> = data => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id } = router.query;

  const [isShowModal, setIsShowModal] = React.useState(false)

  const handleShowModal = () => {
    setIsShowModal(true);
  };
  const handleCloseModal= () => {
    setIsShowModal(false);
  };
  
  const handleClickUser = (event: React.MouseEvent<HTMLAnchorElement> ) => {
    if(!data.userId) {
      event.preventDefault()
      handleShowModal()
    }
  }
  return (
    <>
      {data.authenticated ? (
        <div
          className={`mb-6 flex w-full flex-col gap-y-2 pt-6 ${
            data.total_attendees > 0 ? 'mb-6' : 'mb-0'
          }`}
        >
          <>
            {data.total_attendees > 0 && (
              <p className="regular14-22 text-gray-10">
                {t('People attending the event')}({data?.total_attendees}
                {t('人')}):
              </p>
            )}
            <>
              {data.showAttendees ? (
                <>
                  {data.attendees.map(elem => (
                    <div className={`pb-2`} key={elem.id}>
                      <Link
                        href={
                          elem?.id === data?.userId
                            ? `/profile`
                            : `/user/${elem?.id}`
                        }
                        passHref
                        legacyBehavior
                      >
                        <a onClick={handleClickUser}>
                          <Attendee
                            image={
                              elem.image
                                ? elem.image
                                : '/assets/image/Profile.png'
                            }
                            gender={elem.gender}
                            nick_name={elem.nick_name}
                            age={elem.age}
                            user_status={elem.user_status}
                            key={elem.id}
                            id={elem.id}
                            user_name={elem.user_name}
                            t={t}
                            userId={data?.userId}
                          />
                        </a>
                      </Link>
                    </div>
                  ))}
                  {data.total_attendees > 4 && (
                    <Link
                      href={`/events/${id}/participants`}
                      // className={`${!data?.participant_status && 'blur-sm'}`}
                    >
                      <Button
                        as="div"
                        name={t('View all')}
                        buttonType="secondary"
                      />
                    </Link>
                  )}
                </>
              ) : (
                <div className={'relative z-0'}>
                  {[
                    ...Array(
                      data.total_attendees <= 4 ? data.total_attendees : 4,
                    ),
                  ].map((_, index) => (
                    <div className={'pb-2 blur-sm'} key={index}>
                      <Attendee
                        image={'/assets/image/Profile.png'}
                        gender={'Gender'}
                        nick_name={'Nickname'}
                        age={'00'}
                        user_status={'Status'}
                        key={index}
                        id={index.toString()}
                        user_name={'Username'}
                        t={t}
                        userId={data?.userId}
                      />
                    </div>
                  ))}
                  {!data.showAttendees && data.total_attendees > 0 && (
                    <div className="absolute inset-0 z-10 flex items-center justify-center bg-gray-3 opacity-70">
                      <p className="mb-4 regular20-28 text-gray-10">
                        {t('When you join, you can also see other attendees!')}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          </>
        </div>
      ) : (
        <>
          {data.total_attendees > 0 && (
            <div className="flex items-start gap-2 p-0 my-6">
              <p className="text-black regular14-22">
                {t('People attending the event')}({data.total_attendees}
                {t('人')}):
              </p>
            </div>
          )}
          <Button
            name={t('Join the event to see all attendees!')}
            buttonType="gray"
          />
        </>
      )}
      <ModalDialogBox
        isOpen={isShowModal}
        title={''}
        size="sm"
        onClose={handleCloseModal}
        isCenter
        bodyComponent={
          <UserStatusBeforeLoginModal onClose={handleCloseModal} isShowRadioButton={false} />
        }
      />
    </>
  );
};
