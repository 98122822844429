export const AdminSideBarItemsAfterLogin = [
  {
    icon: '',
    name: 'Event Management',
    path: '/events',
  },
  {
    icon: '',
    name: 'Users Management',
    path: '/users',
  },
  {
    icon: '',
    name: 'Users Participation',
    path: '/participation',
  },
  {
    icon: '',
    name: 'Inquiry Management',
    path: '/inquiry',
  },
  {
    icon: '',
    name: 'Auto Search Word Management',
    path: '/search-word',
  },
  {
    icon: '',
    name: 'Logout',
    path: '',
  },
];
