import { PaginatedResponse, EventPaginatedResponse } from './pagination';
import { IEvents, IParticipantStatus } from 'models';
import { API } from 'config';
import { useMutation } from 'react-query';
import { IToast, toastError, toastSuccess } from 'toast-schema';
import { useTranslation } from 'next-i18next';
import axios, { CancelTokenSource } from 'axios';
import format from 'date-fns/format';
import { removeEmpty } from 'helpers';

interface IEdit {
  data: {};
  id: string;
}
export const getLiveEvents = async (
  pageParam: Number,
  searchQuery?: string,
  startDate?: Date,
  endDate?: Date,
  selectedGenre?: string[],
  selectedLocation?: string | string[],
): Promise<EventPaginatedResponse> => {
  return await API.get('/event-list', {
    params: {
      page: pageParam,
      per_page: 10,
      type: 'live',
      title: searchQuery ? searchQuery : null,
      startDate: startDate && format(startDate!, 'yyyy-MM-dd'),
      endDate: endDate && format(endDate!, 'yyyy-MM-dd'),
      genre:
        selectedGenre && selectedGenre.length > 0
          ? selectedGenre?.join(',')
          : null,
      region: selectedLocation ? selectedLocation : null,
    },
  });
};

export const getUpcomingEvents = async (
  pageParam: Number,
  searchQuery?: string,
  startDate?: Date,
  endDate?: Date,
  selectedGenre?: string[],
  selectedLocation?: string | string[],
): Promise<EventPaginatedResponse> => {
  return await API.get('/event-list', {
    params: {
      page: pageParam,
      per_page: 10,
      type: 'upcoming',
      title: searchQuery ? searchQuery : null,
      startDate: startDate && format(startDate!, 'yyyy-MM-dd'),
      endDate: endDate && format(endDate!, 'yyyy-MM-dd'),
      genre:
        selectedGenre && selectedGenre.length > 0
          ? selectedGenre?.join(',')
          : null,
      region: selectedLocation ? selectedLocation : null,
    },
  });
};

export const getFavoritesEvents = async (
  pageParam: Number,
  searchQuery?: string,
  startDate?: Date,
  endDate?: Date,
  selectedGenre?: string[],
  selectedLocation?: string | string[],
): Promise<EventPaginatedResponse> => {
  return await API.get('/event-list', {
    params: {
      page: pageParam,
      per_page: 10,
      type: 'favorites',
      title: searchQuery ? searchQuery : null,
      startDate: startDate && format(startDate!, 'yyyy-MM-dd'),
      endDate: endDate && format(endDate!, 'yyyy-MM-dd'),
      genre:
        selectedGenre && selectedGenre.length > 0
          ? selectedGenre?.join(',')
          : null,
      region: selectedLocation ? selectedLocation : null,
    },
  });
};

export const AttendEvent = async ({
  data,
  userId,
}: {
  data: IParticipantStatus;
  userId: string;
}): Promise<EventPaginatedResponse> => {
  return await API.post(`/event-list/${data.id}/participant?userId=${userId}`, {
    status: data.status,
  });
};

export const getAllEvents = async (page: number) => {
  return await API.get(`/event-list?page=${page}`);
};

export const getEventById = async (
  id: string | string[],
  auth_token?: string,
): Promise<IEvents> => {
  let opts = {};
  if (auth_token) {
    opts = {
      headers: {
        Authorization: `Bearer ${auth_token}`,
      },
    };
  }

  const { data } = await API.get(`/event-list/${id}`, opts);
  return data;
};

export const delParticipation = async (id: string) => {
  return await API.delete(`/event-list/${id}`);
};

export const cancelParticipation = async (eventParticipationID: string) => {
  return await API.delete(`/event-list/participant/${eventParticipationID}`);
};

export const changeParticipationStatus = async ({
  eventParticipationID,
  newStatus,
}: {
  eventParticipationID: string;
  newStatus: string;
}) => {
  return await API.put(`/event-list/participant/${eventParticipationID}`, {
    status: newStatus,
  });
};


//Attend event
export const useAttendEvent = (toast: IToast) => {
  const { t } = useTranslation();

  return useMutation(AttendEvent, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'event',
        toast,
        description: t('You can now attend the event.'),
      });
      return;
    },
    onError: error => {
      toastError({
        toastId: 'event',
        toast,
        description: t('Error occurred'),
      });
      return;
    },
  });
};

//Cancel Event Participation
export const useCancelParticipation = (toast: IToast) => {
  const { t } = useTranslation();

  return useMutation(cancelParticipation, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'event',
        toast,
        description: t('Participation cancelled successfully.'),
      });
    },
    onError: () => {
      toastError({
        toastId: 'event',
        toast,
        description: t('Unable to cancel this participation, please try again'),
      });
    },
  });
};

//Change Participation Status
export const useChangeStatus = (toast: IToast) => {
  const { t } = useTranslation();
  return useMutation(changeParticipationStatus, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'event',
        toast,
        description: t('Status changed successfully.'),
      });
    },
  });
};

//Search Admin Events
let ct: CancelTokenSource;
export const searchAdminDashboardEvents = async ({
  searchQuery,
  pageParam,
}: {
  searchQuery: string;
  pageParam?: number;
}) => {
  let api;
  if (searchQuery === '' || searchQuery == undefined) {
    api = `/event-list/admin`;
  } else {
    api = `/event-list/admin-search?query=${searchQuery}`;
  }
  if (typeof ct != typeof undefined) {
    ct.cancel('Operation canceled due to new request.');
  }
  ct = axios.CancelToken.source();
  try {
    const response = (await API.get(api, {
      params: removeEmpty({
        page: pageParam,
        per_page: 10,
      }),
      cancelToken: ct.token, //2nd step
    })) as PaginatedResponse<IEvents[]>;
    return response;
  } catch (error) {}
};

//Search  Admin Users
export const searchAdminDashboardUsers = async ({
  searchQuery,
  pageParam,
}: {
  searchQuery: string;
  pageParam?: number;
}) => {
  let api;
  if (searchQuery === '' || searchQuery == undefined) {
    api = `/user`;
  } else {
    api = `/user/search?query=${searchQuery}`;
  }
  if (typeof ct != typeof undefined) {
    ct.cancel('Operation canceled due to new request.');
  }
  ct = axios.CancelToken.source();
  try {
    const response = (await API.get(api, {
      params: removeEmpty({
        page: pageParam,
        per_page: 10,
      }),
      cancelToken: ct.token, //2nd step
    })) as PaginatedResponse<IEvents[]>;
    return response;
  } catch (error) {}
};
//Search Admin participation history
export const searchAdminParticipation = async ({
  pageParam,
}: {
  pageParam?: number;
}) => {
  if (typeof ct != typeof undefined) {
    ct.cancel('Operation canceled due to new request.');
  }
  ct = axios.CancelToken.source();
  try {
    const response = await API.get('/event-list/participant', {
      params: removeEmpty({
        page: pageParam,
        per_page: 10,
      }),
      cancelToken: ct.token, //2nd step
    });
    return response;
  } catch (error) {}
};

export const createEvent = async ({ data }: { data: Object }) => {
  const { data: response } = await API.post('/event-list', data, {
    headers: {
      'Content-Type': `multipart/form-data`,
    },
  });
  return response.data;
};

export const editEvent = async ({ data, id }: IEdit) => {
  const { data: response } = await API.put(`/event-list/${id}`, data);
  return response;
};

export const getFeaturedEvents = async (
  page: number,
  UUID: string,
  eventType: 'previous' | 'going',
): Promise<EventPaginatedResponse> => {
  return await API.get(
    `/event-list/${UUID}/events?type=${eventType}&page=${page}`,
  );
};

//toggle favorite event
export const toggleFavorite = async (eventId: string) => {
  return await API.post(`/event-favorites/toggle/${eventId}`);
};
