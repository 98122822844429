import React from 'react';
import { AdminLayout } from '../AdminLayout';
import { Controller, useForm } from 'react-hook-form';
import { Button, InputField } from 'ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { autoSearchWordSchema } from 'validations';
import { IAutoSearchWordInput } from 'models';
import { useMutation, useQuery } from 'react-query';
import { toastError, toastSuccess } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import {
  addAutoSearchWord,
  getAutoSearchWordById,
  updateAutoSearchWord,
} from 'services';

interface AutoSearchWordFormProps {
  authenticated: boolean;
  logOut: () => Promise<void>;
  edit?: boolean;
}
export const AutoSearchWordForm: React.FC<AutoSearchWordFormProps> = ({
  authenticated,
  logOut,
  edit,
}) => {
  const toast = useToast();
  const router = useRouter();
  const { t } = useTranslation();
  const { id } = router.query;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
    reset,
  } = useForm<IAutoSearchWordInput>({
    mode: 'all',
    resolver: yupResolver(autoSearchWordSchema),
  });

  //fetch search word data
  useQuery(
    ['get-auto-search-word-by-id', id],
    () => getAutoSearchWordById(id! as string),
    {
      enabled: !!edit && authenticated && !!id,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: data => {
        reset({
          search_word: data.data.search_word,
        })
      },
    },
  );

  //add auto search word
  const { mutateAsync: addSearchWordMutation, isLoading } = useMutation(
    edit ? (sw: string) => updateAutoSearchWord(id as string, sw) : addAutoSearchWord,
    {
      onSuccess: () => {
        toastSuccess({
          toastId: 'search-word',
          toast,
          description: t(
            `Auto search word ${edit ? 'updated' : 'added'} successfully.`,
          ),
        });
        router.push('/search-word');
        return;
      },
      onError: () => {
        toastError({
          toastId: 'search-word',
          toast,
          description: t(
            `Something went wrong while ${edit ? 'updating' : 'adding'
            }  auto search word.`,
          ),
        });
      },
    },
  );

  const onSubmit = (data: IAutoSearchWordInput) => {
    addSearchWordMutation(data.search_word);
  };

  return (
    <AdminLayout
      title={edit ? t(`Edit Auto Search Word`) : t(`Add Auto Search Word`)}
      authenticated={authenticated ?? false}
      logOut={logOut}
    >
      <div className={'w-1/2'}>
        <form
          className="flex flex-col"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <Controller
            name="search_word"
            control={control}
            render={({ field }) => (
              <InputField
                field={field}
                label="Auto Search Word (*separate each word with a comma (,))"
                name="auto search word"
                error={errors.search_word?.message as string}
                count={watch('search_word')?.length || 0}
                maxCount={100}
              />
            )}
          />
          <div style={{ width: 300 }} className="mb-6 w-44">
            <Button
              disabled={isSubmitting || isLoading}
              loading={isSubmitting || isLoading}
              name={edit ? 'update-button' : 'add-button'}
              type="submit"
            />
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};
