import React from 'react';
import {
  Button,
  PlacementWithLogical,
  Tooltip as Chakratooltip,
  Text,
} from '@chakra-ui/react';

interface Props {
  tooltipIcon?: React.ReactNode;
  toolTipText?: string;
  tooltipContent: string;
  placement?: PlacementWithLogical;
  maxWidth?: string;
}

export const Tooltip: React.FC<Props> = ({
  tooltipIcon,
  tooltipContent,
  toolTipText,
  placement = 'top',
  maxWidth,
}) => {
  return (
    <Chakratooltip
      label={tooltipContent}
      placement={placement}
      fontSize="sm"
      hasArrow
    >
      {tooltipIcon ? (
        <Button>{tooltipIcon}</Button>
      ) : (
        <Text
          display={'block'}
          maxW={maxWidth}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          whiteSpace={'nowrap'}
        >
          {toolTipText}
        </Text>
      )}
    </Chakratooltip>
  );
};
