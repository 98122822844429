import { List, ListItem } from '@chakra-ui/react';
import React from 'react';
import Link from 'next/link';
import { Icon } from '@iconify/react';

interface NavBarItems {
  icon: string;
  name: string;
  path: string;
}
type SidebarProps = {
  drawerItems: NavBarItems[];
  onClose: Function;
  isOpen: boolean;
  variant: string;
};
export const Sidebar: React.FC<SidebarProps> = ({ drawerItems }) => {
  return (
    <div className="hidden md:block">
      <List spacing={6}>
        {drawerItems.map(item => (
          <ListItem key={item.name}>
            {item.path == 'random' ? (
              <span className="regular14-22 text-white">{item.name}</span>
            ) : (
              <>
                <Link href={item.path}>
                  <Icon
                    icon={item.icon}
                    width={'21px'}
                    height={'21px'}
                    color="white"
                  />
                  <span className="semibold16-24 text-red-500">
                    {item.name}
                  </span>
                </Link>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};
