import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'ui';
import Router from 'next/router';
import { toastError, toastSuccess } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { sendEmailVerification, User } from 'firebase/auth';
import { AuthContextProps } from 'config';

interface IIdentityVerification {
  logOut: () => void;
  user?: User | null;
  setLoading: (args: boolean) => void;
  setLogoutStatus: (args: boolean) => void;
}
export const IdentityVerification: React.FC<IIdentityVerification> = ({
  setLoading,
  user,
  logOut,
  setLogoutStatus,
}) => {
  const { t } = useTranslation('common');
  const [seconds, setSeconds] = useState(45);
  const toast = useToast();
  const [disableButton, setDisableButton] = useState(true);
  const [showTimer, setShowTimer] = useState(true);

  const onResendVerificationLink = async () => {
    setLoading(true);
    try {
      await sendEmailVerification(user!, {
        url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/verify-email`,
      });
      toastSuccess({
        toastId: 'verify-email',
        toast,
        description: t('Verification Email sent'),
      });
      setSeconds(45);
      setDisableButton(true);
      setShowTimer(true);
      setLoading(false);
    } catch (error: any) {
      toastError({
        toastId: 'sign-up-error',
        toast,
        description: t('Something went wrong.'),
      });
      setLoading(false);
      setDisableButton(false);
      setShowTimer(false);
      setSeconds(0);
    }
  };

  useEffect(() => {
    if (seconds === 0) {
      setDisableButton(false);
      setShowTimer(false);
      return;
    }
    let interval = setTimeout(() => {
      setSeconds(seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds, showTimer]);

  const handleLogout = () => {
    setLogoutStatus(true);
    logOut();
  };

  useEffect(() => {
    if (user) {
      if (user?.emailVerified) {        
        Router.push('/usage');
      }
    }
  }, [user]);

  return (
    <div className="items-start">
      <h2 className="semibold20-28 text-gray-9">
        {t('Identity Verification')}
      </h2>
      <div className="pt-2 mb-6 regular14-22 text-gray-8">
        <p> {t('identity-verification-text1')}</p>
        {user?.email && (
          <p className="pt-6">
            {t('Email Address')}: {user?.email}{' '}
          </p>
        )}
        <p className="pt-6">{t('identity-verification-text2')}</p>
      </div>
      <Button
        name={`${t('Resend Email')} ${showTimer ? `(${seconds}s)` : ''}`}
        disabled={disableButton}
        onClick={() =>
          !user?.emailVerified
            ? onResendVerificationLink()
            : toastSuccess({
                toastId: 'profile',
                toast,
                description: t('Email has already been verified.'),
              })
        }
      />
      <p className="mb-6 text-gray-7 regular14-22">
        {t('If your email address is incorrect. Please register again.')}
      </p>
      <Button
        name={t('Return to registration screen')}
        buttonType="secondary"
        onClick={handleLogout}
      />
    </div>
  );
};

export default IdentityVerification;
