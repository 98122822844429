import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, ModalDialogBox } from '../../atoms';
import { useTranslation } from 'react-i18next';
import {
  IEvents,
  IParticipantStatus,
  IProfileInputs,
  ScheduleTimes,
} from 'models';
import { toggleFavorite, useAttendEvent } from 'services';
import { startOfDay } from 'date-fns';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { UserStatusModal } from '../UserStatus';
import { ChangeUserStatusModal } from '../ChangeUserStatus';
import { UserStatusBeforeLoginModal } from '../UserStatusBeforeLogin';
import { Icon } from '@iconify/react';
import { User } from 'firebase/auth';
import { toastError, toastInfo, toastSuccess } from 'toast-schema';
import { isEmptyObject, setSessionStorage } from 'helpers';
import { I18n } from 'next-i18next';
import { favoriteFillIcon, favoriteOutlineIcon } from '../../../assets';
import { useMutation, useQueryClient } from 'react-query';

interface EventDetailsCardProps {
  eventId: string;
  date: string | number | Date;
  time: ScheduleTimes;
  venue: string[];
  website: string;
  user?: User | null;
  participant_status?: IParticipantStatus;
  userProfile: IProfileInputs;
  authenticated?: boolean | null;
  refetch: () => void;
  genre: string[] | null;
  isMyFavorite: boolean;
  favorite_counts: number;
  initializing?: boolean;
  event_name: string;
}

const getMonth = (i18n: I18n, month: number) => {
  const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
  return i18n.language != 'ja'
    ? formatter.format(new Date(2003, month, 12))
    : (month + 1).toString();
};

const getDate = (d: number) => {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};

interface YMD {
  year: number;
  month: string;
  day: string;
}

const useDate = (timestamp: Date): YMD | undefined => {
  const { i18n } = useTranslation();
  const [formattedDate, setFormattedDate] = useState<YMD>();

  useEffect(() => {
    const day =
      i18n.language == 'ja'
        ? timestamp.getDate().toString()
        : getDate(timestamp.getDate());
    setFormattedDate({
      year: timestamp.getFullYear(),
      month: getMonth(i18n, timestamp.getMonth()),
      day,
    });
  }, [timestamp, i18n]);

  return formattedDate;
};

export const EventDetailsCard: React.FC<EventDetailsCardProps> = data => {
  const router = useRouter();
  const { id } = router.query;
  const toast = useToast();
  const { t } = useTranslation();
  const [selectedStatus, setSelectedStatus] = useState('');
  const queryClient = useQueryClient();

  const returnSteps = () => {
    if (data?.userProfile) {
      // if (!data?.userProfile?.subscription) {
      //   toastInfo({
      //     toastId: 'toast',
      //     toast,
      //     description: t('Please subscribe to proceed.'),
      //   });
      //   router.push('/subscription');
      //   return;
      // }
      if (!isEmptyObject(data?.userProfile!) && !data?.userProfile?.username) {
        toastInfo({
          toastId: 'toast',
          toast,
          description: t('Please update your profile to proceed.'),
        });
        router.push('/profile/edit');
        return;
      }
    }
  };

  const {
    isOpen: isOpenSetStatus,
    onOpen: onOpenSetStatus,
    onClose: onCloseSetStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenNotLoggedIn,
    onOpen: onOpenNotLoggedInStatus,
    onClose: onCloseNotLoggedInStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const labelClass = 'text-[16px] text-gray-8 mr-[5px]';
  const rowClass = 'flex px-[8px] pt-[8px] flex-wrap';
  const locClass = 'flex px-[8px] pt-[8px] flex-wrap';
  const textClass = 'text-[16px] text-gray-10  mr-[5px]';

  const date = useMemo(() => new Date(data.date), [data.date]);
  const dmy = useDate(date);

  const { mutateAsync: AttendMeetingMutation, isLoading: attendLoading } =
    useAttendEvent(toast);

  useEffect(() => {
    sessionStorage.setItem('event_name', data.event_name);
    sessionStorage.setItem(
      'event_participation_status',
      data.participant_status?.status || '',
    );
  }, [data.participant_status?.status]);

  const handleAttendEventClick = () => {
    if (data.initializing) {
      return;
    }
    setSessionStorage('returnPath', `/events/${id}`);

    if (!data.authenticated) {
      onOpenNotLoggedInStatus();      
      return;
    }
    if (data.user) {      
      if (
        !data?.user?.emailVerified &&
        data?.user?.providerData[0].providerId === 'password'
      ) {
        toastInfo({
          toastId: 'toast',
          toast,
          description: t('Please verify your email before you proceed.'),
        });
        router.push('/verify-email');
        return;
      }
    }
    returnSteps();
    onOpenSetStatus();
  };

  const onAttend = async () => {
    {
      data?.userProfile?.id &&
        data?.eventId &&
        selectedStatus &&
        (await AttendMeetingMutation({
          data: { status: selectedStatus, id: data.eventId },
          userId: data.userProfile?.id,
        }));
      onCloseSetStatus();
      data.refetch();
    }
  };

  const dt = useMemo(() => data.time, [data.time]);
  const [time, setTime] = useState<typeof data.time>();
  useEffect(() => {
    setTime(dt);
  }, [dt]);

  const { mutateAsync: toggleFavoriteMutation } = useMutation(toggleFavorite, {
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['event_details', data.eventId],
      });
      const prevData = queryClient.getQueryData<IEvents>([
        'event_detail',
        data.eventId,
      ]);
      queryClient.setQueryData<IEvents | undefined>(
        ['event_detail', data.eventId],
        old =>
          old && {
            ...old,
            has_favorite: !data.isMyFavorite,
            favorite_counts: data.isMyFavorite
              ? data.favorite_counts - 1
              : data.favorite_counts + 1,
          },
      );

      return { prevData };
    },
    onError: (e, __, context) => {
      queryClient.setQueryData<IEvents | undefined>(
        ['event_detail', data.eventId],
        context?.prevData,
      );
      toastError({
        toastId: 'profile',
        toast,
        description: t('Error occurred'),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['event_detail', data.eventId]);
    },
    onSuccess: () => {
      !data.isMyFavorite
        ? toastSuccess({
            toastId: 'favorite-add',
            toast,
            description: t('Added to favorites.'),
          })
        : toastSuccess({
            toastId: 'favorite-remove',
            toast,
            description: t('Removed from favorites.'),
          });
    },
  });

  const handleToggleFavorite = () => {
    if (!data.authenticated) {
      return;
    }

    toggleFavoriteMutation(data.eventId);
  };
  return (
    <>
      <div className="mt-4 mb-6">
        <div className="bg-gray-1 rounded-[8px] pb-[8px]">
          <div className={rowClass}>
            <Icon
              icon="ant-design:calendar-outlined"
              width="24"
              height="24"
              className={'text-gray-7 mr-1'}
            />
            <p className={labelClass}>{t('Date:')}</p>
            {dmy ? (
              <p className={textClass}>
                {t('date_format', {
                  year: dmy?.year,
                  month: dmy?.month,
                  day: dmy?.day,
                })}
              </p>
            ) : (
              <p className={`${textClass} invisible`}>.</p>
            )}
          </div>
          {data.time && (
            <div className={rowClass}>
              <p className={labelClass}>{t('Time:')}</p>
              <p className={textClass}>
                {time?.OpeningTime && (
                  <span className={'mr-1'}>
                    {t('Opening:')} {time.OpeningTime.getHours()}:
                    {String(time.OpeningTime.getMinutes()).padStart(2, '0')}
                  </span>
                )}
              </p>
              <p className={textClass}>
                {time?.StartTime && (
                  <span className={'mr-1'}>
                    {t('Start:')} {time.StartTime.getHours()}:
                    {String(time.StartTime.getMinutes()).padStart(2, '0')}
                  </span>
                )}
              </p>
            </div>
          )}
          <div className={locClass}>
            <p className={`${labelClass} flex flex-none`}>{t('Location:')}</p>
            <div>
              <p
                className={`${textClass} line-clamp-2 xxs:max-w-[200px] xs:max-w-xs flex max-w-[80px] flex-wrap sm:max-w-[384px]`}
              >
                {data.venue?.length > 0 && data.venue[0]}
              </p>
              <a
                className="cursor-pointer text-blue-5"
                target={'_blank'}
                rel="noreferrer"
                href={`https://maps.google.com/?q=${data?.venue}`}
              >
                {t('View map')}
              </a>
            </div>
          </div>

          <div className={rowClass}>
            <p className={labelClass}>{t('Website:')}</p>
            <p className="text-blue-5 mr-[5px] overflow-hidden text-[16px]">
              <a href={`${data.website}`} target={'_blank'} rel="noreferrer">
                {data.website}
              </a>
            </p>
          </div>
          {data.genre != null && (
            <div className={`${rowClass} gap-2`}>
              {data.genre.map((g, index) => (
                <p className={'bg-gray-2 h-[26px] px-2 py-0.5'} key={index}>
                  <span className={'text-gray-7'}>{t(g)}</span>
                </p>
              ))}
            </div>
          )}
        </div>
      </div>

      {data.participant_status ? (
        <div className="flex flex-col items-start p-0">
          <div className="mb-1 semibold16-24 text-gray-10">
            <span className="text-primary">{t('Status:')}</span>
            <p>{t(data.participant_status.status)}</p>
          </div>
          {date >= startOfDay(new Date()) && (
            <div className="flex items-baseline justify-around w-full gap-6">
              <div className="w-full">
                <Button
                  name={t('Change Status')}
                  onClick={onOpenChangeStatus}
                />
              </div>

              <div
                className="mr-2 flex text-[32px]"
                onClick={handleToggleFavorite}
              >
                <span
                  className={`${
                    !data?.authenticated ? 'cursor-default' : 'cursor-pointer'
                  }`}
                >
                  {!data.isMyFavorite ? favoriteOutlineIcon : favoriteFillIcon}
                </span>
                {data.favorite_counts > 0 && (
                  <span className="regular24-32">
                    {data?.favorite_counts <= 999
                      ? data?.favorite_counts
                      : '999+'}
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        date >= startOfDay(new Date()) && (
          <div className="flex items-baseline justify-around w-full gap-2">
            <div className="w-full">
              <Button
                name={t('Attend Event')}
                onClick={handleAttendEventClick}
              />
              {/* Show if user is not participated in this event */}
            </div>
            <div
              className="mr-2 flex text-[32px]"
              onClick={handleToggleFavorite}
            >
              <span
                className={`${
                  !data?.authenticated ? 'cursor-default' : 'cursor-pointer'
                }`}
              >
                {!data.isMyFavorite ? favoriteOutlineIcon : favoriteFillIcon}
              </span>
              {data.favorite_counts > 0 && (
                <span className="regular24-32">
                  {data?.favorite_counts <= 999
                    ? data?.favorite_counts
                    : '999+'}
                </span>
              )}
            </div>
          </div>
        )
      )}

      <hr className="border-gray-5" />

      <ModalDialogBox
        isOpen={isOpenSetStatus}
        title={t('Tell us about your status')}
        size="sm"
        onClose={() => {
          setSelectedStatus('');
          onCloseSetStatus();
        }}
        isCenter
        bodyComponent={
          <UserStatusModal
            onClose={onCloseSetStatus}
            setSelectedStatus={setSelectedStatus}
            loading={attendLoading}
            onSelectStatus={onAttend}
            selectedStatus={selectedStatus}
          />
        }
      />
      <ModalDialogBox
        isOpen={isOpenNotLoggedIn}
        title={t('Tell us about your status')}
        size="sm"
        onClose={() => {
          setSelectedStatus('');
          onCloseNotLoggedInStatus();
        }}
        isCenter
        bodyComponent={
          <UserStatusBeforeLoginModal onClose={onCloseNotLoggedInStatus} />
        }
      />
      <ModalDialogBox
        isOpen={isOpenChangeStatus}
        title={t('Current Status')}
        size="sm"
        onClose={onCloseChangeStatus}
        isCenter
        bodyComponent={
          <ChangeUserStatusModal
            currentStatus={data?.participant_status?.status} //need to update
            onClose={onCloseChangeStatus}
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
            eventId={data?.eventId}
            refetch={data.refetch}
            participant_status={data.participant_status!}
          />
        }
      />
    </>
  );
};
