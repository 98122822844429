import React from 'react';
import { backArrowIcon, Button, TitleWithIcon } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export const ChangeEmailDone = () => {
  const router = useRouter();
  const { t } = useTranslation('common');

  const onClickBackArrowIcon = () => {
    router.back();
  };

  return (
    <div className="py-3">
      <TitleWithIcon
        title={t('Change Email Address')}
        icon={backArrowIcon}
        onClickIcon={onClickBackArrowIcon}
        className="h-12"
      />
      <div className="pt-3">
        <div className="regular16-24 text-gray-8 mt-6 flex h-[120px] flex-col items-start justify-between">
          <p className={'whitespace-pre-line'}>
            {t(
              'A verification email has been sent to your new email address.\n\nPlease follow the instructions in the email to complete the changes.',
            )}
          </p>
        </div>
      </div>
      <Link href={'/'} className="mt-6">
        <Button as="div" name={t('Go to Top Page')} />
      </Link>
    </div>
  );
};
