import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
interface IProps {
  children: ReactElement;
}

export const NoInternetConnection = (props: IProps) => {
  const [isOnline, setOnline] = useState<boolean>(true);
  const { t } = useTranslation();
  const router = useRouter();
  // On initization set the isOnline state.

  useEffect(() => {
    setOnline(navigator.onLine);

    // Update network status
    const handleStatusChange = () => {
      setOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);
    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline, router]);

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <>
        <>{props.children}</>
        <div className="flex justify-center">
          <div
            className={`text-gray-1 z-999 fixed bottom-2 z-[999] flex w-[250px]
        rounded-md`}
          >
            <Alert
              status="error"
              className="flex justify-center h-12 rounded-lg bg-red-6"
            >
              <AlertIcon className="w-6 h-6 pr-1 text-white" />

              <AlertDescription>
                {t('You are currently offline.')}
              </AlertDescription>
            </Alert>
          </div>
        </div>
      </>
    );
  }
};
