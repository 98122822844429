import 'ui/styles/globals.css';
import { NoInternetConnection, theme } from 'ui';
import { AuthContextProvider, useAuth } from '../utils/auth';
import { appWithTranslation } from 'next-i18next';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { clearSessionStorage } from 'helpers';
import NextNProgress from 'nextjs-progressbar';
import { ChatListProvider } from 'contexts';
import dynamic from 'next/dynamic';
import { ChakraProvider } from '@chakra-ui/react';
import axios from 'axios';
import { captureException } from '@sentry/nextjs';
import { source } from 'ui';
import { API } from 'config';

const Analytics = dynamic(
  () => import('../components/organisms/Analytics').then(m => m.Analytics),
  { ssr: false },
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: e => {
        if (!axios.isAxiosError(e)) {
          captureException(e);
        }
      },
    },
  },
});

const ChatProviders: React.FC = ({ children }) => {
  const { user } = useAuth();

  return (
    <ChatListProvider user={user}>
      <ChakraProvider theme={theme}>{children}</ChakraProvider>
    </ChatListProvider>
  );
};

const pathsWithStorage = [
  '/subscription',
  '/profile/edit',
  '/profile',
  '/login',
  '/login/email',
  '/signup',
  '/verify-email',
  '/signup/email',
  '/events/[id]',
];

function MyApp({
  Component,
  pageProps,
}: {
  Component: React.ComponentType<any>;
  pageProps: any;
}) {
  const router = useRouter();
  const { asPath } = router;

  const uid = useMemo(() => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('user_id') || '';
    }
    return '';
  }, []);

  const useGetUserInfo = () => {
    const getUserInfo = useCallback(async () => {
      return await API.get(`user/${uid}`);
    }, []);

    return getUserInfo;
  };
  const getUserInfo = useGetUserInfo();

  useEffect(() => {
    if (uid) {
      getUserInfo()
        .then((data: any) => {
          const signup_type = localStorage?.getItem('signup_type');
          if (asPath == '/usage' && process.env.NODE_ENV == 'production') {
            const rentracksScript = document.createElement('script');
            rentracksScript.type = 'text/javascript';
            rentracksScript.src = '';
            rentracksScript.innerHTML = `(function(){
          function loadScriptRTCV(callback){
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://www.rentracks.jp/js/itp/rt.track.js?t=' + (new Date()).getTime();
            if ( script.readyState ) {
              script.onreadystatechange = function() {
                if ( script.readyState === 'loaded' || script.readyState === 'complete' ) {
                  script.onreadystatechange = null;
                  callback();
                }
              };
            } else {
              script.onload = function() {
                callback();
              };
            }
            document.getElementsByTagName('head')[0].appendChild(script);
          }
          loadScriptRTCV(function(){
            _rt.sid = 8351;
            _rt.pid = 11819;
            _rt.price = 0;
            _rt.reward = -1;
            _rt.cname = ${signup_type};
            _rt.ctel = '';
            _rt.cemail = ${data?.data?.email};
            _rt.cinfo = ${data?.data?.id};
            rt_tracktag();
          });
        }(function(){}));
        `;
            document.head.appendChild(rentracksScript);
          }
        })
        .catch((error: any) => {});
    }
  }, [asPath]);

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        clearSessionStorage();
      }
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  useEffect(() => {
    if (!pathsWithStorage.includes(router.pathname)) {
      clearSessionStorage();
    }
  }, [router]);

  return (
    <QueryClientProvider client={client}>
      <Hydrate state={pageProps.dehydratedState}>
        <NoInternetConnection>
          <AuthContextProvider>
            <ChatProviders>
              <Analytics />
              <NextNProgress color="#263238" options={{ showSpinner: false }} />
              <main className={`${source.variable} font-source`}>
                <Component {...pageProps} />
              </main>
            </ChatProviders>
          </AuthContextProvider>
        </NoInternetConnection>
      </Hydrate>
    </QueryClientProvider>
  );
}

export default appWithTranslation(MyApp);
