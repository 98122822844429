export const AdminSideBarItems = [
  {
    icon: '',
    name: 'Event Management',
    path: '/events',
  },
  {
    icon: '',
    name: 'Users Management',
    path: '/users',
  },
  {
    icon: '',
    name: 'Users Participation',
    path: '/participation',
  },
  {
    icon: '',
    name: 'Inquiry Management',
    path: '/inquiry',
  },
  {
    icon: '',
    name: 'Login',
    path: '/login',
  },
];
