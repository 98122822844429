import React from 'react';
import Image from "next/image";
import { useTranslation } from 'next-i18next';
import { Button } from '../../atoms';
import { useRouter } from 'next/router';

export const FavoriteBeforeAuth = ({
  setRedirectedFromNavDrawer,
}: {
  setRedirectedFromNavDrawer: (args: boolean) => void;
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <>
      <figure className="mt-[30px] flex justify-center">
        <Image
          src="/assets/svg/favorite.svg"
          height={100}
          width={100}
          alt={'favorite'}
          className={'cursor-pointer'}
        />
      </figure>
      <p className="my-8 semibold14-22 text-gray-7">
        {t(
          'You can add live shows and events you like to favorites and easily check back later!',
        )}
      </p>
      <Button
        name="Log in to view favorites"
        onClick={() => {
          setRedirectedFromNavDrawer(false);
          router.push('/login');
        }}
      />
      <Button
        name="Sign up and add to favorites"
        marginTop="mt-2"
        onClick={() => {
          setRedirectedFromNavDrawer(false);
          router.push('/signup');
        }}
      />
    </>
  );
};
