import React from 'react';
import { leftIcon, rightIcon, TableComponent, TableHeader } from 'ui';
import { useRouter } from 'next/router';
import ReactPaginate from 'react-paginate';

type PaginationComponentProps = {
  pageCount: number;
  currentPage?: number;
};
export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  pageCount,
  currentPage,
}) => {
  const router = useRouter();
  const handlePageClick = ({ selected: selectedPage }: any) => {
    if (!!(selectedPage + 1)) {
      router.replace({
        query: {
          ...router.query,
          page: !!selectedPage ? selectedPage + 1 : 1
        }
      }, undefined, {
        shallow: true,
      });
    }
  };

  return (
    <>
      <ReactPaginate
        previousLabel={leftIcon}
        nextLabel={rightIcon}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        initialPage={currentPage! - 1}
        containerClassName={'pagination'}
        previousLinkClassName={''}
        nextLinkClassName={''}
        disabledClassName={
          'cursor-not-allowed !bg-gray-3 text-gray-6  border-2 border-red-600'
        }
        activeClassName={'bg-gray-1 border-2 !border-blue-6 text-blue-6'}
        className="[&>li]:border-gray-6 text-gray-9 [&>li]:bg-gray-1 flex gap-x-2 p-3 [&>li]:flex [&>li]:h-8 [&>li]:w-fit [&>li]:min-w-[8] [&>li]:items-center [&>li]:justify-center [&>li]:rounded-sm [&>li]:border [&>li]:p-3"
      />
    </>
  );
};
