import { useToast } from '@chakra-ui/react';
import { getIndividualSessionItems } from 'helpers';
import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const RestrictedRoute = (AuthComponent: any) => {
  function RestrictedComponent({ children }: React.PropsWithChildren<{}>) {
    const {
      authenticated,
      user,
      loading,
      userProfile,
      action: userAction,
      loginStatus,
    } = useAuth();
    const returnPath = getIndividualSessionItems('returnPath');
    const toast = useToast();

    useEffect(() => {
      const provider = user?.providerData[0]?.providerId;
      if (
        authenticated &&
        user !== null &&
        !loading &&
        userProfile?.subscription !== undefined &&
        userProfile?.username !== undefined
      ) {
        if (!user?.emailVerified && provider == 'password') {
          Router.push('/verify-email');
        } else if (returnPath) {
          Router.push(`${returnPath}`);
        } else if (
          userAction == 'sns' &&
          provider !== 'password' &&
          !loginStatus
        ) {
          Router.push('/subscription');
          return;
        } else {
          Router.push('/');
        }
      }
    }, [
      authenticated,
      user,
      userProfile,
      loading,
      returnPath,
      toast,
      userAction,
      loginStatus
    ]);
    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <RestrictedComponent>
          <AuthComponent {...this.props} />
        </RestrictedComponent>
      );
    }
  };
};
