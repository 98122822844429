import { API } from 'config';
import { useMutation, useQueryClient } from 'react-query';
import { IToast, toastError, toastSuccess } from 'toast-schema';
import { loadStripe } from '@stripe/stripe-js/pure';
import { i18n, TFunction } from 'next-i18next';

export const getPricingInformation = async () => {
  return await API.get('subscriptions/pricing');
};

const registerPayment = async ({
  price_id,
  coupon_code,
}: {
  price_id: string;
  coupon_code: string;
}) => {
  return await API.post(`/subscriptions`, {
    price_id: price_id,
    coupon_code: coupon_code,
  });
};

const cancelPayment = async () => {
  return await API.delete(`/subscriptions`);
};

export const useRegisterPayment = (
  toast: IToast,
  t: TFunction,
  status: 'add' | 'edit',
) => {
  const queryClient = useQueryClient();
  return useMutation(registerPayment, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'payment',
        toast,
        description:
          status == 'add'
            ? t('Payment registration successful.')
            : t('Payment information updated successfully.'),
      });
      queryClient.refetchQueries('auth-user-profile');
    },
    onError: (error: { response: { data: { error: string } } }) => {
      if (i18n?.exists(error?.response?.data?.error)) {
        toastError({
          toastId: 'payment',
          toast,
          description: t(error?.response?.data?.error),
        });
      } else {
        toastError({
          toastId: 'payment',
          toast,
          description:
            status == 'add'
              ? t('Something went wrong during payment registration.')
              : t('Something went wrong while updating payment information.'),
        });
      }
    },
  });
};

export const useCancelPayment = (toast: IToast, t: TFunction) => {
  const queryClient = useQueryClient();
  return useMutation(cancelPayment, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'payment',
        toast,
        description: t('Subscription cancellation successful.'),
      });
      queryClient.refetchQueries('auth-user-profile');
    },
    onError: () => {
      toastError({
        toastId: 'payment',
        toast,
        description: t(
          'Something went wrong during the subscription cancellation.',
        ),
      });
    },
  });
};

//Fetch setup intent id
export const getSetupIntent = async (locale: 'ja' | 'en') => {
  const { data } = await API.post('/subscriptions/setup');
  return {
    ...data,
    stripe: loadStripe(data.publishable_key, { locale: locale }),
  };
};
