import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const UsageRoute = (AuthComponent: any) => {
  function UsageComponent({ children }: React.PropsWithChildren<{}>) {
    const { authenticated, loading } = useAuth();

    useEffect(() => {
      const isFromSignUp = globalThis.localStorage?.getItem('signup');
      !isFromSignUp && Router.push('/');
      return;
    }, []);

    useEffect(() => {
      if (!authenticated && !loading) {
        Router.push('/login');
        return;
      }
    }, [authenticated, loading]);

    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <UsageComponent>
          <AuthComponent {...this.props} />
        </UsageComponent>
      );
    }
  };
};
