import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { IProfileInputs } from 'models';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { searchAdminDashboardUsers } from 'services';
import { toastError } from 'toast-schema';
import {
  eyeVisible,
  NoResultsFound,
  PageLoader,
  TableHeader,
  Tooltip,
} from 'ui';
import Link from 'next/link';
import { PaginationComponent } from '../Pagination';
import { PER_PAGE } from 'constant';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { IPagination } from 'services/pagination';

interface Props {
  searchQuery: string;
  authenticated: boolean;
  currentPage: number;
  setCurrentPage: (arg: number) => void;
  kw?: string;
}

export const TableComponent: React.FC<Props> = ({
  searchQuery,
  authenticated,
  currentPage,
  setCurrentPage,
  kw,
}) => {
  const toast = useToast();
  const [pagination, setPagination] = useState<IPagination>();
  const [pageCount, setPageCount] = useState<number>(1);
  const { t } = useTranslation();
  const router = useRouter();

  const { data, isLoading } = useQuery(
    ['search-users', kw, currentPage],
    () => searchAdminDashboardUsers({ searchQuery, pageParam: currentPage }),
    {
      retry: false,
      enabled: authenticated,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );

  useEffect(() => {
    setPagination(data?.pagination);
    setPageCount(Math.ceil(pagination?.count! / PER_PAGE));
  }, [data, pagination?.count]);

  useEffect(() => {
    setCurrentPage(parseInt(router.query.page as string));
  }, [router.query]);

  const noInfo =
    data?.data?.length === 0 ||
    (data?.data?.length === 1 &&
      data.data[0].email === process.env.NEXT_PUBLIC_ADMIN_EMAIL);

  return (
    <>
      {isLoading && <PageLoader />}
      <TableContainer>
        <Table variant="simple">
          <TableHeader
            titleList={[
              'Username',
              'Nickname',
              'Email',
              'Area of Residence',
              'Genre',
              'Operation',
            ]}
          />
          <Tbody>
            {noInfo &&
              searchQuery !== '' && ( //admin info is hidden
                <Tr className="">
                  <Td colSpan={7}>
                    <div className="mx-auto flex items-center justify-center">
                      <NoResultsFound text={t("No results found!")} />
                    </div>
                  </Td>
                </Tr>
              )}
            {noInfo && searchQuery === '' ? (
              <Tr className="">
                <Td colSpan={7}>
                  <div className="mx-auto flex items-center justify-center">
                    <NoResultsFound text="No user information available!" />
                  </div>
                </Td>
              </Tr>
            ) : (
              <>
                {data?.data?.map((data: IProfileInputs) => (
                  <React.Fragment key={data?.id}>
                    {data?.email !== process.env.NEXT_PUBLIC_ADMIN_EMAIL && (
                      <Tr>
                        <Td className={'!py-3'}>{data?.username || '-'}</Td>
                        <Td className={'!py-3'}>{data?.nick_name || '-'}</Td>
                        <Td className={'!py-3'}>{data?.email || '-'}</Td>
                        <Td className={'!py-3'}>{t(data?.location || '-')}</Td>
                        <Td className={'!py-3'}>
                          <div className="flex flex-wrap items-start justify-start gap-2 text-center">
                            {(!data?.genre || data?.genre.length == 0) && '-'}
                            {data?.genre?.map((g, index) => (
                              <div
                                key={index}
                                className="bg-green-1 border-green-3 flex flex-row items-center justify-between border py-[1px] px-2"
                              >
                                <p className="text-green-6 text-center text-sm">
                                  {t(g)}
                                </p>
                              </div>
                            ))}
                          </div>
                        </Td>
                        <Td className="text-blue-6 !py-3 hover:underline">
                          <Link href={`/users/${data?.id}`}>
                            <Tooltip
                              tooltipContent={t('View Details')}
                              tooltipIcon={eyeVisible}
                            />
                          </Link>
                        </Td>
                      </Tr>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {pagination &&
        data?.data.length !== 0 &&
        pagination?.count > PER_PAGE && (
          <PaginationComponent
            pageCount={pageCount}
            currentPage={currentPage}
          />
        )}
    </>
  );
};