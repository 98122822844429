import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

type NoResultsFoundProps = {
  text?: string;
};
export const NoResultsFound: React.FC<NoResultsFoundProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-3 flex flex-col items-center md:mt-8">
      <Image
        priority
        src="/assets/svg/sad.svg"
        alt="sad-image"
        height={114}
        width={114}
      />
      <p className="text-gray-7 semibold14-22 mt-1 text-center md:text-[18px]">
        {text ? <>{t(text)}</> : <>{t('No results found!')}</>}
      </p>
    </div>
  );
};
