import React from 'react';
import { backArrowIcon } from '../../../assets';
import { TitleWithIcon } from '../TitleWithIcon';
import { useRouter } from 'next/router';
import { SearchBar } from '../../atoms';
import { useTranslation } from 'next-i18next';

type AdminHeaderProps = {
  title: string;
};

export const AdminHeader: React.FC<AdminHeaderProps> = ({ title }) => {
  const router = useRouter();
  const handleBackArrowIconClick = () => {
    router.back();
  };

  return (
    <>
      <div className=" flex w-[100%] flex-row justify-between  pb-[5px]">
        <div className="mx-4 flex w-full flex-auto items-center justify-between ">
          {title == 'Event List' ||
          title == 'Users Management' ||
          title == 'Inquiry Management' ||
          title == 'Users Participation' ||
          title == 'Auto Search Word Management' ? (
            <TitleWithIcon
              className={'w-full'}
              title={title}
              onClickIcon={handleBackArrowIconClick}
              bgColor={'bg-gray-1'}
            />
          ) : (
            <TitleWithIcon
              className={'w-full'}
              title={title}
              icon={backArrowIcon}
              onClickIcon={handleBackArrowIconClick}
              bgColor={'bg-gray-1'}
            />
          )}
        </div>
      </div>
    </>
  );
};
