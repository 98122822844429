import { Button, RadioButton } from '../../atoms';
import { useTranslation } from 'next-i18next';
import React from 'react';
import Link from 'next/link';
interface IProps {
  onClose: () => void;
  isShowRadioButton?: boolean;
}

export const UserStatusBeforeLoginModal = ({ onClose, isShowRadioButton = true }: IProps) => {
  const { t } = useTranslation('common');

  const items = [
    {
      value: 'extra',
      title: t('I have an extra ticket'),
    },
    {
      value: 'another',
      title: t('I want to go with someone who has extra tickets'),
    },
    {
      value: 'own',
      title: t('I want to go with people who have tickets'),
    },
    {
      value: "I don't have a ticket but would like to go",
      title: t("I don't have a ticket but would like to go"),
    },
    {
      value: 'I want to be matched with someone at the venue',
      title: t('I want to be matched with someone at the venue'),
    },
  ];

  return (
    <div>
      {isShowRadioButton && <RadioButton items={items} defaultValue="" name={'status'} /> }
      
      <div className={'mt-6'}>
        <span> {t('To use MELD, membership registration is required.')} </span>
        <div className={'mt-4'}>
          <Link href="/signup">
            <Button as="div" name={t('Sign Up')} onClick={onClose} />
          </Link>
        </div>
        <span>{t('If you already have an account, please login.')}</span>
        <div className={'mt-4'}>
          <Link href="/login">
            <Button
              as="div"
              name={t('Login')}
              onClick={onClose}
              marginBottom={'mb-3'}
              buttonType={'secondary'}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
