import React, { Dispatch, SetStateAction } from 'react';
import { TabMenu } from 'ui';
import { IEventData } from 'models';

type EventListProps = {
  data: IEventData[];
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  selectedIndex?: number;
  showFilters?: boolean;
};

export const EventList: React.FC<EventListProps> = ({
  data,
  setSelectedTabIndex,
  selectedIndex,
  showFilters,
}) => {
  return (
    <TabMenu
      tabList={data}
      sticky={true}
      persistTabSelection
      selectedIndex={selectedIndex}
      setSelectedTabIndex={setSelectedTabIndex}
      showFilters={showFilters}
    />
  );
};
