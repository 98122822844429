import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const PrivateRoute = (AuthComponent: any) => {
  function PrivateComponent({ children }: React.PropsWithChildren<{}>) {
    const { authenticated, loading } = useAuth();

    useEffect(() => {
      if (!authenticated && !loading) {
        Router.push('/login');
        return;
      }
    }, [authenticated, loading]);

    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      );
    }
  };
};
