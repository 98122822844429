import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const EmailOnlyVerifiedRoute = (
  AuthComponent: any,
) => {
  function EmailOnlyVerifiedComponent({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const { authenticated, user, loading } = useAuth();

    useEffect(() => {
      if (!authenticated && !loading) {
        Router.push('/login');
        return;
      }
      if (authenticated && user !== null && !loading) {
        const provider = user?.providerData[0]?.providerId;

        if (provider !== 'password') {
          Router.push('/');
          return;
        } else if (!user?.emailVerified) {
          Router.push('/verify-email');
          return;
        }
      }
    }, [authenticated, user, user?.emailVerified, loading]);
    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <EmailOnlyVerifiedComponent>
          <AuthComponent {...this.props} />
        </EmailOnlyVerifiedComponent>
      );
    }
  };
};
