import * as Yup from 'yup';
import { LOWERCASE_REGEX, UPPERCASE_REGEX, NUMERIC_REGEX } from 'constant';

//SIGN UP VALIDATION SCHEMA
export const signupSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter'),
  password: Yup.string()
    .required('Please enter')
    .min(6, 'Please enter at least 6 letters password')
    .max(20, 'Please enter password in 20 characters or less'),
});

//LOGIN VALIDATION SCHEMA
export const loginSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter'),
  password: Yup.string().required('Please enter'),
});

//EMAIL SETTING VALIDATION SCHEMA
export const emailSettingSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Please enter'),
});
