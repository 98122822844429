import React, { Dispatch, SetStateAction } from 'react';
import {
  backArrowIcon,
  Button,
  InputField,
  TitleWithIcon,
  PageLoader,
} from 'ui';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IEmailSetting, IProfileInputs } from 'models';
import { emailSettingSchema } from 'validations';
import { EMAIL_ALREADY_IN_USE, LOG_IN_AGAIN, USER_NOT_FOUND } from 'constant';
import { toastError, toastSuccess } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { User } from 'firebase/auth';
import { auth } from 'config';

type EmailSettingProps = {
  setUpdateEmailDone: Dispatch<SetStateAction<boolean>>;
  changeEmail: (email: string) => void;
  logOut: () => void;
  userProfile?: IProfileInputs;
  user?: User;
};

export const EmailSetting: React.FC<EmailSettingProps> = ({
  setUpdateEmailDone,
  changeEmail,
  logOut,
  userProfile,
  user,
}) => {
  const router = useRouter();
  const toast = useToast();
  const { t, i18n } = useTranslation('common');

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IEmailSetting>({
    resolver: yupResolver(emailSettingSchema),
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = async (data: IEmailSetting) => {
    auth.languageCode = i18n?.language.startsWith('en') ? 'en' : 'ja';
    try {
      await changeEmail(data.email);
      toastSuccess({
        toastId: 'update-email',
        toast,
        description: t('identity verification email has been sent.'),
      });
      setUpdateEmailDone(true);
    } catch (error: any) {
      if (error.message === USER_NOT_FOUND) {
        toastError({
          toastId: 'reset',
          toast,
          description: t('The account doesn’t exists'),
        });
        return;
      } else if (error.message === EMAIL_ALREADY_IN_USE) {
        toastError({
          toastId: 'reset',
          toast,
          description: t('Email Address is already registered.'),
        });
        return;
      } else if (error.message === LOG_IN_AGAIN) {
        toastError({
          toastId: 'reset',
          toast,
          description: t('Please login again.'),
        });
        logOut();
        router.push('/login');
        return;
      }
      toastError({
        toastId: 'reset',
        toast,
        description: t('Failed to send identity verification email.'),
      });
    }
  };

  return (
    <div className="py-3">
      {isSubmitting && <PageLoader />}
      <TitleWithIcon
        title={t('Change Email Address')}
        icon={backArrowIcon}
        onClickIcon={router.back}
      />
      <h2 className="semibold16-24 text-gray-9 pt-4 pb-6 !text-[16px]">
        {t('Change Email Address')}
      </h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-2">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField
                field={field}
                label={t('New Email Address:')}
                type="email"
                name="your email address"
                error={errors.email?.message}
              />
            )}
          />
        </div>
        <div className="mt-6">
          <Button disabled={isSubmitting} name={t('Change')} type="submit" />
        </div>
      </form>
    </div>
  );
};
