import * as yup from 'yup';

export const inquiryValidationSchema = yup.object().shape({
  name: yup.string().required('Please enter'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('Please enter'),
  message: yup
    .string()
    .max(2000, 'Please enter message in 2000 characters or less')
    .required('Please enter'),
});
