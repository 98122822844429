export const LOWERCASE_REGEX = /(?=.*[a-z])/;
export const UPPERCASE_REGEX = /(?=.*[A-Z])/;
export const NUMERIC_REGEX = /(?=.*[0-9])/;
export const URl_REGEX =
  /^https?:\/\/(?:www\.)?[a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=%]+$/;
//MASK
const d = /[0-9]/;
export const CARD_MASK_REGEX = [
  d,
  d,
  d,
  d,
  ' ',
  d,
  d,
  d,
  d,
  ' ',
  d,
  d,
  d,
  d,
  ' ',
  d,
  d,
  d,
  d,
];
export const CVC_MASK_REGEX = [d, d, d];
export const EXP_DATE_MASK_REGEX = [d, d, '/', d, d];
