import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { toastInfo } from 'toast-schema';
import { useToast } from '@chakra-ui/react';

export const UserProfileSetRoute = (AuthComponent: any) => {
  function UserProfileSetComponent({
    children,
  }: {
    children: React.ReactNode;
  }) {
    const { authenticated, user, loading, userProfile } = useAuth();
    const toast = useToast();

    useEffect(() => {
      if (!authenticated && !loading) {
        Router.push('/login');
        return;
      }

      if (authenticated && user !== null) {
        const provider = user?.providerData[0]?.providerId;

        if (!(user?.emailVerified || provider !== 'password')) {
          Router.push('/verify-email');
          return;
        }

        if (userProfile && !userProfile?.username && navigator.onLine) {
          toastInfo({
            toastId: 'chat',
            toast,
            description: 'Please update your profile to proceed.',
          });
          Router.push('/profile/edit');
          return;
        }
      }
    }, [authenticated, user, user?.emailVerified, loading, userProfile]);
    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <UserProfileSetComponent>
          <AuthComponent {...this.props} />
        </UserProfileSetComponent>
      );
    }
  };
};
