import { Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React from 'react';

type TableHeaderProps = {
  titleList?: string[];
};
export const TableHeader: React.FC<TableHeaderProps> = ({ titleList }) => {
  const { t } = useTranslation();
  return (
    <Thead border={'2px'} borderColor={'gray.5'}>
      <Tr height={'60px'} backgroundColor="">
        {titleList?.map((title, index) => (
          <Th
            key={index}
            paddingInlineEnd={3}
            textTransform={'inherit'}
            fontSize={'14px'}
            lineHeight={'22px'}
            fontWeight={'bold'}
            color={'black'}
          >
            {t(title)}
          </Th>
        ))}
      </Tr>
    </Thead>
  );
};
