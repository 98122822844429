import { Textarea } from '@chakra-ui/react';
import React from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TextAreaInputProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: () => void;
  error?: string;
  className?: string;
  height?: string;
  field?: ControllerRenderProps<any, any>;
  count?: number;
  maxCount?: number;
  disabled?: boolean
};
export const TextAreaInput = <T extends FieldValues, K extends Path<T>>({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  className,
  height,
  field,
  count,
  maxCount,
  disabled
}: TextAreaInputProps<T, K>) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} `}>
      <div className="flex justify-between pb-2">
        {label && (
          <label
            htmlFor=""
            className="regular14-22 text-gray-9 text-start font-normal"
          >
            {t(label)}
          </label>
        )}
        {maxCount &&
          (count! <= maxCount ? (
            <p className="regular14-22 text-gray-7 text-start font-normal">
              {count!}/{maxCount}
            </p>
          ) : (
            <p className="regular14-22 text-red-5 text-start font-normal">
              {count!}/{maxCount}
            </p>
          ))}
      </div>
      <Textarea
        // placeholder={placeholder}
        size="lg"
        bg={'white'}
        _placeholder={{ color: 'gray.6' }}
        name={name ? name : ''}
        value={value}
        resize={'none'}
        onChange={onChange}
        borderColor={error ? 'red.6' : 'gray.5'}
        fontSize={'regular16-24'}
        focusBorderColor="gray.5"
        borderRadius={'8px'}
        paddingLeft={'12px'}
        paddingEnd={'12px'}
        paddingY={'12px'}
        color="gray.8"
        height={height}
        _hover={{ borderColor: error ? 'red.6' : 'gray.5' }}
        _focus={{ borderColor: error ? 'red.6' : 'gray.5' }}
        disabled={disabled}
        {...field}
      />
      {error == '' ? null : (
        <p className="text-red-6 mt[10]">
          {' '}
          {t(error || '', { name: t(name!) || 'Required name_' })}
        </p>
      )}
    </div>
  );
};
