import { Button } from '../../atoms';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface Props {
  onClose: () => void;
}
export const CurrentStatusModal = ({ onClose }: Props) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Button name={t('Change Status')} marginBottom={'mb-2'}></Button>
      <Button
        name={t('Cancel Participation')}
        onClick={onClose}
        marginBottom={'mb-3'}
      />
    </div>
  );
};
