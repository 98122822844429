import * as React from 'react';
import Image from 'next/image';
import { convertAgeFromId } from 'helpers';
import { TFunction, useTranslation } from 'next-i18next';

export interface IAttendeeResponse {
  id?: string;
  image?: string;
  gender?: string;
  nick_name?: string;
  user_name?: string;
  user_status: string;
  userId: string;
  age?: string;
  t: TFunction;
}

export const Attendee = (data: IAttendeeResponse) => {
  const { t } = useTranslation('common');

  return (
    <div className="flex w-full p-2 rounded-lg cursor-pointer bg-gray-1 gap-x-2">
      <div className="relative flex h-[54px] w-[54px] flex-none">
        <Image
          priority
          fill
          src={data.image ? data.image : '/assets/image/Profile.png'}
          alt={'Event Attendee Profile Picture'}
          style={{ objectFit: 'cover' }}
          className="rounded-md"
        />
      </div>
      <div className="flex-1">
        <div className="flex-2 mb-2 justify-between min-[375px]:flex">
          <p className="semibold16-24 text-gray-10">
            <span>{data?.nick_name}</span>
            <span className="text-gray-7 semibold14-22">
              {data.user_name ? `@${data?.user_name}` : ''}
            </span>
          </p>
          <p className="capitalize regular14-22 text-gray-7">
            {t(data.gender || '')}
            {data.gender && data.age ? ', ' : ''}
            {convertAgeFromId(data?.age!, data.t)}
          </p>
        </div>
        <p className="regular14-22 text-gray-8">{t(data?.user_status)}</p>
      </div>
    </div>
  );
};
