import { API } from 'config';
import { IProfileInputs } from 'models';
import { useMutation, useQueryClient } from 'react-query';
import { IToast, toastError, toastSuccess } from 'toast-schema';
import { TFunction, useTranslation } from 'next-i18next';
import { i18n } from 'next-i18next';

const updateProfile = async ({
  data,
  id,
}: {
  data: IProfileInputs;
  id: string;
}) => {
  return await API.put(`/user/${id}`, data);
};

const updateDisplayImage = async ({ data }: { data: FormData }) => {
  return await API.put(`/user/profile-picture/update`, data);
};

const deleteAccount = async () => {
  return await API.delete(`/user`);
};

export const useUpdateProfile = (toast: IToast) => {
  const { t } = useTranslation();
  return useMutation(updateProfile, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'profile',
        toast,
        description: t('Profile updated successfully.'),
      });
    },
    onError: () => {
      toastError({
        toastId: 'profile',
        toast,
        description: t('Error occurred'),
      });
    },
  });
};

export const useUpdateDisplayImage = (toast: IToast, t: TFunction) => {
  const queryClient = useQueryClient();
  return useMutation(updateDisplayImage, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'profile-picture',
        toast,
        description: t('Profile Picture Updated Successfully.'),
      });
      queryClient.refetchQueries('auth-user-profile');
    },
    onError: (error: { response: { data: { error: string } } }) => {
      if (i18n?.exists(error?.response?.data?.error)) {
        toastError({
          toastId: 'profile-picture',
          toast,
          description: t(error?.response?.data?.error),
        });
      }
      toastError({
        toastId: 'profile-picture',
        toast,
        description: t('Profile picture update failed.'),
      });
    },
  });
};

export const useDeleteAccount = (toast: IToast, t: TFunction) => {
  const queryClient = useQueryClient();
  return useMutation(deleteAccount, {
    onSuccess: () => {
      toastSuccess({
        toastId: 'account',
        toast,
        description: t('Account deleted successfully.'),
      });
      queryClient.removeQueries('auth-user-profile');
    },
    onError: (error: { response: { data: { error: string } } }) => {
      if (i18n?.exists(error?.response?.data?.error)) {
        toastError({
          toastId: 'account',
          toast,
          description: t(error?.response?.data?.error),
        });
      }
      toastError({
        toastId: 'account',
        toast,
        description: t('Account deletion failed.'),
      });
    },
  });
};
