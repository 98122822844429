import { useTranslation } from 'next-i18next';
import React from 'react';
import { Button } from 'ui';

type DeleteConfirmationModalProps = {
  onClose: () => void;
  handleAccountDelete: () => void;
  loading: boolean;
};
export const DeleteConfirmationModal: React.FC<
  DeleteConfirmationModalProps
> = ({ onClose, handleAccountDelete, loading }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="regular16-24 text-gray-8 whitespace-pre-line"
        dangerouslySetInnerHTML={{
          __html: t(
            'Would you like to confirm your account deletion? <br />Once you confirm, you will no longer be able to log in to this account.',
          ),
        }}
      />

      <div className="pt-6">
        <Button
          buttonType="error"
          name={t('Yes')}
          marginBottom={'mb-3'}
          type={'submit'}
          onClick={handleAccountDelete}
          loading={loading}
          disabled={loading}
        />
      </div>
      <div>
        <Button
          buttonType="secondary"
          name={t('No')}
          marginBottom={'mb-3'}
          onClick={onClose}
        />
      </div>
    </>
  );
};
