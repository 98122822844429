import * as yup from 'yup';
import { URl_REGEX } from 'constant';

export const EventSchema = yup

  .object({
    name: yup
      .string()
      .required('Please enter')
      .max(150, 'Please enter title in 150 characters or less'),
    description: yup
      .string()
      .required('Please enter')
      .max(250, 'Please enter description in 250 characters or less'),
    venue: yup
      .string()
      .required('Please enter')
      .max(200, 'Please enter place in 200 characters or less'),
    date: yup.date().required('Please select a date').nullable().default(null),
    manual_search_words: yup
      .string()
      .optional()
      .max(100, 'Please enter up to 100 characters.'),
    auto_search_word: yup.string().optional(),
    website: yup
      .string()
      .matches(URl_REGEX, 'Please enter correct URL')
      .required('Please enter'),
    show_time: yup.object({
      start_time: yup.date().nullable().default(null),
      open_time: yup.date().nullable().default(null),
    }),
  })
  .test(
    'test-incomplete-day-time-input',
    'Please select all show times',
    function (value) {
      if (!!value.show_time.open_time && !!value.show_time.start_time) {
        return true;
      }
      const caseIncompleteInput =
        !value.show_time.open_time || !value.show_time.start_time;
      const nullInput =
        !value.show_time.open_time && !value.show_time.start_time;

      return !caseIncompleteInput || !nullInput;
    },
  );
