import { Radio, RadioGroup, Stack, StackDirection } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

type radioItem = {
  value: string | number;
  title: string;
};

export type RadioButtonProps<T extends FieldValues, K extends Path<T>> = {
  items: radioItem[];
  direction?: StackDirection;
  label?: string;
  defaultValue?: string;
  className?: string;
  error?: string;
  field?: ControllerRenderProps<T, K>;
  name: string;
  setOption?: React.Dispatch<React.SetStateAction<string>>;
};

export const RadioButton = <T extends FieldValues, K extends Path<T>>({
  items,
  direction,
  label,
  defaultValue,
  className,
  error,
  field,
  setOption,
  name,
}: RadioButtonProps<T, K>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${className}`}>
      {label && (
        <div className="pb-2">
          <label
            htmlFor=""
            className="font-normal regular14-22 text-gray-9 text-start"
          >
            {t(label)}
          </label>
        </div>
      )}

      <RadioGroup
        onChange={setOption ? setOption : field?.onChange}
        value={field?.value}
        defaultValue={defaultValue}
      >
        <Stack direction={direction || 'column'}>
          {items?.map((item: radioItem) => (
            <Radio
              value={item.value}
              key={item.value}
              _checked={{
                bg: 'primary',
                padding: '3px',
                width: '16px',
                height: '16px',
                color: 'primary',
                borderRadius: '50%',
                border: '1px',
                backgroundClip: 'content-box',
              }}
              color="gray.5"
              border="1px"
            >
              <p
                className="regular14-22 px-[2px]"
                dangerouslySetInnerHTML={{ __html: t(item.title) }}
              />
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {error == '' ? null : (
        <p className="mt-1 text-sm text-red-6">
          {t(error || '', { name: t(name!) })}
        </p>
      )}
    </div>
  );
};
