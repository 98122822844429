import { TimeSchedules } from 'models';

type GetTime = {
  show_time?: TimeSchedules;
};

export const getOpenAndCloseTime = (time: GetTime) => {
  const open_time = time.show_time
    ? new Date(time.show_time?.open_time!)
    : null;

  const start_time = time.show_time
    ? new Date(time.show_time?.start_time!)
    : null;

  return {
    OpeningTime: open_time,
    StartTime: start_time,
  };
};
