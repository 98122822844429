import { API } from 'config';

export const getUserById = async (id: string | string[]) => {
  const { data } = await API.get(`/user/${id}`, {
    params: { type: 'firebase' },
  });
  return data;
};

export const getUserByUUID = async (id: string | string[]) => {
  const { data } = await API.get(`/user/${id}`);
  return data;
};


export const getAllUsers = (page: number) => {
  return API.get(`/user?page=${page}`);
};
