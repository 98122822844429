import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface propTypes {
  description?: string;
  genres?: string[];
}

export const UserDescription: React.FC<propTypes> = ({
  description,
  genres,
}) => {
  const { t } = useTranslation();
  const bioText = t('bioText');

  return (
    <>
      <div className="regular14-22 mb-[16px] ">
        <h4 className="text-gray-10 my-[16px]">
          {description ? description : bioText}
        </h4>
        {genres && genres.length > 0 && (
          <h4 className="text-gray-7 flex">
            {t('Genre')}:
            <span className="text-gray-10 ml-[4px] w-[80%]">
              {genres?.map((g, index) => {
                if (index == genres?.length - 1) {
                  return t(g);
                }
                return `${t(g)}, `;
              })}
            </span>
          </h4>
        )}
      </div>
      <hr className="border-gray-5" />
    </>
  );
};
