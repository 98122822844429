import React, { Dispatch, SetStateAction } from 'react';
import Image from "next/image";
import { useTranslation } from 'next-i18next';
import { Button } from '../../atoms';
type INoFavorite = {
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
};
export const NoFavorite = ({ setSelectedTabIndex }: INoFavorite) => {
  const { t } = useTranslation();

  const handleClick = () => {
    setSelectedTabIndex(0);
    localStorage.setItem('selectedTab', '0');
  };
  return (
    <>
      <figure className="mt-[30px] flex justify-center">
        <Image
          src="/assets/svg/favorite.svg"
          height={100}
          width={100}
          alt={'favorite'}
          className={'cursor-pointer'}
        />
      </figure>
      <div className="my-8 semibold14-22 text-gray-7">
        <p>{t('There are no live shows in favorites.')}</p>
        <p>
          {t(
            'You can add a live show to favorites by pressing the heart icon.',
          )}
        </p>
      </div>

      <Button name="See the list of live shows" onClick={handleClick} />
    </>
  );
};
