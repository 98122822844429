import React, { useEffect, useState } from 'react';
import { share } from '../../../assets';
import { ShareDrawer } from '../ShareDrawer';
import { Icon } from '@iconify/react';
import { useTranslation } from 'next-i18next';

type TitleWithIconProps = {
  title?: string;
  icon?: React.ReactNode;
  onClickIcon?: () => void;
  onClickButton?: () => void;
  className?: string;
  suffixIcon?: '' | 'share' | 'button';
  id?: string | string[];
  loading?: boolean;
  bgColor?: string;
  titleFont?: string;
  disabled?: boolean;
};

export const TitleWithIcon: React.FC<TitleWithIconProps> = ({
  disabled,
  title,
  icon,
  onClickIcon,
  onClickButton,
  className,
  loading,
  id,
  suffixIcon = '',
  bgColor,
  titleFont,
}) => {
  const [shareOpen, setShareOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    let mobileDevice =
      /iPad|iPhone|iPod|Android/.test(navigator.userAgent) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (mobileDevice) {
      setShareOpen(false);
    }
  });

  return (
    <div
      className={`flex h-12 items-center justify-between ${className} ${
        bgColor ? bgColor : 'bg-gray-3'
      } sticky top-0 z-50 `}
    >
      <div className={'flex'}>
        {icon && (
          <span
            className="pr-2 cursor-pointer text-gray-6"
            onClick={onClickIcon}
          >
            <>{icon}</>
          </span>
        )}
        <h4
          className={`${
            titleFont ? titleFont : 'semibold16-24'
          } capitalize text-[#313133]`}
        >
          {t(title!)}
        </h4>
      </div>

      {suffixIcon == 'button' ? (
        <div
          className="flex items-center gap-2 cursor-pointer text-gray-7 semibold16-24"
          onClick={onClickButton}
        >
          {loading && (
            <Icon icon="line-md:loading-twotone-loop" width={18} height={18} />
          )}
          {disabled ? (
            <p className="cursor-not-allowed">{t('Save')}</p>
          ) : (
            <p>{t('Save')}</p>
          )}
        </div>
      ) : suffixIcon == `${t('share')}` ? (
        <div
          className="w-6 h-6 cursor-pointer text-primary"
          onClick={async () => {
            try {
              await navigator.share({
                url: process.env.NEXT_PUBLIC_FRONTEND_URL + `/events/${id}`,
                title: 'Share MELD Event',
              });
            } catch (error) {
              setShareOpen(true);
            }
          }}
        >
          {share}
        </div>
      ) : null}
      {shareOpen ? (
        <ShareDrawer isOpen={shareOpen} onClose={() => setShareOpen(false)} />
      ) : null}
    </div>
  );
};
