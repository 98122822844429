import { getIndividualSessionItems } from 'helpers';
import Router from 'next/router';
import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';

export const NotSubscribedRoute = (AuthComponent: any) => {
  function NotSubscribedComponent({ children }: React.PropsWithChildren<{}>) {
    const {
      userProfile,
      loading,
      authenticated,
      user,
      action: userAction,
    } = useAuth();

    const returnPath = getIndividualSessionItems('returnPath');

    useEffect(() => {
      if (!authenticated && !loading) {
        Router.push('/login');
        return;
      }
      if (!loading) {
        if (authenticated && user && userProfile !== undefined) {
          const provider = user?.providerData[0]?.providerId;
          if (!user.emailVerified && provider === 'password') {
            Router.push('/verify-email');
          } else if (
            userProfile?.subscription != null &&
            userProfile?.subscription != undefined
          ) {
            if (returnPath) {
              Router.push(`${returnPath}`);
              return;
            } else {
              Router.push('/usage');
            }
          }
        }
      }
    }, [loading, authenticated, userProfile, user, returnPath]);

    return <> {children} </>;
  }

  return class Higher extends React.Component {
    render() {
      return (
        <NotSubscribedComponent>
          <AuthComponent {...this.props} />
        </NotSubscribedComponent>
      );
    }
  };
};
