import React from 'react';
import { Logo } from '../../atoms';
import Link from 'next/link';
import { roundToNearestMinutes } from 'date-fns';
import { useRouter } from 'next/router';

type AuthLayoutProps = {
  children: React.ReactNode;
};

export const AccountLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const router = useRouter();
  return (
    <div className="mx-auto mt-12 h-screen w-full max-w-[600px] py-4">
      <div className="flex items-center justify-center ">
        <Link
          href={router.pathname !== '/verify-email' ? '/' : '/verify-email'}
          legacyBehavior>
          <Logo />
        </Link>
      </div>
      <div className="flex items-center justify-center w-full mt-12">
        <div className="w-full">
          <>{children}</>
        </div>
      </div>
    </div>
  );
};
