import * as React from 'react';
import { Select as ChakraSelect } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { useTranslation } from 'next-i18next';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { IProfileInputs } from 'models';
interface Props<T extends FieldValues, K extends Path<T>> {
  Options?: Array<{ value: string; label: string }>;
  label?: string;
  field?: ControllerRenderProps<any, any>;
  error?: string;
  defaultValue?: string;
  name?: string;
  placeholder?: string;
  className?: string;
}
interface OptionsType {
  value?: string | number;
  label?: string;
  defaultValue?: string;
}
export const Select = <T extends FieldValues, K extends Path<T>>({
  Options,
  label,
  field,
  error,
  defaultValue,
  name,
  placeholder,
  className,
}: Props<T, K>) => {
  const { t } = useTranslation();
  return (
    <div className={className ? `${className}` : ''}>
      <div className="pb-2">
        {label && (
          <label
            htmlFor=""
            className="font-normal regular14-22 text-gray-9 text-start"
          >
            {t(label)}
          </label>
        )}
      </div>
      <ChakraSelect
        name={name}
        bg={'white'}
        // placeholder={t(placeholder!) || t('Select option')}
        _placeholder={{ opacity: 1, color: 'red.5' }}
        className={`regular16-24 ${
          error ? 'border-red-6' : 'border-gray-5'
        } !h-12 w-full border-[1px] !p-3`}
        color="gray.7"
        variant={'unstyled'}
        borderRadius={'8px'}
        defaultValue={defaultValue}
        icon={<Icon icon="entypo:triangle-down" />}
        {...field}
      >
        <option value={defaultValue} disabled className="left-0 text-gray-7">
          {t(placeholder || '') || t('Select option')}
        </option>
        {Options?.map((item: OptionsType) => (
          <option value={item.value} key={item.value}>
            {t(item?.label || '')}
          </option>
        ))}
      </ChakraSelect>
      {error == '' ? null : (
        <p className=" text-red-6">{t(error || '', { name: t(name!) })}</p>
      )}
    </div>
  );
};
