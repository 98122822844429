export const checkFileExtension = (file: File): boolean => {
  const supportedTypes: string[] = [
    'jpeg',
    'png',
    'jpg',
    'svg',
    'heic',
    'heif',
  ];
  const fileExtension = file.name.split('.').pop()?.toLocaleLowerCase();
  return !!fileExtension && supportedTypes.includes(fileExtension);
};

export const checkFileSize = (file: File): boolean => {
  const maxFileSize = 1024 * 1024 * 5; //5MB
  return file.size <= maxFileSize;
};

export const isEmptyObject = (obj: object): boolean => {
  return Object.keys(obj).length === 0;
};

export const removeEmpty = (
  object?: Record<
    string,
    string | string[] | null | number | boolean | undefined
  >,
): Record<string, string | string[] | number | boolean> => {
  let retObj: Record<string, string | string[] | boolean | number> = {};
  for (const k in object) {
    const data = object[k];
    if (data) {
      retObj[k] = data;
    }
  }
  return retObj;
};
