import { Icon } from '@iconify/react';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { InputField } from '../Input';

type SearchInputProps = {
  name?: string;
  value?: string;
  onChange?: (event: React.FocusEvent<HTMLInputElement>) => void;
};

export const SearchBar: React.FC<SearchInputProps> = ({
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <InputField
      iconLeft={
        <Icon icon="ant-design:search-outlined" width="22" height="22" />
      }
      size="md"
      // placeholder={t('Search')}
      placeholderColor="gray.7"
      iconLeftStyle={{
        color: 'gray.7',
        marginTop: '11px',
        boxSize: 5,
        marginStart: '12px',
      }}
      value={value}
      name={name}
      onChange={onChange}
    />
  );
};
