import { Icon } from '@iconify/react';
import React from 'react';

export const flagIcon = <Icon icon="ci:flag-fill" width="20" height="20" />;
export const messageIcon = (
  <Icon icon="mdi:message-reply" width="16" height="16" />
);
export const editIcon = <Icon icon="eva:edit-2-fill" width="16" height="16" />;
export const mailIconSmall = (
  <Icon icon="ant-design:mail-outlined" width={18} height={18} />
);
export const facebookIcon = (
  <Icon
    icon="ant-design:facebook-filled"
    color="white"
    width="24"
    height="24"
  />
);
export const twitterIcon = (
  <Icon
    icon="ant-design:twitter-outlined"
    color="white"
    width="24"
    height="24"
  />
);
export const mailIcon = (
  <Icon icon="ant-design:mail-outlined" color="white" width="24" height="24" />
);
export const hamburgerIcon = (
  <Icon icon="ci:hamburger" width="24" height="24" />
);
export const searchIcon = (
  <Icon icon="ant-design:search-outlined" width="24" height="24" />
);
export const backArrowIcon = (
  <Icon icon="eva:arrow-back-fill" width="24" height="24" />
);
export const envelope = (
  <Icon
    icon="ant-design:mail-outlined"
    color="#262626"
    width="18"
    height="18"
  />
);
export const lock = (
  <Icon
    icon="ant-design:lock-outlined"
    color="#262626"
    width="18"
    height="18"
  />
);
export const eyeInvisible = (
  <Icon
    icon="ant-design:eye-invisible-outlined"
    color="#8c8c8c"
    width="18"
    height="18"
  />
);
export const eyeVisible = (
  <Icon icon="ant-design:eye-outlined" color="#8c8c8c" width="18" height="18" />
);
export const arrowLeft = (
  <Icon icon="ant-design:arrow-left-outlined" width="24" height="24" />
);
export const share = <Icon icon="ci:share-outline" width="24" height="24" />;
export const leftIcon = (
  <Icon icon="ant-design:left-outlined" width="14" height="14" />
);
export const rightIcon = (
  <Icon icon="ant-design:right-outlined" width="14" height="14" />
);
export const calendarIcon = (
  <Icon
    icon="ant-design:calendar-outlined"
    className="text-gray-6"
    width="24"
    height="24"
  />
);

export const plusIcon = (
  <Icon
    icon="ant-design:plus-outlined"
    className="text-white"
    width="24"
    height="24"
  />
);

export const deleteIcon = (
  <Icon
    icon="ant-design:delete-outlined"
    className="text-red-4 hover:text-red-6 hover:shadow-md"
    width="24"
    height="24"
  />
);
export const EditIcon = (
  <Icon
    icon="ant-design:edit-outlined"
    className="text-gray-8 hover:text-gray-10 hover:shadow-md"
    width="24"
    height="24"
  />
);
export const ImageIcon = (
  <Icon icon="clarity:image-line" color="gray" width={18} height={18} />
);

export const fileIcon = <Icon icon="akar-icons:file" width={28} height={28} />;

export const arrowDown = (
  <Icon icon="ant-design:arrow-down-outlined" width="24" height="24" />
);

export const downloadIcon = (
  <Icon
    icon="ant-design:download-outlined"
    color="#8C8C8C"
    width="24"
    height="24"
  />
);

export const rightArrowIcon = (
  <Icon
    icon="material-symbols:arrow-right-alt-rounded"
    color="#d9d9d9"
    width="24"
    height="24"
  />
);
export const tildeIcon = (
  <Icon icon="mdi:tilde" color="#d9d9d9" width="24" height="24" />
);
export const dateIcon = (
  <Icon
    icon="ant-design:calendar-outlined"
    className="text-gray-6"
    width="24"
    height="24"
    color="#8c8c8c"
  />
);
export const cheveronDownIcon = (
  <Icon icon="zondicons:cheveron-down" color="#8c8c8c" width="18" height="18" />
);

export const closeIcon = (
  <Icon icon="ci:close-big" color="#8C8C8C" width="22" height="22" />
);
export const searchIconGray = (
  <Icon icon="ant-design:plus-outlined" width="24" height="24" />
);
export const cancelIcon = <Icon icon="iconoir:cancel" width="34" height="34" />;
export const favoriteOutlineIcon = (
  <Icon icon="material-symbols:favorite-outline" color="#262626" />
);
export const favoriteFillIcon = (
  <Icon icon="material-symbols:favorite" color="#262626" />
);
