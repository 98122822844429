export const ErrorMessages: { [key: string]: string } = {
  'auth/invalid-email': 'Please provide a valid email',
  'auth/email-already-in-use': 'Email Address is already registered.',
  'auth/user-not-found': 'The account doesn’t exists',
  'auth/wrong-password': 'The password provided is not correct',
  'auth/weak-password': 'The provided password is not secure',
  'auth/network-request-failed': 'Network error',
  'auth/too-many-requests':
    '試行回数が多すぎます。 あとでもう一度試してみてください。',
  TOO_MANY_ATTEMPTS_TRY_LATER:
    '試行回数が多すぎます。 あとでもう一度試してみてください。',
};
