import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { auth } from './firebase';

/**
 * axios instance
 */

axios.defaults.responseType = 'json';

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASEURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':
      'api.meld.co.jp, api-dev-dot-meld-dev.an.r.appspot.com, localhost',
  },
});

// Step-2: Create request, response & error handlers
const requestHandler = async (request: AxiosRequestConfig<any>) => {
  const token = await auth.currentUser?.getIdToken(false);
  if (token) {
    request.headers && (request.headers['Authorization'] = 'Bearer ' + token);
  }
  return request;
};

const responseHandler = (response:AxiosResponse<any, any>) => {
  if (response.status === 401) {
    window.location.href = '/login';
  }
  return response?.data;
};

const errorHandler = (error: string) => {
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
API.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error),
);

API.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error),
);

export { API };
