import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from '@chakra-ui/react';
import { IUserParticipation } from 'models';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { searchAdminParticipation } from 'services';
import { toastError } from 'toast-schema';
import { NoResultsFound, PageLoader, TableHeader } from 'ui';
import { PaginationComponent } from '../Pagination';
import { PER_PAGE } from 'constant';
import { useTranslation } from 'next-i18next';
import { IPagination, PaginatedResponse } from 'services/pagination';
import { useRouter } from 'next/router';
import { formatISO9075 } from 'date-fns';
import Link from 'next/link';

interface Props {
  authenticated: boolean;
}

export const TableParticipation: React.FC<Props> = ({ authenticated }) => {
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState<number>();
  const [pagination, setPagination] = useState<IPagination>();
  const [pageCount, setPageCount] = useState<number>(1);
  const { t } = useTranslation();
  const router = useRouter();
  const {
    data,
    isLoading,
  }: {
    data?: PaginatedResponse<IUserParticipation[]>;
    isLoading: boolean;
  } = useQuery(
    ['search-users', currentPage],
    () => searchAdminParticipation({ pageParam: currentPage }),
    {
      retry: false,
      enabled: authenticated,
      onError: () => {
        if (!toast.isActive('fetch')) {
          toastError({
            toastId: 'fetch',
            toast,
            description: t('Error occurred'),
          });
        }
      },
    },
  );

  useEffect(() => {
    setPagination(data?.pagination);
    setPageCount(Math.ceil(pagination?.count! / PER_PAGE));
  }, [data, pagination?.count]);

  useEffect(() => {
    setCurrentPage(parseInt(router.query.page as string));
  }, [router.query]);

  const noInfo = data?.data?.length === 0;

  return (
    <>
      {isLoading && <PageLoader />}
      <TableContainer>
        <Table variant="simple">
          <TableHeader
            titleList={['Username', 'Event Name', 'Status', 'Change Time']}
          />
          <Tbody>
            {noInfo ? (
              <Tr className="">
                <Td colSpan={7}>
                  <div className="mx-auto flex items-center justify-center">
                    <NoResultsFound
                      text={t('No participation information available!')}
                    />
                  </div>
                </Td>
              </Tr>
            ) : (
              <>
                {data?.data?.map((data: IUserParticipation) => (
                  <React.Fragment key={data.id}>
                    <Tr>
                      <Td
                        className={'text-blue-7 !py-5'}
                        maxWidth={'250px'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                      >
                        <Link href={`/users/${data?.User?.id}`}>
                          {data?.User?.username || '-'}
                        </Link>
                      </Td>
                      <Td
                        className={'!py-5'}
                        maxWidth={'250px'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                      >
                        {data?.Event?.name || '-'}
                      </Td>
                      <Td className={'!py-5'}>{t(data?.status) || '-'}</Td>
                      <Td className={'!py-5'}>
                        {data?.updated_at != null
                          ? formatISO9075(new Date(data.updated_at), {
                              representation: 'complete',
                            }).slice(0, -3)
                          : '-'}
                      </Td>
                    </Tr>
                  </React.Fragment>
                ))}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {pagination?.count &&
        data?.data.length !== 0 &&
        pagination?.count > PER_PAGE && (
          <PaginationComponent
            pageCount={pageCount}
            currentPage={currentPage}
          />
        )}
    </>
  );
};
