import { API, auth } from 'config';
import {
  AuthProvider,
  FacebookAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  unlink,
} from 'firebase/auth';
import { IToast, toastError, toastSuccess } from 'toast-schema';
import { useMutation } from 'react-query';
import { ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL } from 'constant';
import { TFunction, useTranslation } from 'next-i18next';
import { removeEmpty } from 'helpers';
interface IUserData {
  email: string | null;
  profile_picture_url: string | null;
  firebase_id: string;
  username: string | null;
}
export const createUsers = async (userData: IUserData) => {
  return await API.post(
    '/user',
    removeEmpty({
      email: userData.email,
      profile_picture_url: userData.profile_picture_url,
      firebase_id: userData.firebase_id,
      username: userData.username || '',
    }),
  );
};

export const handleSnsSignIn = async (
  snsName: string,
  toast: IToast,
  t: TFunction,
) => {
  let provider: AuthProvider;
  switch (snsName) {
    case 'facebook':
      provider = new FacebookAuthProvider();
      break;
    case 'twitter':
      provider = new TwitterAuthProvider();
      break;
    default:
      throw new Error('Unsupported SNS: ' + snsName);
  }

  try {
    const response = await signInWithPopup(auth, provider);
    if (response?.user?.email) {
      const providers = await fetchSignInMethodsForEmail(
        auth,
        response?.user?.email,
      );
      if (providers?.length > 1) {
        unlink(response.user, 'facebook.com');
        await signOut(auth);
        toastError({
          toastId: 'login',
          toast,
          description: t('This user is registered from different provider'),
        });
      }
    }
    return response;
  } catch (error: any) {
    if (error.message === ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIAL) {
      toastError({
        toastId: 'login',
        toast,
        description: t('Email Address is already registered.'),
      });
      return;
    }
    return;
  }
};

export const useCreateUser = (toast: IToast, sns: boolean) => {
  const { t } = useTranslation();

  return useMutation(createUsers, {
    onSuccess: () => {
      if (sns) {
        toastSuccess({
          toastId: 'signup',
          toast,
          description: t('You are successfully registered.'),
        });
        return;
      } else {
        toastSuccess({
          toastId: 'signup',
          toast,
          description: t('Please verify your email before you proceed.'),
        });
        return;
      }
    },
    onError: () => {
      toastError({
        toastId: 'signup',
        toast,
        description: t('There was an issue while creating your account.'),
      });
    },
  });
};
