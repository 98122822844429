export const convertAgeFromId = (id: string, t: (args: string) => void) => {
  switch (id) {
    case '1':
      return `10 ${t('years')}`;
    case '2':
      return `20 ${t('years')}`;
    case '3':
      return `30 ${t('years')}`;
    case '4':
      return `40 ${t('years')}`;
    case '5':
      return `50 ${t('years')}`;
    case '6':
      return `60 ${t('years and above')}`;
  }
};
