export const BeforeLoginDrawerItems = [
  {
    icon: 'ant-design:info-circle-outlined',
    name: 'About MELD',
    path: '/about-meld',
  },
  {
    icon: 'clarity:star-line',
    name: 'live_events',
    path: '/',
  },
  {
    icon: 'ant-design:user-add-outlined',
    name: 'Signup',
    path: '/signup',
  },
  {
    icon: 'ant-design:login-outlined',
    name: 'Login',
    path: '/login',
  },
  {
    icon: 'mdi:cards-heart-outline',
    name: 'My Favorite',
    path: '/',
  },
  {
    icon: 'material-symbols:payments-outline',
    name: 'Fee Structure',
    path: '/fee-structure',
  },
  {
    icon: 'mdi:shield-alert-outline',
    name: 'Precautions',
    path: '/precautions',
  },
  {
    icon: 'fluent:chat-bubbles-question-24-regular',
    name: 'FAQ',
    path: '/faq',
  },
  {
    icon: 'ant-design:contacts-outlined',
    name: 'Contact Us',
    path: '/inquiry',
  },
  {
    icon: 'material-symbols:ballot-outline',
    name: 'Event listing request',
    path: '/events/request',
  },
  {
    icon: 'ant-design:file-protect-outlined',
    name: 'Terms of service',
    path: '/terms-of-service',
  },
  {
    icon: 'ant-design:safety-certificate-outlined',
    name: 'Privacy policy',
    path: '/privacy-policy',
  },
  {
    icon: 'ant-design:safety-certificate-outlined',
    name: 'Commercial Law',
    path: '/terms-of-use',
  },
];
