import React from 'react';
import { As, Button as ChakraButton, Flex, Text } from '@chakra-ui/react';

type SmallButtonType = {
  title?: string;
  backgroundColor?: string;
  paddingY?: string;
  paddingX?: string;
  height?: string;
  borderRadius?: string;
  minWidth?: string;
  textColor?: string;
  icon?: React.ReactNode;
  fontSize?: string;
  onClick?: () => void;
  className?: string;
  align?: string;
  isLoading?: boolean;
  as?: As<any>
};

export const SmallButton: React.FC<SmallButtonType> = ({
  title,
  backgroundColor,
  paddingY,
  paddingX,
  height,
  borderRadius,
  minWidth,
  textColor,
  icon,
  fontSize,
  align,
  isLoading = false,
  onClick,
  as,
}) => {
  return (
    <>
      <ChakraButton
        as={as}
        onClick={onClick}
        backgroundColor={backgroundColor || 'gray.3'}
        py={paddingY || '12px'}
        px={paddingX || '8px'}
        height={height || '20px'}
        borderRadius={borderRadius || '2px'}
        minW={minWidth || '36px'}
        isLoading={isLoading}
      >
        <Flex color={textColor || 'blue.6'} align={align || 'center'}>
          <>
            {icon && icon}
            {title && (
              <Text fontSize={fontSize || '12px'} cursor={'pointer'} ml={'8px'}>
                {title}
              </Text>
            )}
          </>
        </Flex>
      </ChakraButton>
    </>
  );
};
