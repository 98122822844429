import React, { MouseEventHandler } from 'react';
import { AdminHeader } from '../AdminHeader';
import { AdminSideBar } from '../AdminSidebar';

type AdminLayoutProps = {
  children: React.ReactNode;
  title?: string;
  authenticated?: boolean | null;
  logOut: () => Promise<void>;
};

export const AdminLayout: React.FC<AdminLayoutProps> = ({
  children,
  title,
  authenticated,
  logOut,
}) => {
  return (
    <div className="flex w-full h-screen gap-3">
      <div className="relative w-[20%]">
        <div className="h-[100vh] w-[20%] !bg-[#001529]">
          <AdminSideBar authenticated={authenticated} logOut={logOut} />
        </div>
      </div>
      <div className="  m-auto flex h-full w-[77%] flex-col bg-white">
        <div className="sticky top-0 z-10 h-[40px] w-full">
          <AdminHeader title={title!} />
        </div>
        <div className="flex flex-col px-6 mt-3 bg-white">{children}</div>
      </div>
    </div>
  );
};
