import {
  Box,
  BoxProps,
  CloseButton,
  Flex,
  Link,
  List,
  ListItem,
} from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import NextLink from 'next/link';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Logo } from '../../atoms';
import { NavBarItems } from '../NavDrawer';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { User } from 'firebase/auth';
import { useQueryClient } from 'react-query';

interface SidebarContentProps extends BoxProps {
  drawerItems: NavBarItems[];
  onClose: () => void;
  drawerTitle?: string;
  onLogout: () => void;
  authenticated: boolean;
  user: User | null;
  subscription?: boolean;
  setSelectedTabIndex: Dispatch<SetStateAction<number>>;
  setRedirectedFromNavDrawer: Dispatch<SetStateAction<boolean>>;
}

export const SidebarContent: React.FC<SidebarContentProps> = ({
  drawerItems,
  onClose,
  onLogout,
  authenticated,
  user,
  setSelectedTabIndex,
  subscription,
  setRedirectedFromNavDrawer,
  ...rest
}) => {
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const [provider, setProvider] = useState('');
  const lang = i18n.language?.startsWith('en') ? 'en' : 'ja';
  const queryClient = useQueryClient();

  const handleLogout = () => {
    onLogout();
    onClose();
    queryClient.clear();
    router.push('/');
  };

  const handleLinkClick = (url: string, title: string) => {
    if (url === '/') {
      if (title === 'My Favorite') {
        setSelectedTabIndex(2);
        localStorage.setItem('selectedTab', '2');
      } else {
        setSelectedTabIndex(0);
        localStorage.setItem('selectedTab', '0');
      }
    }
    setRedirectedFromNavDrawer(true);
    onClose();
  };

  useEffect(() => {
    if (authenticated && user !== null) {
      setProvider(user?.providerData[0]?.providerId);
    }
  }, [authenticated, user]);

  const payment_name = subscription
    ? 'Change Payment Method'
    : 'Payment Method';

  return (
    <Box
      bg={'primary'}
      pos="fixed"
      h="full"
      {...rest}
      paddingTop={'45px'}
      paddingStart={'38px'}
      paddingRight={'25px'}
      paddingBottom={authenticated ? '25px' : '78px'}
      mx={'auto'}
      overflow={'auto'}
      className="flex flex-col w-full"
    >
      <Flex h="10" alignItems="center" justifyContent="end">
        <CloseButton
          color={'white'}
          onClick={onClose}
          bg={'primary'}
          mt={'-42px'}
        />
      </Flex>
      <div className="justify-center mx-auto text-center">
        <span onClick={onClose} className="cursor-pointer">
          <Logo type="white" />
        </span>
      </div>
      <List spacing={6} bg={'primary'} marginTop={'45px'}>
        {drawerItems.map(item => (
          <ListItem key={item.name}>
            {item.path === '/payment-method' ? (
              <a
                href={`/${lang}/payment-method`}
                className="flex items-center gap-3"
              >
                <Icon
                  icon={item.icon}
                  width={'21px'}
                  height={'21px'}
                  color="white"
                />
                <span className="text-white semibold14-22">{t(item.name)}</span>
              </a>
            ) : item.path === '/logout' ? (
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={handleLogout}
              >
                <Icon
                  icon={item.icon}
                  width={'21px'}
                  height={'21px'}
                  color="white"
                />
                <span className="text-white semibold14-22">{t(item.name)}</span>
              </div>
            ) : (
              <>
                {(!['/change-email', '/change-password'].includes(item.path) ||
                  (['/change-email', '/change-password'].includes(item.path) &&
                    provider === 'password')) && (
                  <NextLink href={item.path} passHref legacyBehavior>
                    <Link
                      onClick={() => handleLinkClick(item.path, item.name)}
                      className="flex items-center gap-3"
                      _hover={{ bg: 'none' }}
                    >
                      <Icon
                        icon={item.icon}
                        width={'21px'}
                        height={'21px'}
                        color="white"
                      />
                      <span className="text-white semibold14-22">
                        {item.path == '/payment-method'
                          ? t(payment_name)
                          : t(item.name)}
                      </span>
                    </Link>
                  </NextLink>
                )}
              </>
            )}
          </ListItem>
        ))}
      </List>
      {!authenticated && (
        <span className="semibold14-22 mt-[140px] text-white">
          Copyright © MELD {new Date().getFullYear()}
        </span>
      )}
    </Box>
  );
};
