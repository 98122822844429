import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Input } from '@chakra-ui/react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

export type LeftIconProps = {
  marginStart?: string;
  marginTop?: string;
  boxSize?: number;
  color?: string;
};

export interface EventFileType {
  file: any;
  name: string;
}

type FormInputProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  onChange?: (file: File | null) => void;
  error?: string;
  field?: ControllerRenderProps<T, K>;
  marginBottom?: string;
  displayImage?: EventFileType;
  edit?: boolean;
  imageToShow?: string | null;
  accept?: string;
  onClear?: () => void;
};

export const ImageInputField = <T extends FieldValues, K extends Path<T>>({
  label,
  accept,
  onChange,
  error,
  field,
  marginBottom = 'mb-6',
  displayImage,
  edit,
  imageToShow,
  onClear,
}: FormInputProps<T, K>) => {
  const { t } = useTranslation('common');

  return (
    <div className={`${marginBottom} `}>
      <div className="pb-2">
        {label && (
          <label className="text-gray-9 text-start text-sm font-normal">
            {t(label)}
          </label>
        )}
      </div>

      <div className="w-full ">
        <div>
          {(edit && imageToShow) || displayImage?.file ? (
            <>
              <div className="bg-gray-8 relative h-[200px] w-[300px]">
                <Image
                  src={displayImage?.file || imageToShow}
                  alt={'event image'}
                  fill
                  className="object-contain brightness-50"
                />
              </div>
              <label>
                <div className="absolute mt-[-208px] h-[64px] w-[300px] items-end rounded-full p-4 text-right">
                  <Image
                    src="/assets/svg/close.svg"
                    height={20}
                    width={20}
                    alt={'close'}
                    className={'cursor-pointer'}
                    onClick={onClear}
                  />
                </div>
              </label>
              <label>
                <div className="absolute mt-[-64px] flex h-[64px] w-[64px] items-center justify-center rounded-full">
                  <Image
                    src="/assets/svg/photo_camera.svg"
                    height={20}
                    width={20}
                    alt={'upload image'}
                    className={'cursor-pointer w-auto h-auto'}
                  />
                </div>
                <input
                  accept={accept}
                  type="file"
                  id="fileupload"
                  className="opacity-0"
                  hidden
                  {...field}
                  onChange={event => {
                    onChange &&
                      onChange(
                        event.target.files && event.target.files[0]
                          ? event.target.files[0]
                          : null,
                      );
                  }}
                />
              </label>
            </>
          ) : (
            <div className="flex w-full items-start">
              <label className="border-black-200 flex h-[200px] w-[300px] cursor-pointer flex-col border-4 border-dashed hover:border-gray-300 hover:bg-gray-100">
                <div className="flex flex-auto flex-col items-center justify-center">
                  <span className="text-gray-7 text-7xl"> + </span>
                  <p className="text-gray-6 pt-1 text-sm tracking-wider group-hover:text-gray-600">
                    {t('Upload a file')}
                  </p>
                </div>
                <Input
                  type="file"
                  id="fileupload"
                  className="opacity-0"
                  {...field}
                  onChange={event =>
                    onChange &&
                    onChange(
                      event.target.files && event.target.files[0]
                        ? event.target.files[0]
                        : null,
                    )
                  }
                />
              </label>
            </div>
          )}
        </div>
      </div>

      {error == '' ? null : <div className="text-red-6">{t(error || '')}</div>}
    </div>
  );
};
