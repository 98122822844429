import React from 'react';

interface IFAQDataType {
  question?: string;
  answer?: string;
  id?: number;
}

const FaqData: IFAQDataType[] = [
  {
    id: 1,
    question: 'MELDとは何ですか？',
    answer:
      '音楽イベント専用の同行者マッチングサービスです。\n「音楽イベントが更に身近なものになり音楽と人と人を繋ぐ」をモットーに運営しています。\n 様々なジャンルのイベント情報に溢れ、音楽好きにとって居心地の良い場となるよう努めてまいります。',
  },
  {
    id: 2,
    question: '料金はどのようにかかりますか？',
    answer: `【無料会員様の場合】
    アカウントの作成、イベント情報の検索、イベントのお気に入り登録、イベントの参加登録ができます。
    
    【有料会員の場合】
    DM（ダイレクトメッセージ）の開始には月額480円（税抜）のサブスクリプションの会員になっていただく必要があります。最初の1ヶ月は50%OFFの240円（税抜）でご利用いただけます。
    詳しくは料金形態のページをご覧ください。
   <a href="/fee-structure" class="underline cursor-pointer text-[#4141e9e3]">https://meld.co.jp/fee-structure</a>`,
  },

  {
    id: 3,
    question: '出会い系サイトですか？',
    answer: `MELDは音楽イベントの同行者マッチングサービスであり、出会い系サイトではありません。なので過度な性的なメッセージや誘いなどは通報いただくと管理者が確認し、警告や退会などの処置を取らせていただきます。\n 詳しくは注意事項のページをご覧ください。\n<a href= "/precautions" style="text-decoration: underline; color: #4141e9e3">https://meld.co.jp/precautions</a>`,
  },
  {
    id: 4,
    question: '年齢確認はありますか？',
    answer:
      'MELDは未成年の方の利用を禁止させていただいております。\n クレジットカードの登録をもちまして年齢確認とさせていただいております。',
  },
  {
    id: 5,
    question: '登録は本名でなければなりませんか？',
    answer:
      '本名やご本人の顔写真の利用はご利用者様にお任せしておりますが、音楽の趣味や人となりが分かりやすい方がマッチングの成功率は高まる傾向にあります。',
  },
  {
    id: 6,
    question: '同性同士のマッチングも可能ですか？',
    answer:
      'MELDでは性別や年齢に関わらず様々な方とマッチングし一緒に音楽を楽しむことができます。',
  },
  {
    id: 7,
    question: 'マッチングできなかった場合返金はしてもらえますか？',
    answer:
      'MELDはマッチングの場の提供を行い、マッチングの確約はできません。\n マッチングができなかった場合でも返金はできかねます。',
  },
  {
    id: 8,
    question: 'マッチングした後、連絡先の交換はできますか？',
    answer: 'お互いの了承が得られた場合、連絡先の交換は禁止していません。',
  },
  {
    id: 9,
    question: 'チケットの転売をしている人がいた場合どうしたらいいですか？',
    answer:
      'MELDではチケットの転売行為を厳しく禁じています。\n そのようなユーザーを発見した場合はすぐに通報してください。\n 担当者が確認し、退会処分、永久にアカウントが作成できないように対処いたします。',
  },
  {
    id: 10,
    question:
      '行きたい音楽イベントが掲載されていない場合はどうしたらいいですか？',
    answer: `こちら <a href= "/events/request" style="text-decoration: underline; color: #4141e9e3">https://meld.co.jp/events/request</a>\n から新規追加希望のイベント情報をお受付しております。\n 担当者が確認し追加させていただきます。\n 音楽ジャンルや地域は問いませんのでお気軽にご連絡ください。`,
  },
  {
    id: 11,
    question: '退会したい場合はどうしたらいいですか？',
    answer: `こちらより退会のお手続きができます。
    <a href="/profile/delete" class="underline cursor-pointer text-[#4141e9e3]">https://meld.co.jp/profile/delete</a>
    
    【有料会員様の場合】
    退会のお手続きの前に、サブスクリプション停止のお手続きが必要となりますので、アナウンスに基づきサブスクリプションの停止を設定していただき、退会のお手続きを行なってください。`,
  },
];

export const FAQComponent: React.FC = () => {
  return (
    <>
      {FaqData.map((data: IFAQDataType) => {
        return (
          <React.Fragment key={data.id}>
            <p className="semibold16-24 text-gray-8">{data.question}</p>

            <div
              className="mb-4 whitespace-pre-line text-gray-8"
              dangerouslySetInnerHTML={{ __html: data.answer || '' }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
