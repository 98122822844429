import {
  chakra,
  Box,
  Flex,
  Stack,
  Text,
  useCheckbox,
  useCheckboxGroup,
  Spacer,
  UseCheckboxProps,
} from '@chakra-ui/react';
import { IOptions, IProfileInputs } from 'models';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

type MultipleSelectProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  className?: string;
  error?: string;
  field?: ControllerRenderProps<T, K>;
  name?: string;
  setSelectedValues?: Dispatch<SetStateAction<any>>;
  selectedValues: (string | number)[];
  userProfile?: IProfileInputs;
  defaultValue?: string[] | null;
  showCountOnly?: boolean;
  placeHolder?: string;
  placeHolderText?: string;
  checkboxColor?: string;
  genreOptions?: IOptions[];
};
// inherits from UseCheckboxProps
interface Props extends UseCheckboxProps {
  title?: string;
}
export const MultipleSelect = <T extends FieldValues, K extends Path<T>>({
  label,
  className,
  error,
  setSelectedValues,
  defaultValue,
  placeHolder,
  placeHolderText,
  showCountOnly,
  checkboxColor,
  genreOptions,
}: MultipleSelectProps<T, K>) => {
  const { value, getCheckboxProps } = useCheckboxGroup({
    defaultValue: defaultValue ?? [],
  });
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const refs = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValues && setSelectedValues(value);
  }, [value]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (refs.current && !refs['current'].contains(event.target as any)) {
        setShowOptions(false);
      }
    };
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  function CustomCheckbox(props: Props) {
    const { state, getCheckboxProps, getInputProps, getLabelProps, htmlProps } =
      useCheckbox(props);
    return (
      <chakra.label
        display="flex"
        flexDirection="row"
        alignItems="center"
        gridColumnGap={2}
        px={3}
        py={1}
        cursor="pointer"
        {...htmlProps}
      >
        <input {...getInputProps()} hidden />
        <div className="font-semibold">
          <Text
            {...getLabelProps()}
            _checked={{ color: 'gray.10' }}
            fontSize="14px"
            className={`${className}`}
          >
            {t(props.title || '')}
          </Text>
        </div>
        <Spacer />

        <Flex
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor={checkboxColor ? checkboxColor : 'gray.5'}
          borderRadius={'2px'}
          w={4}
          h={4}
          {...getCheckboxProps()}
          _checked={{
            border: '1px solid',
            borderColor: 'blue.6',
          }}
        >
          {state.isChecked && (
            <Box w={2} h={2} bg={checkboxColor ? checkboxColor : 'blue.6'} />
          )}
        </Flex>
      </chakra.label>
    );
  }
  return (
    <>
      {label && (
        <div className="pb-2">
          <label
            htmlFor=""
            className="text-sm font-normal text-gray-9 text-start"
          >
            {t(label)}
          </label>
        </div>
      )}
      <div ref={refs}>
        <Stack spacing={1}>
          <div className="w-full" onClick={() => setShowOptions(true)}>
            <ul
              className={`${
                error ? 'border-red-6' : 'border-gray-5'
              } bg-gray-1 flex min-h-[48px] flex-wrap items-center justify-start overflow-hidden rounded-lg border p-3`}
            >
              {placeHolderText &&
                (value.length == 0 ||
                  (value.length == 1 && value[0] == '')) && (
                  <span className="left-0 text-gray-7">
                    {t(placeHolderText || '')}
                  </span>
                )}
              {placeHolder && (
                <p className="text-gray-8">
                  {placeHolder}{' '}
                  <span className="left-0 text-gray-6">
                    {value.length > 0 && `(${value.length})`}
                  </span>
                </p>
              )}
              {!showCountOnly && (
                <>
                  {value.map((item, index) => {
                    return (
                      <>
                        {item != '' && (
                          <li
                            key={index}
                            className="flex items-center justify-between h-6 px-2 mx-1 cursor-pointer text-blue-6 hover:text-blue-7 bg-gray-3 regular14-22"
                          >
                            {t(item as string)}
                          </li>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </ul>
          </div>

          {showOptions && (
            <Box
              border="1px"
              borderColor="gray.5"
              color="gray.7"
              borderRadius="2px"
              maxH={'216px'}
              position={`${showCountOnly ? 'absolute' : 'relative'}`}
              zIndex={50}
              marginTop={`${showCountOnly ? '52px !important' : ''}`}
              backgroundColor={'gray.3'}
              width={showCountOnly ? '258px' : ''}
            >
              <h4 className="mt-2 mb-2 scrollbar">
                {genreOptions?.map((genre, index) => (
                  <CustomCheckbox
                    key={index}
                    {...getCheckboxProps({
                      value: genre.value,
                      title: genre.label,
                    })}
                  />
                ))}
              </h4>
            </Box>
          )}
        </Stack>
        {error == '' ? null : <p className="text-red-6">{t(error || '')}</p>}
      </div>
    </>
  );
};
