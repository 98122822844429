import React from 'react';
import { useTranslation } from 'react-i18next';
import { IEvents } from 'models';

interface IEventDetailProps {
  ev: IEvents;
}

export const EventDetailsSection: React.FC<IEventDetailProps> = ({ ev }) => {
  const { t, i18n } = useTranslation();

  const getMonth = (month: number) => {
    const formatter = new Intl.DateTimeFormat('en', { month: 'long' });
    return i18n.language != 'ja'
      ? formatter.format(new Date(2003, month, 12))
      : month + 1;
  };

  const date = new Date(ev.date);
  return (
    <>
      <p className="regular14-22 text-gray-10">{t('Event details:')}</p>
      <div className="p-[8px]">
        <p className="text-gray-8 text-[16px]">{t('Date and Time:')}</p>
        <p className="text-gray-10 text-[16px]">
          {i18n.language == 'ja' ? (
            t('date_format', {
              year: date.getFullYear(),
              month: getMonth(date.getMonth()),
              day: date.getDate(),
            })
          ) : (
            <>
              {date.getFullYear()}/{date.getMonth() + 1}/{date.getDate()} (
              {date.toLocaleString('en', { weekday: 'short' }).toUpperCase()})
            </>
          )}
        </p>
        {ev.show_time && (
          <div className="flex text-[16px]">
            <p className="text-gray-8">
              {t(`${ev?.event_type === 'day' ? 'Day Show:' : 'Night Show:'}`)}
            </p>
            &nbsp;
            <p className="text-gray-10 text-[16px]">
              {new Date(ev.show_time.open_time!).getHours()}:
              {String(new Date(ev?.show_time.open_time!).getMinutes()).padStart(
                2,
                '0',
              )}{' '}
              {t('Opening')}
              <span> / </span>
              {new Date(ev.show_time.start_time!).getHours()}:
              {String(new Date(ev.show_time.start_time!).getMinutes()).padStart(
                2,
                '0',
              )}{' '}
              {t('Start')}
            </p>
          </div>
        )}

        <div className="text-gray-10 text-[16px]">
          <span className="text-2xl">※</span>
          <span>{t('Show times are subject to change.')}</span>
        </div>

        <hr className="bg-gray-6 my-[12px]" />
        {ev?.event_details && ev?.event_details != 'undefined' && (
          <div
            className="htmlEditor"
            dangerouslySetInnerHTML={{
              __html: ev?.event_details,
            }}
          />
        )}
      </div>
    </>
  );
};
