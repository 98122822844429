import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { Button } from '../Button';
import { useCancelPayment } from 'services';
import { useToast } from '@chakra-ui/react';
import { formatISO9075 } from 'date-fns';
import { User } from 'firebase/auth';
import { IProfileInputs } from 'models';
import { useRouter } from 'next/router';

type PaymentCancelSubscriptionProps = {
  onClose?: () => void;
  date?: string;
  user?: User;
  setUserProfile?: React.Dispatch<
    React.SetStateAction<IProfileInputs | undefined>
  >;
};

export const PaymentCancelSubscription: React.FC<
  PaymentCancelSubscriptionProps
> = ({ onClose, date }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const router = useRouter();

  const { mutateAsync: CancelPaymentMutation, isLoading } = useCancelPayment(
    toast,
    t,
  );
  const handleCancelPayment = async () => {
    await CancelPaymentMutation();
    onClose && onClose();
    if (localStorage.getItem('delete_account')) {
      router.push('/profile');
    }
  };

  return (
    <>
      <p className="regular16-24 text-gray-8 whitespace-pre-line ">
        {t(
          'If you cancel your subscription, you will not be able to attend live shows or interact with other members after the following dates.',
        )}
      </p>
      {date && (
        <p className="py-6">
          {t('Expiration date:')}
          <strong>
            {formatISO9075(new Date(date), {
              representation: 'date',
            }).replaceAll('-', '/')}
          </strong>
        </p>
      )}
      <p>{t('Are you sure you want to cancel your subscription?')}</p>
      <div className="mt-6">
        <Button
          buttonType="error"
          name={t('Confirm')}
          onClick={handleCancelPayment}
          marginBottom={'mb-2'}
          loading={isLoading}
        />
        <Button
          buttonType="tertiary"
          name={t('Cancel')}
          onClick={onClose}
          marginBottom={'mb-3'}
        />
      </div>
    </>
  );
};
