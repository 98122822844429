import axios, { CancelTokenSource } from 'axios';
import { API } from 'config';
import { removeEmpty } from 'helpers';
import { PaginatedResponse } from './pagination';
export interface InquirySubmit {
  name: string;
  email: string;
  message: string;
}
interface ISearch {
  searchQuery?: string;
  pageParam?: number;
}
interface IResponse {
  id: string;
  name: string;
  email: string;
  message: string;
  created_at: string;
  updated_at: string;
}
export const inquirySubmit = async (data: InquirySubmit) => {
  return await API.post('/inquiry', data);
};

let ct: CancelTokenSource;
//Search  Admin inquiry
export const searchAdminDashboardInquiry = async ({
  searchQuery,
  pageParam,
}: ISearch) => {
  if (typeof ct != typeof undefined) {
    ct.cancel('Operation canceled due to new request.');
  }
  ct = axios.CancelToken.source();
  try {
    const response = (await API.get('/inquiry', {
      params: removeEmpty({
        query: searchQuery,
        page: pageParam,
        per_page: 10,
      }),
      cancelToken: ct.token, //2nd step
    })) as PaginatedResponse<IResponse[]>;
    return response;
  } catch (error) {}
};
