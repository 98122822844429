import * as Yup from 'yup';

export const profileSchema = Yup.object({
  nick_name: Yup.string()
    .required('Please enter')
    .max(14, 'Please enter nickname in 14 characters or less'),
  username: Yup.string()
    .max(50, 'Please enter username in 50 characters or less')
    .matches(
      /^[A-Za-z0-9_]+$/,
      `Please enter your ID using a combination of alphanumeric characters and '_' (underscore).`,
    )
    .required('Please enter your user ID'),
  // .max(14, 'Maximum 14 characters.'),
  notification_email: Yup.string()
    .email("Please enter a valid email.")
    .optional()
    .max(100, 'Please enter notification email in 100 characters or less'),
  bio: Yup.string()
    .required('Please enter')
    .max(500, 'Please enter bio in 500 characters or less'),
  // .min(8, 'Minimum 8 characters required'),
  genre: Yup.array(Yup.string()),
  gender: Yup.string().required('Please select gender'),
  age: Yup.string(),
  location: Yup.string(),
});
