import React, { Dispatch, SetStateAction, useState } from 'react';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'next-i18next';
import { Button } from 'ui';
import { ICouponData } from 'models';
import { useRouter } from 'next/router';
import { UseMutateAsyncFunction, useQueryClient } from 'react-query';
import {
  UseFormHandleSubmit,
  UseFormReturn,
  UseFormSetError,
  UseFormWatch,
} from 'react-hook-form';
import { AxiosResponse } from 'axios';
interface IPayload {
  price_id: string;
  coupon_code: string;
}
type SubscriptionFormProps = {
  RegisterPaymentMutation?: UseMutateAsyncFunction<
    AxiosResponse<any, any>,
    any,
    IPayload,
    unknown
  >;
  isLoading?: boolean;
  handleSubmit?: UseFormHandleSubmit<ICouponData>;
  watch?: UseFormWatch<ICouponData>;
  setError?: UseFormSetError<ICouponData>;
};
export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  RegisterPaymentMutation,
  isLoading,
  handleSubmit,
  watch,
  setError,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const router = useRouter();
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  //stripe payment form submit
  const handlePaymentFormSubmit = async () => {
    setIsStripeLoading(true);
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      setIsStripeLoading(false);
      queryClient.refetchQueries('get-setup-intent');
    } else {
      queryClient.refetchQueries('get-setup-intent');
      setIsStripeLoading(false);
      return 'success';
    }
  };

  const onSubmit = async (data: ICouponData) => {
    if (watch && watch('price_id') == undefined) {
      setError && setError('price_id', { message: t('Please select a plan') });
      return;
    }

    const payload = {
      price_id: data.price_id,
      coupon_code: data?.coupon_code,
    };
    const response = await handlePaymentFormSubmit();
    if (response === 'success') {
      await (RegisterPaymentMutation && RegisterPaymentMutation(payload));
      if (localStorage.getItem('returnPath')) {
        router.push(localStorage.getItem('returnPath') || '/');
      } else if (localStorage.getItem('signup') === 'true') {
        router.push('/usage');
      } else {
        router.push('/');
      }
    }
  };
  return (
    <>
      <PaymentElement
        options={{ wallets: { applePay: 'never', googlePay: 'never' } }}
      />
      <div className="mt-6">
        <Button
          name="Register"
          type="submit"
          loading={isStripeLoading || isLoading}
          onClick={handleSubmit && handleSubmit(onSubmit)}
        />
      </div>
    </>
  );
};
