import { Spinner } from '@chakra-ui/react';
import React from 'react';

export const PageLoader: React.FC = () => {
  return (
    <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-white bg-opacity-60">
      <div className="flex items-center mx-auto">
        <Spinner
          thickness="4px"
          speed="0.70s"
          emptyColor="gray.200"
          color="gray.7"
          size="xl"
        />
      </div>
    </div>
  );
};
