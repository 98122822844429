import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputField, TextAreaInput } from '../../atoms';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InquirySubmit, inquirySubmit } from 'services';
import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { toastError, toastSuccess } from 'toast-schema';
import { AnyObjectSchema } from 'yup';
import Lazy from 'yup/lib/Lazy';
interface IProps {
  validation: AnyObjectSchema | Lazy<any, unknown>;
  setDone: Dispatch<SetStateAction<boolean>>;
}

export function ContactForm({ validation, setDone }: IProps) {
  const { t } = useTranslation();
  const toast = useToast();

  const event_inform = [
    'URL of flyer image',
    'Title',
    'Date & Time',
    'Venue',
    'Fee',
    'Performances',
    'Tickets (advance sales and general sale information)',
    'Notes (in case of rain, seating, etc.)',
    'Inquiries',
    'Artist Name',
    'Artist Official Link',
  ];

  const extra_inform = [
    'Release information',
    'Jacket Attachment',
    'Artist Information',
    'Artwork attached',
    'Interview',
    'Event Concept',
  ];

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: '',
    },
    resolver: yupResolver(validation),
  });

  const { mutateAsync: submitInquiryMutation, isLoading } = useMutation(
    inquirySubmit,
    {
      onSuccess: () => {
        toastSuccess({
          toast,
          toastId: 'success',
          description: t('Your inquiry has been submitted successfully!'),
        });
        setDone(true);
      },
      onError: () => {
        toastError({
          toast,
          toastId: 'success',
          description: t('Error occurred!'),
        });
      },
    },
  );

  const handleFormSubmit = async (data: InquirySubmit) => {
    await submitInquiryMutation(data);
  };

  return (
    <div className="mt-[12px] pb-3">
      <h4 className="semibold16-24">{t('Contact Us')}</h4>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => {
            return (
              <InputField
                marginTop="mt-[10px]"
                label="Name:"
                error={errors.name?.message && errors.name.message}
                {...field}
              />
            );
          }}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <InputField
              marginTop="mt-[10px]"
              label="Email Address:"
              error={errors.email?.message && errors.email.message}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="message"
          render={({ field }) => (
            <TextAreaInput
              className="mt-[10px]"
              label="Details of your inquiry:"
              count={watch('message')?.length || 0}
              maxCount={2000}
              error={errors.message?.message && errors.message.message}
              {...field}
            />
          )}
        />
        <div className="mt-6">
          <p className="font-bold text-black semibold16-24">
            {t('To artists, event organizers, labels, etc.')}
          </p>
          <p className="mt-6">
            {t(
              'To request the addition of a new event, please fill out the format here and send it to us.',
            )}
          </p>
          <h4 className="mt-4 regular16-24">
            {t('Event Information')}
            <ul className="ml-4">
              {event_inform.map(e => (
                <li key={e}>{t(e)}</li>
              ))}
            </ul>
          </h4>
          <div className="mt-6 regular16-24">
            {t('Please provide the following information if you have it.')}
            <ul className="ml-4">
              {extra_inform.map(e => (
                <li key={e}>{t(e)}</li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          disabled={isSubmitting}
          name="Send"
          type={'submit'}
          loading={isLoading}
          marginTop="mt-6"
        />
      </form>
    </div>
  );
}
