import { ICouponData, IPricingData } from 'models';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { Control, Controller, FieldError, FieldValues } from 'react-hook-form';
import { Button, InputField, RadioButton } from 'ui';

interface PaymentUpdateSubscriptionProps {
  onConfirm?: () => void;
  control?: Control<ICouponData, any>;
  errors?: string;
  type?: 'button' | 'submit' | 'reset';
  onSubmit?: () => void;
  loading?: boolean;
  pricingData?: IPricingData[];
}

export const PaymentUpdateSubscription: React.FC<
  PaymentUpdateSubscriptionProps
> = ({ onSubmit, control, errors, type, loading, pricingData }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <p className="regular16-24 text-gray-8 whitespace-pre-line pb-4">
        {t(
          'Your subscription is suspended.\nStart a subscription to continue using the service.',
        )}
      </p>

      <Controller
        name="price_id"
        control={control}
        render={({ field }) => (
          <RadioButton
            label={t('Select Plan:')}
            name={'Plan'}
            error={errors}
            field={field}
            items={
              pricingData?.map((data: IPricingData) => ({
                key: data?.id,
                value: data?.id,
                title:
                  (i18n?.language.startsWith('en')
                    ? data?.label?.en?.time
                    : data?.label?.ja?.time) +
                  ` <span class="text-gray-7 semibold14-22">${
                    i18n?.language.startsWith('en')
                      ? data?.label?.en?.price
                      : data?.label?.ja?.price
                  }</span>`,
              })) || []
            }
          />
        )}
      />
      <p className="regular12-20 text-gray-9 pl-6">
        {pricingData?.map((data: IPricingData) => (
          <span
            key={data.id}
            dangerouslySetInnerHTML={{
              __html: i18n?.language.startsWith('en')
                ? data?.label?.en?.label_description
                : data?.label?.ja?.label_description,
            }}
          />
        ))}
      </p>
      <div className="pt-4">
        <Controller
          name="coupon_code"
          control={control}
          render={({ field }) => (
            <InputField
              field={field}
              textTransform={'uppercase'}
              label="Coupon Code"
            />
          )}
        />
      </div>
      <div className="pt-6 ">
        <Button
          buttonType="primary"
          name={t('Update')}
          marginBottom={'mb-3'}
          type={type}
          onClick={onSubmit}
          loading={loading}
        />
      </div>
    </>
  );
};
