import React from 'react';
import Image from 'next/image';
interface propTypes {
  height?: any;
  width?: any;
  src?: string;
  className?: string;
}

export const CircularAvatar: React.FC<propTypes> = ({
  className = '',
  height,
  width,
  src,
}) => {
  return (
    <Image
      src={src ? src : '/assets/image/Profile.png'}
      alt="profile picture"
      width={width}
      height={height}
      className={`rounded-full ${className}`}
      style={{ objectFit: 'cover' }}
    />
  );
};
