import { Button, ModalDialogBox } from '../../atoms';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { UserStatusModal } from '../UserStatus';
import { useCancelParticipation, useChangeStatus } from 'services';
import { CancelParticipationModal } from '../CancelParticipation';
import { IParticipantStatus } from 'models';

type UserStatusModalProps = {
  onClose: () => void;
  setSelectedStatus: Dispatch<SetStateAction<string>>;
  currentStatus?: string;
  eventId: string;
  refetch: () => void;
  selectedStatus: string;
  participant_status: IParticipantStatus;
};
export const ChangeUserStatusModal: React.FC<UserStatusModalProps> = ({
  onClose,
  setSelectedStatus,
  currentStatus,
  eventId,
  refetch,
  selectedStatus,
  participant_status,
}) => {
  const { t } = useTranslation('common');
  const toast = useToast();
  const {
    isOpen: isOpenChangeStatus,
    onOpen: onOpenChangeStatus,
    onClose: onCloseChangeStatus,
  } = useDisclosure();

  const {
    isOpen: isOpenConfirmCancel,
    onOpen: onOpenConfirmCancel,
    onClose: onCloseConfirmCancel,
  } = useDisclosure();

  const {
    mutateAsync: CancelParticipationMutation,
    isLoading: isCancelLoading,
  } = useCancelParticipation(toast);

  const { mutateAsync: ChangeStatusMutation, isLoading: changeStatusLoading } =
    useChangeStatus(toast);

  const handleCancelParticipation = async () => {
    await CancelParticipationMutation(participant_status.id);
    setSelectedStatus('');
    refetch();
    onCloseConfirmCancel();
    onClose();
  };

  const handleChangeStatus = async () => {
    await ChangeStatusMutation({
      eventParticipationID: participant_status.id,
      newStatus: selectedStatus,
    });
    refetch();
    onCloseChangeStatus();
    onClose();
  };

  return (
    <>
      <p className="text-gray-8 regular16-24">{t(currentStatus || '')}</p>
      <div className={'mt-6'}>
        <Button
          name={t('Change Status')}
          onClick={() => {
            onOpenChangeStatus();
          }}
          marginBottom={'mb-3'}
        />
        <Button
          name={t('Cancel Participation')}
          onClick={onOpenConfirmCancel}
          buttonType={'tertiary'}
          marginBottom={'mb-3'}
        />
      </div>

      <ModalDialogBox
        isOpen={isOpenChangeStatus}
        title={t('Tell us about your status')}
        size="sm"
        onClose={onCloseChangeStatus}
        isCenter
        bodyComponent={
          <UserStatusModal
            selectedStatus={selectedStatus}
            onClose={onCloseChangeStatus}
            setSelectedStatus={setSelectedStatus}
            onSelectStatus={handleChangeStatus}
            loading={changeStatusLoading}
            status={participant_status.status}
          />
        }
      />
      <ModalDialogBox
        isOpen={isOpenConfirmCancel}
        title={t('Cancel Participation')}
        size="sm"
        onClose={onCloseConfirmCancel}
        isCenter
        bodyComponent={
          <CancelParticipationModal
            onClose={onCloseConfirmCancel}
            cancelLoading={isCancelLoading}
            handleCancelParticipation={handleCancelParticipation}
          />
        }
      />
    </>
  );
};
