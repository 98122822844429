import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth, AuthContextProps } from 'config';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  User,
  verifyBeforeUpdateEmail,
} from 'firebase/auth';
import { getUserById, useCreateUser } from 'services';
import { toastError, toastSuccess } from 'toast-schema';
import { useToast } from '@chakra-ui/react';
import { IProfileInputs } from 'models';
import { ErrorMessages } from 'ui';
import { useTranslation } from 'next-i18next';
import { clearSessionStorage } from 'helpers';
import { useQuery, useQueryClient } from 'react-query';
import { destroyCookie, setCookie } from 'nookies';

const AuthContext = createContext<AuthContextProps>({
  signUp: (email: string, password: string) => Promise.resolve(),
  setLoading: () => {},
  setUser: () => {},
  setAuthenticated: () => {},
  logIn: (email: string, password: string) => Promise.resolve(),
  logOut: () => Promise.resolve(),
  resetPassword: (email: string) => Promise.resolve(),
  changeEmail: (newEmail: string) => Promise.resolve(),
  changePassword: () => {},
  setLoginStatus: () => {},
  setLogoutStatus: () => {},
  setRedirectedFromNavDrawer: () => {},
  setAction: () => {},
  setSelectedTabIndex: () => {},
  setUserProfile: () => {},
});

export const checkIsAdmin = (jwt: String) => {
  if (!jwt) return false;

  const splitJWT = jwt.split('.');
  try {
    if (splitJWT.length == 3) {
      const payload = splitJWT[1];
      const payloadJSON = JSON.parse(Buffer.from(payload, 'base64').toString());
      return payloadJSON?.admin || false;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const useAuth = () => {
  const user = useContext<AuthContextProps>(AuthContext);
  const { data: userProfile, isLoading } = useQuery(
    'auth-user-profile',
    () => getUserById(user.user?.uid ?? ''),
    { enabled: !!user.user?.uid, keepPreviousData: false },
  );
  return {
    ...user,
    userProfile,
    loading: user.loading || isLoading,
    initializing: user.loading,
  };
};

export const AuthContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { t, i18n } = useTranslation();

  const { mutateAsync } = useCreateUser(toast, false);
  const [userProfile, setUserProfile] = useState<IProfileInputs>();
  const [user, setUser] = useState(null as User | null);
  const [loginStatus, setLoginStatus] = useState(true);
  const [action, setAction] = useState<any>('');
  const [logoutStatus, setLogoutStatus] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [redirectedFromNavDrawer, setRedirectedFromNavDrawer] = useState(false);

  useEffect(() => {
    const selectedTab = localStorage.getItem('selectedTab');
    if (selectedTab) {
      setSelectedTabIndex(Number(selectedTab));
    } else {
      setSelectedTabIndex(0);
    }

    auth.onIdTokenChanged(async cuser => {
      if (cuser) {
        const isAdmin = checkIsAdmin(await cuser?.getIdToken());
        if (isAdmin) {
          setUser(null);
          setAction('');
          setLoginStatus(true);
          setLogoutStatus(false);
          setAuthenticated(cuser !== null);
          setLoading(false);
          destroyCookie(null, 'fb_auth_token', { path: '/' });
          return;
        }

        setUser(cuser);

        queryClient
          .fetchQuery('auth-user-profile', () => getUserById(cuser.uid))
          .then((res: IProfileInputs) => {
            setUserProfile(res);
            setAuthenticated(cuser !== null);
            setLoading(false);
          })
          .catch(() => {
            setAuthenticated(cuser !== null);
            setLoading(false);
          });
        const token = await auth.currentUser?.getIdToken(false);
        if (token) {
          setCookie(null, 'fb_auth_token', token, {
            path: '/',
            sameSite: 'strict',
            secure: true,
          });
        }
      } else {
        setUser(null);
        setAction('');
        setLoginStatus(true);
        setLogoutStatus(false);
        setAuthenticated(cuser !== null);
        setLoading(false);
        destroyCookie(null, 'fb_auth_token', { path: '/' });
      }
    });
  }, []);

  const signUp = async (email: string, password: string) => {
    try {
      setLoading(true);
      setAction('signup');
      localStorage.setItem('signup', 'true');
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      const firebase_id = userCredential?.user?.uid;
      const username = userCredential?.user?.displayName || '';

      const profile_picture_url = userCredential?.user?.photoURL || '';
      const userData = {
        email,
        firebase_id,
        username,
        profile_picture_url,
      };

      mutateAsync(userData).then(value => {
        setLoginStatus(false);
        setUserProfile(value.data);
        setAuthenticated(true);
        setUser(userCredential.user);
        localStorage.setItem('user_id', value.data.id || '');
        localStorage.setItem('signup_type', 'email');
      });
      auth.languageCode = i18n?.language.startsWith('en') ? 'en' : 'ja';
      await sendEmailVerification(userCredential.user, {
        url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}${
          auth.languageCode === 'en' ? '/en/' : '/'
        }verify-email`,
      });
      setLoading(false);

      return userCredential;
    } catch (error: any) {
      const errorMessage = ErrorMessages[error.code];
      toastError({
        toastId: 'mutate-user-create',
        toast,
        description: t(errorMessage),
      });
      setLoading(false);
      setAction('');
    }
  };

  const logIn = (email: string, password: string) => {
    const user = signInWithEmailAndPassword(auth, email, password);
    setLoginStatus(true);
    setRedirectedFromNavDrawer(false);
    setAction('login');
    return user;
  };

  const logOut = async () => {
    if (user) {
      clearSessionStorage();
      localStorage.removeItem('signup');
      setRedirectedFromNavDrawer(false);
      await signOut(auth);
      queryClient.resetQueries('auth-user-profile');
      toastSuccess({
        toastId: 'profile',
        toast,
        description: t('Successfully logged out.'),
      });
    }
  };

  const resetPassword = (email: string) => {
    return sendPasswordResetEmail(auth, email, {
      url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/login/email`,
    });
  };

  const changeEmail = (newEmail: string) => {
    return verifyBeforeUpdateEmail(user!, newEmail, {
      url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/login/email`,
    });
  };

  const changePassword = () => {
    if (user?.email) {
      return sendPasswordResetEmail(auth, user?.email!, {
        url: `${process.env.NEXT_PUBLIC_FRONTEND_URL}/login/email`,
      });
    } else {
      toastError({
        toastId: 'no-email',
        toast,
        description: t('The account doesn’t exist'),
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        setAction,
        authenticated,
        setAuthenticated,
        loading,
        setLoading,
        signUp,
        logIn,
        logOut,
        resetPassword,
        changeEmail,
        changePassword,
        userProfile,
        selectedTabIndex,
        setSelectedTabIndex,
        setUserProfile,
        loginStatus,
        setLoginStatus,
        logoutStatus,
        action,
        setLogoutStatus,
        redirectedFromNavDrawer,
        setRedirectedFromNavDrawer,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
