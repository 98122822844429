import React from 'react';
import Link from 'next/link';

export const PrecautionsComponent = () => {
  return (
    <div className="pb-4 text-gray-8">
      <p className="font-sans text-base whitespace-pre-line">
        {`
          本サービスは音楽イベントの同行者をマッチングするサービスであり、出会い系のマッチングサービスではありません。他人の不快になるような性的なメッセージや中傷行為、出会い目的の執拗な誘い等は即座に通報願います。対象のアカウントは行為確認後、警告、アカウント休止や退会処置になります。\nその際の返金対応等は致しませんのでご了承願います。\n\nまた、チケットの原価以上の販売である転売行為を目的としているアカウントも通報対象となり、警告、退会処置とさせていただきます。その際の返金対応等は致しませんのでご了承願います。\nご利用者様同士での金銭等のトラブルは弊社は一切の責任を負いかねますのでご了承お願いいたします。\n\n全ての人が等しい立場で音楽を純粋に楽しむことを見つけることができる場となるようにご利用者様一人一人のご配慮をお願い申し上げます。\n\n `}
        {`詳しくは、`}
        <Link href="/terms-of-service" className="underline text-blue-7 hover:shadow-md ">
          利用規約
        </Link>
        {`をお読みください。`}
      </p>
    </div>
  );
};
