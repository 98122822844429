import { Box, Flex, List, ListItem } from '@chakra-ui/react';

import React, { MouseEventHandler } from 'react';
import { Logo } from '../../atoms';
import { AdminSideBarItems, AdminSideBarItemsAfterLogin } from '../../../utils';
import NextLink from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
export interface AdminBarItems {
  icon: string;
  name: string;
  path: string;
}

type AdminSideBarProps = {
  authenticated?: boolean | null;
  logOut?: MouseEventHandler<HTMLParagraphElement>;
};

export const AdminSideBar = ({ authenticated, logOut }: AdminSideBarProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const items = authenticated ? AdminSideBarItemsAfterLogin : AdminSideBarItems;
  return (
    <Box background={'bg-[#001529]'} h="100%" pos="fixed" top="0" width={'20%'}>
      <Flex bg={'gray.1'} paddingY={'35px'} paddingX={'20px'}>
        <Logo type={'light'} />
      </Flex>
      <List h="100%" bg="gray.1" spacing={'5px'} color={'white'}>
        {items.map((item: AdminBarItems, index: number) => {
          return (
            <ListItem
              className={`mx-4 ${(item?.path as string) == (router?.pathname as string)
                  ? 'bg-gray-9 text-white'
                  : 'text-gray-10'
                } ${item?.path == router.pathname && 'rounded-lg'}`}
              key={index}
              paddingY={'13px'}
              paddingLeft={'20px'}
            >
              {item.path != '' ? (
                (<NextLink
                  href={item.path}
                  className={`${item?.path == router.pathname
                      ? 'font-bold'
                      : 'font-semibold'
                    } text-[15px]`}>

                  {t(item.name)}

                </NextLink>)
              ) : (
                <p
                  className=" cursor-pointer text-[15px] font-semibold text-black"
                  onClick={logOut}
                >
                  {t(item.name)}
                </p>
              )}
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};
