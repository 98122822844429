import { Textarea, InputGroup, InputRightElement } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type TextAreaInputProps<T extends FieldValues, K extends Path<T>> = {
  name?: string;
  value?: string;
  placeholder: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  error?: string;
  className?: string;
  height?: string;
  field?: ControllerRenderProps<T, K>;
  iconRight?: ReactElement<FieldValues, string>;
  marginBottom?: string;
  onClickRightIcon?: React.MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
};
export const ChatTextArea = <T extends FieldValues, K extends Path<T>>({
  name,
  value,
  onChange,
  placeholder,
  error,
  className,
  field,
  iconRight,
  marginBottom,
  onClickRightIcon,
  disabled,
}: TextAreaInputProps<T, K>) => {
  const { t } = useTranslation();
  return (
    <div className={`${className}`}>
      <InputGroup>
        <Textarea
          className="!min-h-[42px] !pt-[8px] "
          placeholder={t(placeholder)}
          size="xs"
          bg={'white'}
          _placeholder={{ color: 'gray.6' }}
          name={name ? name : ''}
          value={value}
          resize={'none'}
          onChange={onChange}
          borderColor={error ? 'red.6' : 'gray.5'}
          fontSize={'regular16-24'}
          focusBorderColor="primary"
          borderRadius={'8px'}
          paddingLeft={'12px'}
          paddingEnd={'12px'}
          paddingRight="10"
          marginBottom={marginBottom}
          color="gray.8"
          disabled={disabled}
          _hover={{ borderColor: error ? 'red.6' : 'gray.5' }}
          _focus={{ borderColor: error ? 'red.6' : 'gray.5' }}
          {...field}
        />
        <InputRightElement
          paddingTop={'2px'}
          paddingRight={2}
          paddingLeft={2}
          marginRight={2}
          onClick={onClickRightIcon}
          className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
        >
          {iconRight}
        </InputRightElement>
        {error == '' ? null : (
          <div className="text-red-6 mt[10]">{t(error || '')}</div>
        )}
      </InputGroup>
    </div>
  );
};
