import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  StyleProps,
} from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import React, { ChangeEventHandler, ReactElement } from 'react';
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form';

export type LeftIconProps = {
  marginStart?: string;
  marginTop?: string;
  boxSize?: number;
  color?: string;
};

type FormInputProps<T extends FieldValues, K extends Path<T>> = {
  label?: string;
  name?: string;
  type?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  placeholderColor?: string;
  captionText?: string;
  iconLeft?: ReactElement<FieldValues, string>;
  iconRight?: ReactElement<FieldValues, string>;
  iconLeftStyle?: LeftIconProps;
  error?: string;
  required?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClickRightIcon?: () => void;
  field?: ControllerRenderProps<T, K>;
  marginBottom?: string;
  className?: string;
  placeholderStyle?: StyleProps;
  disabled?: boolean;
  border?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  autoFocus?: boolean;
};

export const InputWithChangeField = <T extends FieldValues, K extends Path<T>>({
  label,
  name,
  type,
  value,
  placeholder,
  placeholderColor = 'gray.8',
  placeholderStyle,
  iconLeft,
  iconRight,
  iconLeftStyle = {
    boxSize: 6,
    marginStart: '12px',
    marginTop: '12px',
    color: 'gray.9',
  },
  disabled,
  onChange,
  error,
  size = 'lg',
  onClickRightIcon,
  field,
  border = true,
  marginBottom = 'mb-6',
  className,
  onBlur,
  autoFocus = false,
}: FormInputProps<T, K>) => {
  const { t } = useTranslation('common');
  return (
    <div className={`${marginBottom} ${className}`}>
      <div className="pb-2">
        {label && (
          <label className="text-sm font-normal text-gray-9 text-start">
            {t(label)}
          </label>
        )}
      </div>

      <InputGroup>
        {iconLeft && (
          <InputLeftElement
            pointerEvents="none"
            width={12}
            height={12}
            color={iconLeftStyle.color}
            marginStart={iconLeftStyle.marginStart}
            marginTop={iconLeftStyle.marginTop}
            boxSize={iconLeftStyle.boxSize}
          >
            {iconLeft}
          </InputLeftElement>
        )}
        <Input
          bg={border ? 'white' : 'gray.3'}
          variant={border ? 'outline' : 'unstyled'}
          type={type ? type : 'text'}
          placeholder={t(placeholder!)}
          size={size}
          _placeholder={{
            color: placeholderColor,
            ...placeholderStyle,
          }}
          name={name ? name : ''}
          value={value}
          borderColor={error ? 'red.6' : 'gray.5'}
          className="relative regular16-24"
          focusBorderColor="gray.5"
          borderRadius={'8px'}
          paddingLeft={iconLeft ? '38px' : border ? '12px' : '0px'}
          paddingEnd={'12px'}
          paddingY={'12px'}
          color="gray.8"
          alignItems={'center'}
          disabled={disabled}
          _disabled={{
            color: placeholderColor,
          }}
          _hover={{ borderColor: border ? (error ? 'red.6' : 'gray.5') : '' }}
          _focus={{ borderColor: border ? (error ? 'red.6' : 'gray.5') : '' }}
          // {...field}
          onChange={onChange && onChange}
          onBlur={onBlur && onBlur}
          // value={field.value}
          autoFocus={autoFocus}
        />
        {iconRight && (
          <InputRightElement
            width={border ? 12 : '22px'}
            height={border ? 12 : '22px'}
            marginEnd={border ? '12px' : ''}
            marginTop={border ? '12px' : '12px'}
            boxSize={border ? 6 : ''}
            cursor="pointer"
            onClick={onClickRightIcon}
            color="gray.7"
          >
            {iconRight}
          </InputRightElement>
        )}
      </InputGroup>
      {error == '' ? null : <div className="text-red-6">{t(error || '')}</div>}
    </div>
  );
};
