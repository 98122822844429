import React from 'react';
import Head from 'next/head';

type MetaTagsType = {
  description?: string;
  url?: string;
  image?: string;
  imageType?: string;
  keywords?: string;
  title?: string;
  noIndex?: boolean;
};

export const MetaTags: React.FC<MetaTagsType> = ({
  description,
  url,
  image,
  keywords,
  imageType,
  title,
  noIndex,
}) => {
  if (noIndex) {
    return (
      <Head>
        {title ? <title>{`${title} | MELD`}</title> : <title>{'MELD'}</title>}
        <meta name="robots" content="noindex" />
      </Head>
    );
  }
  return (
    <Head>
      {title ? <title>{`${title} | MELD`}</title> : <title>{'MELD'}</title>}
      {keywords && <meta name="keywords" content={keywords} />}
      {title && <meta property="og:title" content={title} />}
      <meta property="og:type" content="website" />
      {url && <meta property="og:url" content={url} />}
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
        </>
      )}
      {image && <meta property="og:image" content={image} />}
      {imageType && <meta property="og:image:type" content={imageType} />}
      <meta name="og:site_name" content="MELD" />
    </Head>
  );
};
