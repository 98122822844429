function getSessionStorage(...args: any[]) {
  const storage = globalThis.localStorage;
  if (!storage) return;
  let allArgs: any = [];
  args.forEach(arg => {
    allArgs = [...allArgs, { key: arg, value: storage.getItem(arg) }];
  });
  return allArgs;
}

function removeSessionStorageItem(...args: string[]) {
  const storage = globalThis.localStorage;
  if (!storage) return;
  args.forEach(arg => {
    window.localStorage.removeItem(arg);
  });
}

export const setSessionStorage = (keyName: string, defaultValue: string) => {
  try {
    const storage = globalThis.localStorage;
    if (!storage) return;
    const value = window.localStorage.getItem(keyName);
    if (value) {
      return JSON.parse(value);
    } else {
      window.localStorage.setItem(keyName, defaultValue);
      return defaultValue;
    }
  } catch (err) {
    return defaultValue;
  }
};

export const getIndividualSessionItems = (key: string) => {
  const sessionItem = getSessionStorage(key)?.reduce((item: any) => {
    return item;
  });
  return sessionItem?.value;
};

export const removeAStep = (key: string) => {
  const steps = getIndividualSessionItems('steps');
  if (steps) {
    const parsed_steps = JSON.parse(steps);
    delete parsed_steps[key];
    removeSessionStorageItem('steps');
    setSessionStorage('steps', JSON.stringify(parsed_steps));
  }
};

export const clearSessionStorage = () => {
  const storage = globalThis.localStorage;
  if (!storage) return;
  window.localStorage.removeItem('steps');
  window.localStorage.removeItem('returnPath');
  localStorage.removeItem('user_id');
};
