export const genreList = [
  { value: 'J-POP', label: 'J-POP' },
  { value: 'Idol(Jhonny)', label: 'Idol(Jhonny)' },
  { value: 'Idol(Male Idol)', label: 'Idol(Male Idol)' },
  { value: 'Idol(Female Idol)', label: 'Idol(Female Idol)' },
  { value: 'J-Rock', label: 'J-Rock' },
  { value: 'ANIME', label: 'ANIME' },
  { value: 'J-HIP-HOP', label: 'J-HIP-HOP' },
  { value: 'K-POP', label: 'K-POP' },
  { value: 'Foreign Artist', label: 'Foreign Artist' },
  { value: 'Classic', label: 'Classic' },
  {
    value: 'CLUB/DANCE/ELECTRONIC/HOUSE',
    label: 'CLUB/DANCE/ELECTRONIC/HOUSE',
  },
  { value: 'FES', label: 'FES' },
];
