import { useTranslation } from 'react-i18next';
import {
  TitleWithIcon,
  MetaTags,
  Button,
  backArrowIcon,
  NoResultsFound,
} from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React from 'react';

interface IErrorPageComponentProps {
  title?: string;
  content: string;
  buttonText: string;
  buttonLink: string;
}

export const ErrorPageComponent: React.FC<IErrorPageComponentProps> = ({
  title,
  content,
  buttonLink,
  buttonText,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  return <>
    <div className="py-3">
      {title && (
        <TitleWithIcon
          title={t(title)}
          icon={backArrowIcon}
          onClickIcon={router.back}
        />
      )}
    </div>
    <div className="mb-4">
      <NoResultsFound text={content} />
    </div>
    <Link href={buttonLink ?? '/'} className="max-w-xs mx-auto">
      <Button as="div" buttonType="error" name={t(buttonText)} />
    </Link>
  </>;
};
