import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, InputField, TextAreaInput } from '../../atoms';
import Link from 'next/link';

export function ContactDone() {
  const { t } = useTranslation();
  return (
    <div className="mt-[24px] pb-3">
      <h4 className="semibold16-24">{t('Inquiry Completed')}</h4>
      <p className="mt-[24px] whitespace-pre-wrap">
        {t(
          'Thank you for your inquiry.\n\nA representative will be in touch with you soon.',
        )}
      </p>
      <Link href={'/'}>
        <Button as="div" name={t('Go to Top Page')} marginTop="mt-6" />
      </Link>
    </div>
  );
}
