import { Button, RadioButton } from '../../atoms';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction, useState } from 'react';

type UserStatusModalProps = {
  onClose: () => void;
  setSelectedStatus: Dispatch<SetStateAction<string>>;
  onSelectStatus?: () => void;
  status?: string;
  loading: boolean;
  selectedStatus: string;
};
export const UserStatusModal: React.FC<UserStatusModalProps> = ({
  setSelectedStatus,
  onSelectStatus,
  status,
  loading,
  selectedStatus,
}) => {
  const { t } = useTranslation('common');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const items = [
    {
      value: 'I have an extra ticket',
      title: t('I have an extra ticket'),
    },
    {
      value: 'I want to go with someone who has a ticket',
      title: t('I want to go with someone who has extra tickets'),
    },
    {
      value: 'I have my own ticket',
      title: t('I want to go with people who have tickets'),
    },
    {
      value: "I don't have a ticket but would like to go",
      title: t("I don't have a ticket but would like to go"),
    },
    {
      value: 'I want to be matched with someone at the venue',
      title: t('I want to be matched with someone at the venue'),
    },
    {
      value: 'Found a companion',
      title: t('Found a companion'),
    },
  ];

  return (
    <div>
      <RadioButton
        items={items}
        setOption={setSelectedStatus}
        defaultValue={status}
        name={'status'}
      />
      {isSubmitting && !selectedStatus && (
        <p className="mt-2 text-red-6">{t('Please select')}</p>
      )}
      <div className={'mt-6'}>
        <Button
          name={t('Enter')}
          loading={loading}
          onClick={() => {
            if (selectedStatus) {
              onSelectStatus && onSelectStatus();
            } else setIsSubmitting(true);
          }}
          marginBottom={'mb-3'}
        />
      </div>
    </div>
  );
};
